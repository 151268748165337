import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useContext,
  useGlobal,
} from 'reactn';
import { useFirestore, useSignoutCallback } from '../../firebase/hooks';
import {
  VoidRepository,
  FirestoreRepository,
  ThrottleRepository,
  Note,
} from './../repositories';

interface NoteSubscriptionContext {
  note: Note | undefined;
  uid: string | undefined;
  directoryPath: string | undefined;
  noteId: string | undefined;
  initialNote: Note | undefined;
  repository: ThrottleRepository;
  deleteNote: () => void;
}
const noteSubscriptionContext = React.createContext<NoteSubscriptionContext>({
  note: undefined,
  uid: undefined,
  directoryPath: undefined,
  noteId: undefined,
  initialNote: undefined,
  repository: new ThrottleRepository(new VoidRepository()),
  deleteNote: () => undefined,
});

export const useNoteSubscription = (): NoteSubscriptionContext => {
  return useContext(noteSubscriptionContext);
};

interface NoteSubscriberProps {
  uid: string;
  directoryPath: string;
  noteId: string;
  onLoad: () => void;
  onDelete: () => void;
  children: React.ReactNode;
}
const NoteSubscriber: React.FC<NoteSubscriberProps> = (props) => {
  const [noteUpdateError, setNoteUpdateError] = useState<Error>();
  useEffect(() => {
    if (noteUpdateError) {
      // TODO: Show error in more appropriate way
      alert(
        '[Document conflict]\n' +
          'This note may have been updated by another client and your current changes cannot be applied.\n' +
          'Please try reloading and edit the note again.'
      );
    }
  }, [noteUpdateError]);

  const firestore = useFirestore();
  const repository = useMemo(() => {
    const repository = firestore
      ? new FirestoreRepository(firestore)
      : new VoidRepository();
    return new ThrottleRepository(repository, {
      onNoteUpdateError: (error) => {
        setNoteUpdateError(error);
      },
    });
  }, [firestore]);
  const flush = useCallback(() => repository.flush(), [repository]);
  useSignoutCallback(flush);

  const [note, setNote] = useState<Note>();
  const [initialNote, setInitialNote] = useState<Note>();
  const [loadedUid, setLoadedUid] = useState<string>();
  const [loadedDirectoryPath, setLoadedDirectoryPath] = useState<string>();
  const [loadedNoteId, setLoadedNoteId] = useState<string>();
  // const loaded = props.uid === loadedUid && props.directoryPath === loadedDirectoryPath && props.noteId === loadedNoteId;
  const noteDeleted = useRef(false);

  const setPageTitle = useGlobal('pageTitle')[1];

  const onLoad = props.onLoad;
  const onDelete = props.onDelete;
  useEffect(() => {
    let isValueInitialized = false;

    const unsubscribe = repository.listenNote(
      props.uid,
      props.directoryPath,
      props.noteId,
      (note) => {
        if (note) {
          if (!isValueInitialized) {
            setLoadedUid(props.uid);
            setLoadedDirectoryPath(props.directoryPath);
            setLoadedNoteId(props.noteId);
            setInitialNote(note);
            onLoad();
            isValueInitialized = true;
          }
          setNote(note);
          noteDeleted.current = false;
          setPageTitle(note.title || null);

          // TODO: Sync / Collaborative editing
        } else {
          setNote(undefined);
          setPageTitle(null);
        }
      },
      () => {
        setNote(undefined);
        setPageTitle(null);
        onDelete();
      }
    );

    const flushRepository = (): void => {
      if (isValueInitialized) {
        repository.flush();
      }
    };
    window.addEventListener('beforeunload', flushRepository);

    return () => {
      unsubscribe();

      window.removeEventListener('beforeunload', flushRepository);

      // TODO: This can be called AFTER logging out and it causes an error
      //       that the app tries to write to firestore without permission.
      //       It seems this can be solved by refs like here's `noteDeleted`.
      if (!noteDeleted.current) {
        flushRepository();
      }

      isValueInitialized = false;
    };
  }, [
    repository,
    props.uid,
    props.directoryPath,
    props.noteId,
    onLoad,
    onDelete,
    setPageTitle,
  ]);

  // `deleteNote` is implemented in this component because it must work with flushRepository above
  const deleteNote = useCallback((): void => {
    repository.deleteNote(props.uid, props.directoryPath, props.noteId);
    noteDeleted.current = true;
  }, [repository, props.uid, props.directoryPath, props.noteId]);

  const contextValue = useMemo(
    () => ({
      note,
      uid: loadedUid,
      directoryPath: loadedDirectoryPath,
      noteId: loadedNoteId,
      initialNote,
      repository,
      deleteNote,
    }),
    [
      note,
      loadedUid,
      loadedDirectoryPath,
      loadedNoteId,
      initialNote,
      repository,
      deleteNote,
    ]
  );

  return (
    <noteSubscriptionContext.Provider value={contextValue}>
      {props.children}
    </noteSubscriptionContext.Provider>
  );
};

export default React.memo(NoteSubscriber);
