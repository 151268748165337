import React from 'react';
import { Alert, Spinner, Text } from 'sancho';
import { Main, Centering } from '../../components/layout';
import { Logo } from '../../components/logo';

const GithubRedirected: React.FC = () => {
  return (
    <Main>
      <Centering>
        <Logo />
        <Alert intent="info">
          <Text
            variant="h6"
            style={{
              margin: 0,
            }}
          >
            Redirected from GitHub. Authorizing...
          </Text>
          <Spinner style={{ marginLeft: '1em' }} />
        </Alert>
      </Centering>
    </Main>
  );
};

export default GithubRedirected;
