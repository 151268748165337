import React, { useState } from 'react';
import { Input, InputGroup, Button, Toolbar, Text, Link } from 'sancho';
import {
  Link as RouterLink,
  Redirect,
  RouteComponentProps,
} from 'react-router-dom';
import { StaticContext } from 'react-router';
import { useUser } from './hooks';
import { firebaseAuth, firebaseAnalytics } from '../firebase';
import firebase from 'firebase/app';
import { Main, Centering } from '../components/layout';
import { AuthFormContainer, AuthForm } from '../components/auth';
import { Logo } from '../components/logo';

const errorReasons: { [key: string]: string } = {
  'auth/email-already-in-use': 'email',
  'auth/invalid-email': 'email',
  'auth/operation-not-allowed': 'email', // XXX: This is neither about email nor password.
  'auth/weak-password': 'password',
};

interface SignupLocationState {
  email?: string;
  from?: Location;
}
const Signup: React.FC<RouteComponentProps<
  Record<string, string>,
  StaticContext,
  SignupLocationState
>> = (props) => {
  const locationState = props.location.state || {};
  const { email: defaultEmail = '' } = locationState;

  const globalUser = useUser();

  const [email, setEmail] = useState<string>(defaultEmail);
  const [password, setPassword] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<{
    code: string;
    message: string;
  } | null>();

  const [loggedInUser, setLoggedInUser] = useState<firebase.User>();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    setLoading(true);
    setError(null);

    if (globalUser && globalUser.isAnonymous) {
      const credential = firebase.auth.EmailAuthProvider.credential(
        email,
        password
      );
      globalUser
        .linkWithCredential(credential)
        .then((cred) => {
          firebaseAnalytics.logEvent('sign_up', {
            method: 'email',
            fromAnonymous: true,
          });
          setLoading(false);
          setLoggedInUser(cred.user || undefined);
        })
        .catch((error) => {
          setLoading(false);
          setLoggedInUser(undefined);
          setError({ code: error.code, message: error.message });
        });
    } else {
      firebaseAuth
        .createUserWithEmailAndPassword(email, password)
        .then((cred) => {
          firebaseAnalytics.logEvent('sign_up', {
            method: 'email',
            fromAnonymous: false,
          });
          setLoading(false);
          setLoggedInUser(cred.user || undefined);
        })
        .catch((error) => {
          setLoading(false);
          setLoggedInUser(undefined);
          setError({ code: error.code, message: error.message });
        });
    }
  };

  // The if-clause with a condition `loggedInUser.uid === globalUser?.uid` here means
  // to wait for the new `globalUser` to be set by the `firebaseAuth.onAuthStateChanged` callback
  // in `useFirebase` hook.
  if (loggedInUser && loggedInUser.uid === globalUser?.uid) {
    const { from = { pathname: '/' } } = locationState;
    // const { from } = props.location.state || { from: { pathname: '/' } };
    if (from.pathname === props.location.pathname) {
      // Avoid infinate loop
      from.pathname = '/';
    }
    return <Redirect to={from} />;
  }

  return (
    <Main scrollable>
      <Centering>
        <RouterLink to="/">
          <Logo />
        </RouterLink>
        <AuthFormContainer title="Sign up">
          <AuthForm onSubmit={onSubmit}>
            <InputGroup
              label="Email address"
              error={
                error && errorReasons[error.code] === 'email' && error.message
              }
            >
              <Input
                name="email"
                type="email"
                required
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </InputGroup>
            <InputGroup
              label="Password"
              error={
                error &&
                errorReasons[error.code] === 'password' &&
                error.message
              }
            >
              <Input
                name="password"
                type="password"
                required
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                }}
              />
            </InputGroup>

            <InputGroup
              label="Submit"
              hideLabel
              error={
                error &&
                errorReasons[error.code] !== 'email' &&
                errorReasons[error.code] !== 'password' &&
                error.message
              }
            >
              <Button
                component="button"
                type="submit"
                block
                intent="primary"
                loading={loading}
              >
                Sign up
              </Button>
            </InputGroup>
          </AuthForm>

          <Toolbar>
            <Text>
              {'Already signed up? '}
              <Link
                component={RouterLink}
                to={{ pathname: '/login', state: { email } }}
              >
                Log in
              </Link>
              .
            </Text>
          </Toolbar>
        </AuthFormContainer>
      </Centering>
    </Main>
  );
};

export default Signup;
