import React, { useState, useMemo, useEffect } from 'react';
import { useEditor, ReactEditor } from 'slate-react';
import { HistoryEditor } from 'slate-history';
import {
  TopLevelElement,
  TasquetMarkdownEditor,
} from '@tasquet/slate-markdown-plugin';
import GitHubBadge from './GitHubBadge';
import { GitHubInfo, extractGitHubInfo } from './github-info';
import { CompletedStatusMap, aggregateCompletedStatus } from './url-status';

interface UrlsProps {
  element: TopLevelElement;
  urls: string[];
}
export const Urls: React.FC<UrlsProps> = (props) => {
  const [completedStatusMap, setCompletedStatusMap] = useState<
    CompletedStatusMap
  >({});
  const setCompletedCallbacks = useMemo(() => {
    return props.urls.map((url) => (completed: boolean | undefined) => {
      setCompletedStatusMap((cur) => {
        if (!(url in cur) || cur[url] !== completed) {
          return { ...cur, [url]: completed };
        } else {
          return cur;
        }
      });
    });
  }, [props.urls]);

  const checked: boolean | undefined = useMemo(() => {
    return aggregateCompletedStatus(completedStatusMap, props.urls);
  }, [props.urls, completedStatusMap]);

  // NOTE: This component is not re-rendered everytime `editor.children` or its children are changed since here `useEditor` is used to listen to the editor object instead of `useSlate`.
  // Make sure that all consumers of `editor` in this component do not require to react to every change of its children, but just need to have the reference.
  const editor = useEditor();

  useEffect(() => {
    if (checked == null) {
      return;
    }

    const parentMdast = props.element.parentMdastNode;
    if (
      parentMdast &&
      parentMdast.type === 'listItem' &&
      parentMdast.checked != null
    ) {
      if (parentMdast.checked !== checked) {
        const elementPath = ReactEditor.findPath(editor, props.element);
        if (HistoryEditor.isHistoryEditor(editor)) {
          HistoryEditor.withoutSaving(editor, () => {
            TasquetMarkdownEditor.setListItemCheckbox(
              editor,
              checked,
              elementPath
            );
          });
        } else {
          TasquetMarkdownEditor.setListItemCheckbox(
            editor,
            checked,
            elementPath
          );
        }
      }
    }
  }, [editor, props.element, checked]);

  const githubInfoList = props.urls
    .map(extractGitHubInfo)
    .filter((info) => info) as GitHubInfo[];

  return (
    <>
      {githubInfoList.map((githubInfo, i) => (
        <GitHubBadge
          info={githubInfo}
          key={githubInfo.url}
          onCompletedChange={setCompletedCallbacks[i]}
        />
      ))}
    </>
  );
};

export default React.memo(Urls, (prevProps, nextProps) => {
  if (prevProps.urls.length !== nextProps.urls.length) {
    return false;
  }
  if (prevProps.urls.some((url, i) => url !== nextProps.urls[i])) {
    return false;
  }

  if (prevProps.element !== nextProps.element) {
    return false;
  }

  return true;
});
