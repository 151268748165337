/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { IconAlertTriangle } from 'sancho';
import BadgeContainer from './BadgeContainer';
import BadgeText from './BadgeText';
import RefreshButton from './RefreshButton';
import { badgeShapeCss } from './css';

interface LoadingBadgeProps {
  onRefresh: () => void;
}
const LoadingBadge: React.FC<LoadingBadgeProps> = (props) => {
  return (
    <BadgeContainer>
      <span css={badgeShapeCss}>
        <IconAlertTriangle size="sm" />
        <BadgeText>Error</BadgeText>
      </span>
      <RefreshButton onClick={props.onRefresh} />
    </BadgeContainer>
  );
};

export default LoadingBadge;
