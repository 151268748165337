/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import SkeletonEditable from './SkeletonEditable';

interface SkeletonEditableLazyProps {
  delay?: number;
}
const SkeletonEditableLazy: React.FC<SkeletonEditableLazyProps> = (props) => {
  const { delay = 0 } = props;

  const [show, setShow] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setShow(true), delay);
    return () => clearTimeout(timeout);
  }, [delay]);

  return (
    <div css={{ transition: 'opacity 1s ease', opacity: show ? 1 : 0 }}>
      <SkeletonEditable />
    </div>
  );
};

export default React.memo(SkeletonEditableLazy);
