/** @jsx jsx */
import React, {
  useCallback,
  useRef,
  useState,
  useMemo,
  useContext,
} from 'react';
import { jsx, css } from '@emotion/core';
import { useTheme, IconChevronRight, IconChevronLeft } from 'sancho';
import Color from 'color';
import { useLocalStorage } from '@rehooks/local-storage';

type SplitFrameContextValue = [boolean, (value: boolean) => void];
const splitFrameContext = React.createContext<SplitFrameContextValue>([
  true,
  () => undefined,
]);
export const useSplitFrame = (): SplitFrameContextValue =>
  useContext(splitFrameContext);

interface ToggleButtonProps {
  isOpen: boolean;
  onChange: (isOpen: boolean) => void;
}
let ToggleButton: React.FC<ToggleButtonProps> = (props) => {
  const width = 12;
  const height = 40;

  const toggleButtonIconCss = css({
    position: 'absolute',
    top: '50%',
    left: 1,
    width: 11,
    height: 24,
    marginTop: -10,
  });

  return (
    <button
      css={{
        border: '2px solid',
        cursor: 'pointer',
        outline: 'none',
        padding: 0,
        appearance: 'none',
        boxSizing: 'border-box',
        position: 'absolute',
        top: '50%',
        left: 0,
        width,
        height,
        marginTop: -height / 2,
        borderRadius: width,
        display: 'flex',
        justifyContent: 'center',
      }}
      onClick={() => props.onChange(!props.isOpen)}
    >
      {props.isOpen ? (
        <IconChevronRight viewBox="9 6 14 18" css={toggleButtonIconCss} />
      ) : (
        <IconChevronLeft viewBox="8 6 13 18" css={toggleButtonIconCss} />
      )}
    </button>
  );
};
ToggleButton = React.memo(ToggleButton);

const MIN_WIDTH = 300;

interface SplitFrameProps {
  localStorageKey: string;
  primary?: React.ReactElement | null;
  secondary?: React.ReactElement | null;
}
const SplitFrame: React.FC<SplitFrameProps> = (props) => {
  const theme = useTheme();

  const [secondaryVisible, setSecondaryVisible] = useLocalStorage<boolean>(
    `${props.localStorageKey}-visibility`,
    true
  );
  const contextValue: SplitFrameContextValue = useMemo(
    () => [secondaryVisible, setSecondaryVisible],
    [secondaryVisible, setSecondaryVisible]
  );
  const [dragging, setDragging] = useState(false);
  const [dragOffsetX, setDragOffsetX] = useState(0);
  const [primaryWidth, setPrimaryWidth] = useLocalStorage<number>(
    `${props.localStorageKey}-width`
  );
  const containerRef = useRef<HTMLDivElement>(null);
  const handlerRef = useRef<HTMLDivElement>(null);

  const primaryWidthCss = primaryWidth == null ? '50%' : `${primaryWidth}px`;

  const borderColor = theme.colors.border.default;
  const borderHoverBaseColor = Color(theme.colors.intent.primary.light);
  const borderHoverColor = borderHoverBaseColor.alpha(0.7).string();
  const borderHoverColor2 = borderHoverBaseColor.alpha(0.5).string();

  return (
    <splitFrameContext.Provider value={contextValue}>
      <div
        ref={containerRef}
        css={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'row',
        }}
        onMouseMove={useCallback(
          (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
            if (!dragging) {
              return;
            }
            if (!containerRef.current || !handlerRef.current) {
              return;
            }
            const containerBBox = containerRef.current.getBoundingClientRect();
            const containerOffsetLeft = containerBBox.left;
            const handlerXOffset =
              e.clientX - dragOffsetX - containerOffsetLeft;

            const maxWidth = containerRef.current.clientWidth - MIN_WIDTH - 12;
            const newPrimaryWidth = Math.min(
              Math.max(handlerXOffset, MIN_WIDTH),
              maxWidth
            );
            setPrimaryWidth(newPrimaryWidth);
          },
          [dragging, dragOffsetX, setPrimaryWidth]
        )}
        onMouseUp={useCallback(() => {
          setDragging(false);
        }, [])}
        onMouseLeave={useCallback(() => {
          setDragging(false);
        }, [])}
      >
        <div
          css={{
            minWidth: secondaryVisible ? primaryWidthCss : '100%',
            width: secondaryVisible ? primaryWidthCss : '100%',
            marginRight: secondaryVisible ? '-6px' : '-12px',
            paddingRight: secondaryVisible ? '6px' : '12px',
            boxSizing: 'border-box',
            flexGrow: 0,
          }}
        >
          {props.primary}
        </div>
        <div
          css={{
            position: 'relative',
            height: '100%',
            width: '12px',
            minWidth: '12px',
            cursor: 'ew-resize',
            zIndex: 1,
            '&::before': {
              content: '" "',
              display: 'block',
              width: '2px',
              height: '100%',
              backgroundColor: borderColor,
              opacity: 0.5,
              margin: '0 auto',
            },
            '&:hover:before': {
              backgroundColor: borderHoverColor,
            },
            '& button': {
              backgroundColor: theme.colors.background.default,
              borderColor: borderColor,
            },
            '& svg': {
              stroke: borderColor,
            },
            '&:hover button': {
              borderColor: borderHoverColor2,
            },
            '&:hover svg': {
              stroke: borderHoverColor,
            },
          }}
          ref={handlerRef}
          onMouseDown={useCallback(
            (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              if (!secondaryVisible) {
                return;
              }
              if (!handlerRef.current) {
                return;
              }
              setDragOffsetX(
                e.clientX - handlerRef.current.getBoundingClientRect().left
              );
              setDragging(true);
            },
            [secondaryVisible]
          )}
          onDoubleClick={useCallback(() => {
            setSecondaryVisible(!secondaryVisible);
          }, [secondaryVisible, setSecondaryVisible])}
        >
          <ToggleButton
            isOpen={secondaryVisible}
            onChange={setSecondaryVisible}
          />
        </div>
        <div
          css={{
            display: secondaryVisible ? 'block' : 'none',
            flex: '1 1 auto',
            marginLeft: '-6px',
            paddingLeft: '6px',
          }}
        >
          {secondaryVisible && props.secondary}
        </div>
      </div>
    </splitFrameContext.Provider>
  );
};

export default React.memo(SplitFrame);
