import React from 'react';
import EditorErrorFallback from './components/EditorErrorFallback';

type Props = Record<string, unknown>;
interface State {
  error: Error | undefined;
}

class ErrorBoundary extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: Error): Partial<State> {
    return { error };
  }

  // componentDidCatch(error: Error, errorInfo: any) {
  //   // You can also log the error to an error reporting service
  //   logErrorToMyService(error, errorInfo);
  // }

  render(): React.ReactNode {
    if (this.state.error) {
      return <EditorErrorFallback />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
