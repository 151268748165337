/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { Layer, LayerElevations, Toolbar, Text, useTheme } from 'sancho';

interface AuthFormContainerProps {
  title?: string;
  elevation?: LayerElevations;
}
export const AuthFormContainer: React.FC<AuthFormContainerProps> = (props) => {
  const theme = useTheme();

  return (
    <Layer
      elevation={props.elevation}
      css={{
        maxWidth: '100vw',
        width: '400px',
        margin: `0 auto ${theme.spaces.lg}`,
      }}
    >
      {props.title && (
        <Toolbar
          css={{
            justifyContent: 'center',
            borderBottom: `1px solid ${theme.colors.border.default}`,
          }}
        >
          <Text gutter={false} variant="h4">
            {props.title}
          </Text>
        </Toolbar>
      )}
      {props.children}
    </Layer>
  );
};

export const AuthForm: React.FC<React.ComponentProps<'form'>> = (props) => {
  const { children, ...restProps } = props;
  const theme = useTheme();

  return (
    <form
      css={{
        padding: theme.spaces.lg,
      }}
      {...restProps}
    >
      {children}
    </form>
  );
};
