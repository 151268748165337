import React, { useRef, useCallback } from 'react';
import { NoteAppContainer } from './components/styled';
import BackButton from './components/BackButton';
import NoteAppHeader from './components/NoteAppHeader';
import NoteSubscriber from './NoteSubscriber';
import NoteEditor, { NoteEditorRef } from './NoteEditor';
import NoteTitle, { NoteTitleRef } from './NoteTitle';
import NoteDelete from './NoteDelete';
import NoteTagList from './NoteTagList';
import NoteTagSelect from './NoteTagSelect';

interface ConnectedNoteAppProps {
  uid: string;
  directoryPath: string;
  noteId: string;
  onBackClick: () => void;
  onNoteDelete: () => void;
}
const ConnectedNoteApp: React.FC<ConnectedNoteAppProps> = (props) => {
  const titleRef = useRef<NoteTitleRef>(null);
  const editorRef = useRef<NoteEditorRef>(null);

  return (
    <NoteAppContainer>
      <NoteSubscriber
        uid={props.uid}
        directoryPath={props.directoryPath}
        noteId={props.noteId}
        onLoad={useCallback(() => {
          setTimeout(() => {
            titleRef.current?.focus();
          });
        }, [])}
        onDelete={props.onNoteDelete}
      >
        <NoteAppHeader>
          <BackButton onClick={props.onBackClick} />
          <NoteTitle
            ref={titleRef}
            onFocusNextRequested={editorRef.current?.focus}
          />
          <NoteDelete />
        </NoteAppHeader>
        <NoteAppHeader>
          <NoteTagList />
          <NoteTagSelect />
        </NoteAppHeader>

        <NoteEditor ref={editorRef} />
      </NoteSubscriber>
    </NoteAppContainer>
  );
};

export default ConnectedNoteApp;
