import {
  Repository,
  OnNoteChange,
  OnNoteDelete,
  ListenGoogleCalendarEventCallback,
  DeserializedNoteUpdate,
  NoteCreate,
  ListenNotesCallback,
  ListenTaskChangesCallback,
} from '.';
import {
  throttleParameterized2,
  ThrottledFunctionBoundParam2,
  throttleParameterized3,
  ThrottledFunctionBoundParam3,
} from './parameterized-throttle';
import { DateTask } from '../ConnectedNoteApp/logics/task-parser';

interface ThrottleRepositoryOptions {
  noteThrottleWait?: number;
  taskThrottleWait?: number;
  onNoteUpdateError?: (error: Error) => void;
}

export class ThrottleRepository implements Repository {
  private repository: Repository;

  private updateNoteThrottled: ThrottledFunctionBoundParam3<
    Repository['updateNote']
  >;

  private setTaskThrottled: ThrottledFunctionBoundParam2<Repository['setTask']>;

  constructor(repository: Repository, opts: ThrottleRepositoryOptions = {}) {
    const {
      noteThrottleWait = 10000,
      taskThrottleWait = 2000,
      onNoteUpdateError,
    } = opts;

    this.repository = repository;

    const updateNoteWithErrorHandler: Repository['updateNote'] = (
      uid,
      directoryPath,
      noteId,
      deserializedNote
    ): Promise<void> => {
      return this.repository
        .updateNote(uid, directoryPath, noteId, deserializedNote)
        .catch(onNoteUpdateError);
    };

    this.updateNoteThrottled = throttleParameterized3(
      updateNoteWithErrorHandler,
      noteThrottleWait,
      { leading: false },
      this
    );
    this.setTaskThrottled = throttleParameterized2(
      this.repository.setTask,
      taskThrottleWait,
      { leading: false },
      this.repository
    );

    this.flush = this.flush.bind(this);
  }

  updateNote(
    uid: string,
    directoryPath: string,
    noteId: string,
    deserializedNote: Partial<DeserializedNoteUpdate>
  ): Promise<void> {
    return this.updateNoteThrottled(
      uid,
      directoryPath,
      noteId,
      deserializedNote
    );
  }

  setTask(uid: string, taskId: string, data: DateTask): Promise<void> {
    return this.setTaskThrottled(uid, taskId, data);
  }

  deleteNote(
    uid: string,
    directoryPath: string,
    noteId: string
  ): Promise<void> {
    if (
      this.updateNoteThrottled.boundParams &&
      uid === this.updateNoteThrottled.boundParams[0] &&
      directoryPath === this.updateNoteThrottled.boundParams[1] &&
      noteId === this.updateNoteThrottled.boundParams[2]
    ) {
      this.updateNoteThrottled?.cancel();
    }

    return this.repository.deleteNote(uid, directoryPath, noteId);
  }

  async flush(): Promise<void> {
    await (this.updateNoteThrottled.flush() as Promise<void> | undefined);
    this.updateNoteThrottled.cancel();
    await (this.setTaskThrottled.flush() as Promise<void> | undefined);
    this.setTaskThrottled.cancel();
  }

  deleteTask(uid: string, taskId: string): Promise<void> {
    if (
      this.setTaskThrottled.boundParams &&
      uid === this.setTaskThrottled.boundParams[0] &&
      taskId === this.setTaskThrottled.boundParams[1]
    ) {
      this.setTaskThrottled?.cancel();
    }

    return this.repository.deleteTask(uid, taskId);
  }

  // Simplely delegated methods
  createNote(
    uid: string,
    directoryPath: string,
    note: NoteCreate
  ): Promise<string> {
    return this.repository.createNote(uid, directoryPath, note);
  }
  listenNote(
    uid: string,
    directoryPath: string,
    noteId: string,
    onChange: OnNoteChange,
    onDelete: OnNoteDelete
  ): () => void {
    return this.repository.listenNote(
      uid,
      directoryPath,
      noteId,
      onChange,
      onDelete
    );
  }
  listenNotes(
    uid: string,
    directoryPath: string,
    tagId: string | null,
    callback: ListenNotesCallback
  ): () => void {
    return this.repository.listenNotes(uid, directoryPath, tagId, callback);
  }
  listenTaskChanges(
    uid: string,
    callback: ListenTaskChangesCallback
  ): () => void {
    return this.repository.listenTaskChanges(uid, callback);
  }
  listenGoogleCalendarEvents(
    uid: string,
    calendarId: string,
    callback: ListenGoogleCalendarEventCallback
  ): () => void {
    return this.repository.listenGoogleCalendarEvents(
      uid,
      calendarId,
      callback
    );
  }
  getTasks(
    uid: string,
    taskIds: string[]
  ): Promise<{ [taskId: string]: DateTask }> {
    return this.repository.getTasks(uid, taskIds);
  }
}
