/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import {
  IconButton,
  IconSun,
  SkipNavLink,
  SkipNavContent,
  Tooltip,
  useTheme,
  ThemeColors,
  Theme,
  ThemeProvider,
} from 'sancho';
import { useDarkMode } from '../darkmode';
import GlobalStyle from './GlobalStyle';
export { default as SideBySide } from './SideBySide';

// Derived from https://github.com/bmcmahen/sancho/blob/master/src/Theme/Providers.tsx
function mergeColors(theme: Theme, colors: ThemeColors): Theme {
  return {
    ...theme,
    shadows: colors.shadows,
    colors,
  };
}

// Derived from https://github.com/bmcmahen/sancho/blob/master/src/Theme/Providers.tsx
// with some modifications.
// See https://github.com/bmcmahen/sancho/issues/49
export interface ColorModeProps {
  darkMode: boolean;
}
const ColorMode: React.FC<ColorModeProps> = (props) => {
  const theme = useTheme();

  // memo is necessary to prevent unnecessary rerenders
  // https://reactjs.org/docs/context.html#caveats
  const adjustedTheme = React.useMemo(
    () =>
      mergeColors(theme, props.darkMode ? theme.modes.dark : theme.modes.light),
    [theme, props.darkMode]
  );

  return <ThemeProvider theme={adjustedTheme}>{props.children}</ThemeProvider>;
};

export const AppContainer: React.FC = ({ children }) => {
  const [darkMode] = useDarkMode();

  return (
    <ColorMode darkMode={darkMode}>
      <SkipNavLink />
      {children}
      <GlobalStyle />
    </ColorMode>
  );
};

export const ColumnarAppFrame: React.FC = ({ children }) => {
  return (
    <div
      css={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
      }}
    >
      {children}
    </div>
  );
};

interface SidebarProps {
  isOpenForMobile?: boolean;
}
export const Sidebar: React.FC<SidebarProps> = ({
  children,
  isOpenForMobile = false,
}) => {
  const theme = useTheme();

  return (
    <div
      css={{
        position: 'fixed',
        top: 0,
        left: 0,
        backgroundColor: theme.colors.background.layer,
        width: '100%',
        height: '100%',
        zIndex: 1,
        display: isOpenForMobile ? 'flex' : 'none',
        flexDirection: 'column',
        [theme.mediaQueries.md]: {
          position: 'relative',
          display: 'flex',
          width: 'inherit',
        },
      }}
    >
      {children}
    </div>
  );
};

interface MainProps {
  scrollable?: boolean;
}
export const Main: React.FC<MainProps> = ({ children, scrollable = false }) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <SkipNavContent />
      <main
        css={{
          flexGrow: 1,
          position: 'relative',
          height: '100%',
          boxSizing: 'border-box',
          overflow: 'hidden',
          overflowY: scrollable ? 'scroll' : 'hidden',
          backgroundColor: theme.colors.background.default,
        }}
      >
        {children}
      </main>
    </React.Fragment>
  );
};

export const Centering: React.FC = (props) => {
  return (
    <div
      css={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: '100%',
        minHeight: '100%', // Here is not `height`, but `minHeight` because if `height` is used, some inside content such as <img> are ignored to calculate the height.
      }}
    >
      {props.children}
    </div>
  );
};

export const ToggleDarkMode: React.FC = () => {
  const [darkMode, setDarkMode] = useDarkMode();

  return (
    <Tooltip content="Toggle dark mode">
      <IconButton
        onPress={() => setDarkMode(!darkMode)}
        label="Toggle dark mode"
        variant="ghost"
        icon={<IconSun />}
      />
    </Tooltip>
  );
};
