/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { useTheme } from 'sancho';

interface NoteAppHeaderProps {
  children: React.ReactNode;
}
const NoteAppHeader: React.FC<NoteAppHeaderProps> = (props) => {
  const theme = useTheme();

  return (
    <div
      css={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        boxSizing: 'border-box',
        padding: theme.spaces.sm,
        zIndex: 1,
      }}
    >
      {props.children}
    </div>
  );
};

export default React.memo(NoteAppHeader);
