import React, { useCallback } from 'react';
import { useLocation } from 'react-router-dom';
import { useTagsSubscription } from './TagsSubscriber';
import TagListContainer from './components/TagListContainer';
import NestedTagList from './components/NestedTagList';

interface ConnectedTagListProps {
  selectedTagId: string | null;
}
const ConnectedTagList: React.FC<ConnectedTagListProps> = (props) => {
  const { tags, createTag, deleteTag } = useTagsSubscription();

  const location = useLocation();

  return (
    <TagListContainer unselectLinkTo={{ ...location, search: '' }}>
      <NestedTagList
        tags={tags}
        selectedTagId={props.selectedTagId}
        onTagCreate={useCallback((name) => createTag({ name }), [createTag])}
        onTagDelete={useCallback((tagId) => deleteTag(tagId), [deleteTag])}
        tagLinkToFn={useCallback(
          (tag) => ({ ...location, search: `tagId=${tag.id}` }),
          [location]
        )}
      />
    </TagListContainer>
  );
};

export default React.memo(ConnectedTagList);
