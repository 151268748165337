/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import styled from '@emotion/styled';
import { useTheme, List, ListItem } from 'sancho';
import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import differenceInDays from 'date-fns/differenceInDays';
import { Note } from '../repositories';

export type NoteListItemData = Pick<Note, 'title' | 'plainText' | 'updatedAt'>;

const NoteListText = styled.p`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin: 0;
`;

interface NoteListItemProps {
  note: NoteListItemData;
  currentDate: Date;
  onClick: () => void;
  selected: boolean;
}
// It's not worth to memoize this component because `currentData` prop frequently changes.
const NoteListItem: React.FC<NoteListItemProps> = (props) => {
  const theme = useTheme();

  const updatedAt = props.note.updatedAt.toDate();
  const dateStr = format(updatedAt, 'PPp'); // TODO: i18n
  const shownDateStr =
    differenceInDays(updatedAt, props.currentDate) === 0
      ? formatDistance(updatedAt, props.currentDate)
      : dateStr;

  return (
    <ListItem
      css={{
        borderLeft: `${theme.spaces.xs} solid`,
        borderLeftColor: props.selected
          ? theme.colors.intent.primary.light
          : 'rgba(0,0,0,0)',
        boxSizing: 'border-box',
        backgroundColor: props.selected
          ? theme.colors.background.tint1
          : undefined,
      }}
      primary={props.note.title || '(Untitled)'}
      secondary={
        <React.Fragment>
          {props.note.plainText && (
            <NoteListText>
              {props.note.plainText.slice(undefined, 50)}
            </NoteListText>
          )}
          <small title={dateStr}>{shownDateStr}</small>
        </React.Fragment>
      }
      onClick={props.onClick}
    />
  );
};

interface NoteListProps {
  notes: [string, NoteListItemData][];
  onSelect: (key: string) => void;
  currentDateOverridden?: Date;
  selectedNoteId?: string | null;
}
const NoteList: React.FC<NoteListProps> = (props) => {
  const [currentDate, setCurrentDate] = useState(new Date());

  useEffect(() => {
    const interval = 60 * 1000;
    let timeout: NodeJS.Timeout;

    const updateCurrentDate = (): void => {
      setCurrentDate(new Date());

      timeout = setTimeout(updateCurrentDate, interval);
    };

    updateCurrentDate();

    return () => clearTimeout(timeout);
  }, []);

  return (
    <List css={{ flexGrow: 2, overflowY: 'scroll' }}>
      {props.notes.map(([key, note]) => (
        <NoteListItem
          key={key}
          note={note}
          selected={key === props.selectedNoteId}
          currentDate={props.currentDateOverridden || currentDate}
          onClick={() => props.onSelect(key)}
        />
      ))}
    </List>
  );
};

export default NoteList;
