import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
  useImperativeHandle,
} from 'react';
import { createEditor, Node } from 'slate';
import { withReact, ReactEditor } from 'slate-react';
import { withHistory, HistoryEditor } from 'slate-history';
import {
  withMarkdown,
  TopLevelElement,
  MarkdownEnhancedEditor,
} from '@tasquet/slate-markdown-plugin';
import * as Comlink from 'comlink';
import makeMarkdownListeners from './markdown-listeners';
import SlateEditor, { SlateEditorRef } from './SlateEditor';
import SkeletonEditableLazy from './components/SkeletonEditableLazy';
import Toolbar from './Toolbar';
import { TasquetConnectedEditor } from './commands';
import { useNoteSubscription } from './NoteSubscriber';
import { Repository } from '../repositories';
import { Worker as DeserializeWorkerType } from './deserialize.worker';

// eslint-disable-next-line @typescript-eslint/no-var-requires, import/no-webpack-loader-syntax
const DeserializeWorker = require('worker-loader?esModule=false!./deserialize.worker');

// NOTE: deserialized object is copied by "structured clone" (similar to deep copy) and transferred.
// So make sure the all contained objects are clonable.
// See https://github.com/GoogleChromeLabs/comlink/blob/master/structured-clone-table.md

const makeSlateEditor = (
  repository: Repository,
  uid: string | undefined
): MarkdownEnhancedEditor<ReactEditor & HistoryEditor> => {
  const reactEditor = withHistory(withReact(createEditor()));

  if (!uid) {
    return withMarkdown(reactEditor);
  }

  const markdownEventListeners = makeMarkdownListeners(reactEditor, {
    onTaskCreate(taskId, task) {
      repository.setTask(uid, taskId, task);
      // TODO: commit and flush the throttled tasks just before re-rendering
    },
    onTaskUpdate(taskId, task) {
      repository.setTask(uid, taskId, task);
    },
    onTaskDelete(taskId) {
      repository.deleteTask(uid, taskId);
    },
  });
  return withMarkdown(reactEditor, markdownEventListeners);
};

export interface NoteEditorRef {
  focus: () => void;
}
const NoteEditor = React.forwardRef<NoteEditorRef>((props, ref) => {
  const {
    uid,
    directoryPath,
    noteId,
    initialNote,
    repository,
  } = useNoteSubscription();

  const [editor, setEditor] = useState<
    MarkdownEnhancedEditor<ReactEditor & HistoryEditor>
  >();
  const [slateValue, setSlateValue] = useState<TopLevelElement[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setEditor(undefined);

    const timeout = setTimeout(() => {
      const editor = makeSlateEditor(repository, uid);
      setEditor(editor);
    });
    return () => clearTimeout(timeout);
  }, [repository, uid]);

  // TODO: Do not use useMemo() as cache
  const getTasks = useMemo(() => {
    if (uid == null) {
      return (): ReturnType<typeof repository.getTasks> => Promise.resolve({});
    }
    return repository.getTasks.bind(repository, uid);
  }, [repository, uid]);

  useEffect(() => {
    // NOTE: if `editor` is null, initialization including deserializing the note, which can be computationally costly, is not necessary.
    // Skipping unnecessary heavy process is important.
    // See https://github.com/tuttieee/tasquet/pull/1219
    if (editor == null) {
      return;
    }

    let unmounted = false;

    const deserializeWorker = new DeserializeWorker();
    const deserializeApi = Comlink.wrap<DeserializeWorkerType>(
      deserializeWorker
    );

    async function initializeAsync(): Promise<void> {
      if (initialNote == null) {
        return;
      }

      if (unmounted) {
        return;
      }

      const [slateValue, tasksMap] = await Promise.all([
        deserializeApi.deserializeFromPlainText(initialNote.plainText || ''),
        getTasks(Object.keys(initialNote.taskElementIndexMap)),
      ]);

      if (unmounted) {
        return;
      }

      // Here, a new object `slateValueWithTaskIds` containing the same values as `slateValue` is created to set `taskId`s because
      // `slateValue` is not extensible (https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Errors/Cant_define_property_object_not_extensible)
      // and `taskId` cannot be assigned to its objects.
      const slateValueWithTaskIds: TopLevelElement[] = slateValue.map(
        (element) => Object.assign({}, element)
      );
      // Set `taskId`s based on `taskMap`.
      for (const [taskId, index] of Object.entries(
        initialNote.taskElementIndexMap
      )) {
        slateValueWithTaskIds[index].taskId = taskId;
        slateValueWithTaskIds[index].task = tasksMap[taskId];
      }

      if (editor) {
        editor.history.undos = [];
        editor.history.redos = [];
      }
      setSlateValue(slateValueWithTaskIds);
      setLoading(false);
    }

    setLoading(true);
    initializeAsync();

    return () => {
      setLoading(false);
      unmounted = true;
      deserializeWorker.terminate();
    };
  }, [initialNote, editor, getTasks]);

  const onChange = useCallback(
    (value: Node[]) => {
      if (!editor) {
        return;
      }
      if (editor.operations.every((op) => op.type === 'set_selection')) {
        return;
      }

      setSlateValue(value as TopLevelElement[]);

      if (uid == null || directoryPath == null || noteId == null) {
        // If loading note failed, the updated value that must be empty must not be written to the database.
        // That case can happen for exapmle when the device is offline.
        return;
      }

      repository.updateNote(uid, directoryPath, noteId, {
        slateValue: value as TopLevelElement[],
      });
    },
    [repository, uid, directoryPath, noteId, editor]
  );

  useEffect(() => {
    if (editor == null || uid == null) {
      return;
    }

    return repository.listenTaskChanges(uid, (changes) => {
      changes.forEach((change) => {
        if (change.type === 'removed') {
          return;
        }

        TasquetConnectedEditor.applyTask(editor, change.id, change.task);
      });
    });
  }, [repository, uid, editor]);

  const editorRef = useRef<SlateEditorRef>(null);
  useImperativeHandle(ref, () => ({
    focus() {
      editorRef.current?.focus();
    },
  }));

  if (editor == null) {
    return null;
  }

  if (loading) {
    return <SkeletonEditableLazy delay={1000} />;
  }

  return (
    <>
      <SlateEditor
        ref={editorRef}
        editor={editor}
        value={slateValue}
        onChange={onChange}
      />
      <Toolbar editor={editor} />
    </>
  );
});

NoteEditor.displayName = 'NoteEditor';

export default React.memo(NoteEditor);
