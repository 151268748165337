import React from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { CalendarOptions } from '@fullcalendar/common';

const plugins = [timeGridPlugin, interactionPlugin];

const CustomizedFullCalendar = React.forwardRef<FullCalendar, CalendarOptions>(
  (props, ref) => {
    return (
      <FullCalendar
        ref={ref}
        {...props}
        initialView="timeGridWeek"
        plugins={plugins}
        editable
        eventClassNames={function (arg) {
          if (arg.event.extendedProps.focused) {
            return ['focused'];
          }
          return [];
        }}
      />
    );
  }
);

CustomizedFullCalendar.displayName = 'CustomizedFullCalendar';

export default React.memo(CustomizedFullCalendar);
