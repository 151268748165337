import { useEffect } from 'react';
import { useToast } from 'sancho';
import * as serviceWorker from '../serviceWorker';

export const useServiceWorker = (): void => {
  const toast = useToast();

  useEffect(() => {
    serviceWorker.register({
      onSuccess(registration) {
        toast({
          title: 'App content is cached for offline use and PWA is available.',
          duration: null,
          intent: 'info',
          position: 'bottom-right',
        });
      },
      onUpdate(registration) {
        toast({
          title:
            'New content is available and will be used when all tabs for this page are closed',
          duration: null,
          intent: 'info',
          position: 'bottom-right',
        });
      },
    });
  }, [toast]);
};
