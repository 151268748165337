import React from 'react';
import { MenuList, MenuItem, IconLogIn, IconUserPlus } from 'sancho';
import { Link } from 'react-router-dom';

const AuthMenuForLoggedOut: React.FC = () => {
  return (
    <MenuList>
      <MenuItem contentBefore={<IconUserPlus />} component={Link} to="/signup">
        Sign up
      </MenuItem>
      <MenuItem contentBefore={<IconLogIn />} component={Link} to="/login">
        Log in
      </MenuItem>
    </MenuList>
  );
};

export default React.memo(AuthMenuForLoggedOut);
