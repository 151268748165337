import firebase from 'firebase/app';
import { calendar_v3 } from 'googleapis';
import {
  TopLevelElement,
  serializeToPlainText,
} from '@tasquet/slate-markdown-plugin';
import { googleCalendarIdPrefix } from '../../const';
import { ProxiedData } from '../../../../../interface/firestore/serviceDataProxy';
import { DateTimeTask, DateTask } from '../ConnectedNoteApp/logics/task-parser';
import {
  Repository,
  OnNoteChange,
  OnNoteDelete,
  ListenNotesCallback,
  ListenGoogleCalendarEventCallback,
  Note,
  NoteCreate,
  TaskChange,
  ListenTaskChangesCallback,
  TaskChangeAddedModified,
  TaskChangeRemoved,
  DeserializedNoteUpdate,
} from '.';
import { directoryPathToDocPath } from '../directory';

interface FirestoreNote extends Omit<Note, 'tagIds'> {
  user: string;
  tagIds: {
    [tagId: string]: {
      title: string;
      createdAt: firebase.firestore.Timestamp;
      updatedAt: firebase.firestore.Timestamp;
    };
  };
  revision: number;
}

interface NoteFirestoreCreate
  extends Omit<FirestoreNote, 'tagIds' | 'createdAt' | 'updatedAt'> {
  user: string;
  tagIds: {
    [tagId: string]: {
      title: string;
      createdAt: firebase.firestore.FieldValue;
      updatedAt: firebase.firestore.FieldValue;
    };
  };
  createdAt: firebase.firestore.FieldValue;
  updatedAt: firebase.firestore.FieldValue;
}

interface NoteFirestoreUpdate
  extends Omit<FirestoreNote, 'tagIds' | 'createdAt' | 'updatedAt'> {
  tagIds: {
    [tagId: string]: {
      title: string;
      createdAt: firebase.firestore.FieldValue;
      updatedAt: firebase.firestore.FieldValue;
    };
  };
  updatedAt: firebase.firestore.FieldValue;
}

const getTaskElementIndexMap = (
  value: TopLevelElement[]
): Note['taskElementIndexMap'] => {
  const taskElementIndexMap: Note['taskElementIndexMap'] = {};
  for (let index = 0; index < value.length; ++index) {
    const topLevelElement = value[index];
    const taskId = topLevelElement.taskId as string | undefined;
    const task = topLevelElement.task as DateTimeTask | undefined;
    if (taskId && task) {
      taskElementIndexMap[taskId] = index;
    }
  }

  return taskElementIndexMap;
};

const validateTaskDoc = (
  docData: firebase.firestore.DocumentData
): DateTask | undefined => {
  if (typeof docData.title !== 'string') {
    return undefined;
  }

  const start =
    typeof docData.start?.toDate === 'function'
      ? docData.start?.toDate()
      : null;
  const end =
    typeof docData.end?.toDate === 'function' ? docData.end?.toDate() : null;

  const task: DateTask = {
    title: docData.title,
    start: start,
    end: end,
  };

  return task;
};

export class FirestoreRepository implements Repository {
  private firestore: firebase.firestore.Firestore;

  private latestRevisionMap: { [noteDocPath: string]: number } = {};

  constructor(firestore: firebase.firestore.Firestore) {
    this.firestore = firestore;
  }

  createNote(
    uid: string,
    directoryPath: string,
    note: NoteCreate
  ): Promise<string> {
    const tagIds: NoteFirestoreCreate['tagIds'] = {};
    for (const tagId of note.tagIds) {
      tagIds[tagId] = {
        title: note.title,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };
    }

    const newNote: NoteFirestoreCreate = {
      ...note,
      user: uid,
      revision: 1,
      tagIds,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };
    const directoryDocPath = directoryPathToDocPath(directoryPath);

    const notesRef = this.firestore
      .doc(`users/${uid}${directoryDocPath}`)
      .collection('notes');

    return notesRef.add(newNote).then((docRef) => {
      return docRef.id;
    });
  }

  async updateNote(
    uid: string,
    directoryPath: string,
    noteId: string,
    deserializedNote: Partial<DeserializedNoteUpdate>
  ): Promise<void> {
    const noteRef = this.getNoteRef(uid, directoryPath, noteId);
    const latestRevision = this.latestRevisionMap[noteRef.path];
    const nextRevision = latestRevision != null ? latestRevision + 1 : 1;

    const updatedNote: Partial<NoteFirestoreUpdate> = {
      revision: nextRevision,
      updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
    };

    if ('title' in deserializedNote) {
      updatedNote.title = deserializedNote.title;
    }

    if ('tagIds' in deserializedNote) {
      const currentNoteDoc = await noteRef.get();
      const currnetNote = currentNoteDoc.data();
      const title = currnetNote?.title;
      const createdAt = currnetNote?.createdAt;
      if (title != null && createdAt != null) {
        const tagIds: NoteFirestoreUpdate['tagIds'] = {};
        if (deserializedNote.tagIds) {
          for (const tagId of deserializedNote.tagIds) {
            tagIds[tagId] = {
              title,
              createdAt,
              updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
            };
          }
        }
        updatedNote.tagIds = tagIds;
      } else {
        throw new Error('The note is already removed');
      }
    }

    if ('slateValue' in deserializedNote) {
      if (deserializedNote.slateValue) {
        const taskElementIndexMap = getTaskElementIndexMap(
          deserializedNote.slateValue
        );
        const plainText = serializeToPlainText(deserializedNote.slateValue);
        updatedNote.taskElementIndexMap = taskElementIndexMap;
        updatedNote.plainText = plainText;
      } else {
        updatedNote.plainText = undefined;
      }
    }

    this.latestRevisionMap[noteRef.path] = nextRevision;

    return noteRef.update(updatedNote);
  }

  listenNote(
    uid: string,
    directoryPath: string,
    noteId: string,
    onChange: OnNoteChange,
    onDelete: OnNoteDelete
  ): () => void {
    const noteRef = this.getNoteRef(uid, directoryPath, noteId);

    let initialLoaded = false;

    const unsubscribe = noteRef.onSnapshot((doc) => {
      if (!doc.exists) {
        onDelete();
        return;
      }
      const rawNote = doc.data();
      if (rawNote == null) {
        onChange(null);
        return;
      }
      const filledNote: Note = {
        title: rawNote.title || '',
        plainText: rawNote.plainText || '',
        taskElementIndexMap: rawNote.taskElementIndexMap || {},
        tagIds: Object.keys(rawNote.tagIds || {}),
        createdAt:
          rawNote.createdAt ||
          firebase.firestore.Timestamp.fromDate(new Date()),
        updatedAt:
          rawNote.updatedAt ||
          firebase.firestore.Timestamp.fromDate(new Date()),
      };

      if (!initialLoaded) {
        const revision = rawNote.revision != null ? rawNote.revision : 0; // This 0 is same to the defualt value of revision field defined in firestore.rules
        this.latestRevisionMap[noteRef.path] = revision;
      }

      initialLoaded = true;
      onChange(filledNote);
    });

    return unsubscribe;
  }

  listenNotes(
    uid: string,
    directoryPath: string,
    tagId: string | null,
    callback: ListenNotesCallback
  ): () => void {
    const directoryDocPath = directoryPathToDocPath(directoryPath);

    const notesRef = this.firestore
      .doc(`users/${uid}${directoryDocPath}`)
      .collection('notes');

    let query: firebase.firestore.Query;
    if (tagId) {
      const tagField = `tagIds.${tagId}.updatedAt`;
      query = notesRef
        .where(tagField, '>', new Date(0))
        .orderBy(tagField, 'desc');
    } else {
      query = notesRef.orderBy('updatedAt', 'desc');
    }

    return query.onSnapshot((querySnapshot) => {
      const newFirebaseNotes = querySnapshot.docs
        .map<[string, Note] | undefined>((doc) => {
          if (!doc.exists) {
            return undefined;
          }

          const fetchedNote = doc.data() as Partial<Note>;
          const filledNote: Note = {
            title: fetchedNote.title || '',
            plainText: fetchedNote.plainText || '',
            taskElementIndexMap: fetchedNote.taskElementIndexMap || {},
            tagIds: Object.keys(fetchedNote.tagIds || {}),
            createdAt:
              fetchedNote.createdAt ||
              firebase.firestore.Timestamp.fromDate(new Date()),
            updatedAt:
              fetchedNote.updatedAt ||
              firebase.firestore.Timestamp.fromDate(new Date()),
          };

          return [doc.id, filledNote];
        })
        .filter((n): n is [string, Note] => !!n);

      callback(newFirebaseNotes);
    });
  }

  deleteNote(
    uid: string,
    directoryPath: string,
    noteId: string
  ): Promise<void> {
    const noteRef = this.getNoteRef(uid, directoryPath, noteId);

    return noteRef.delete();
  }

  setTask(uid: string, taskId: string, data: DateTask): Promise<void> {
    const taskRef = this.firestore
      .collection('users')
      .doc(uid)
      .collection('tasks')
      .doc(taskId);
    return taskRef.set(data);
  }

  deleteTask(uid: string, taskId: string): Promise<void> {
    const taskRef = this.firestore
      .collection('users')
      .doc(uid)
      .collection('tasks')
      .doc(taskId);
    return taskRef.delete();
  }

  listenTaskChanges(
    uid: string,
    callback: ListenTaskChangesCallback
  ): () => void {
    const collectionRef = this.firestore
      .collection('users')
      .doc(uid)
      .collection('tasks');
    return collectionRef.onSnapshot((querySnapshot) => {
      const changes: TaskChange[] = querySnapshot
        .docChanges()
        .map<TaskChange>((change) => {
          const id = change.doc.id;
          if (change.type === 'added' || change.type === 'modified') {
            const docData = change.doc.data();
            const task = validateTaskDoc(docData);
            if (task == null) {
              throw new Error(
                `Unexpected task data ${JSON.stringify(docData)}`
              );
            }
            const ret: TaskChangeAddedModified = {
              type: change.type,
              id,
              task,
            };

            return ret;
          } else if (change.type === 'removed') {
            const ret: TaskChangeRemoved = {
              type: 'removed',
              id,
            };
            return ret;
          } else {
            throw new Error(
              `Unexpected change type: ${change.type} on ${JSON.stringify(
                change
              )}`
            );
          }
        });

      callback(changes);
    });
  }

  getTasks(
    uid: string,
    taskIds: string[]
  ): Promise<{ [taskId: string]: DateTask }> {
    if (taskIds.length === 0) {
      return Promise.resolve({});
    }

    const collectionRef = this.firestore
      .collection('users')
      .doc(uid)
      .collection('tasks');

    const taskRefs = collectionRef.where(
      firebase.firestore.FieldPath.documentId(),
      'in',
      taskIds
    );

    return taskRefs.get().then((querySnapshot) => {
      const tasksMap: { [taskId: string]: DateTask } = {};
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        const task = validateTaskDoc(docData);
        if (task) {
          tasksMap[doc.id] = task;
        }
      });

      return tasksMap;
    });
  }

  listenGoogleCalendarEvents(
    uid: string,
    calendarId: string,
    callback: ListenGoogleCalendarEventCallback
  ): () => void {
    const collectionRef = this.firestore
      .collection('users')
      .doc(uid)
      .collection('serviceDataProxies')
      .doc('googleCalendar')
      .collection('calendars')
      .doc(calendarId)
      .collection('events');

    return collectionRef.onSnapshot((querySnapshot) => {
      querySnapshot.docChanges().forEach((change) => {
        if (!change.doc.id.startsWith(googleCalendarIdPrefix)) {
          return;
        }

        if (change.type === 'removed') {
          return;
        }

        if (change.type === 'added' || change.type === 'modified') {
          const docData = change.doc.data() as ProxiedData<
            calendar_v3.Schema$Event
          >;
          if (docData.status !== 'updated') return;
          const event = docData.data;

          callback(change.doc.id, event);
        }
      });
    });
  }

  private getNoteRef(
    uid: string,
    directoryPath: string,
    noteId: string
  ): firebase.firestore.DocumentReference<Partial<FirestoreNote>> {
    const directoryDocPath = directoryPathToDocPath(directoryPath);

    return this.firestore
      .doc(`users/${uid}${directoryDocPath}`)
      .collection('notes')
      .doc(noteId);
  }
}
