import React from 'react';
import { Button, IconArrowUpRight } from 'sancho';
import { authGoogleAPI, useGoogleAPI } from '../../GoogleApiAuth';

const GoogleCalendarButton: React.FC = () => {
  useGoogleAPI();

  return (
    <Button
      onPress={authGoogleAPI}
      size="sm"
      variant="outline"
      iconBefore={<IconArrowUpRight />}
    >
      Sync with Google Calendar
    </Button>
  );
};

export default React.memo(GoogleCalendarButton);
