import React, { useCallback } from 'react';
import NoteTagListComponent from './components/NoteTagList';
import { useNoteSubscription } from './NoteSubscriber';
import { useTagsSubscription } from '../TagsSubscriber';
import { Tag } from '../TagsSubscriber';

const NoteTagList: React.FC = () => {
  const {
    note,
    repository,
    uid,
    directoryPath,
    noteId,
  } = useNoteSubscription();
  const { tags } = useTagsSubscription();

  const onRemove = useCallback(
    (tagId: string) => {
      if (
        uid == null ||
        directoryPath == null ||
        noteId == null ||
        note == null
      ) {
        return null;
      }

      // TODO: Split updating the tags and text. Throttling is not necessary for tags.
      (async function () {
        await repository.flush();
        await repository.updateNote(uid, directoryPath, noteId, {
          tagIds: note.tagIds.filter((ti) => ti !== tagId),
        });
        await repository.flush();
      })();
    },
    [repository, uid, directoryPath, noteId, note]
  );

  if (note == null) {
    return null;
  }

  const noteTags = note.tagIds
    .map((tagId) => tags.find((tag) => tag.id === tagId))
    .filter((maybeTag): maybeTag is Tag => !!maybeTag);

  return <NoteTagListComponent tags={noteTags} onRemove={onRemove} />;
};

export default React.memo(NoteTagList);
