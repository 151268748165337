/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { useTheme } from 'sancho';

const ItemContainer: React.FC<React.ComponentProps<'div'>> = (props) => {
  const theme = useTheme();

  return (
    <div
      {...props}
      css={{
        padding: theme.spaces.md,
        display: 'flex',
        alignItems: 'center',
      }}
    />
  );
};
export default React.memo(ItemContainer);
