import React from 'react';
import { useLocation } from 'react-router-dom';
import CalendarContainer from './components/CalendarContainer';
import CalendarFooter from './components/CalendarFooter';
import GoogleCalendarButton from './GoogleCalendarButton';
import ConnectedCalendar from './ConnectedCalendar';

interface ConnectedCalendarAppProps {
  uid: string;
}
const ConnectedCalendarApp: React.FC<ConnectedCalendarAppProps> = (props) => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const focusedEventId = search.get('eventFocus');

  return (
    <CalendarContainer>
      <ConnectedCalendar uid={props.uid} focusedEventId={focusedEventId} />
      <CalendarFooter>
        <GoogleCalendarButton />
      </CalendarFooter>
    </CalendarContainer>
  );
};

export default React.memo(ConnectedCalendarApp);
