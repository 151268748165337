/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { Text, useTheme } from 'sancho';
import { Link, LinkProps } from 'react-router-dom';

interface TagListProps {
  unselectLinkTo: LinkProps['to'];
  children: React.ReactNode;
}
const TagList: React.FC<TagListProps> = (props) => {
  const theme = useTheme();

  return (
    <div>
      <div
        css={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Text variant="lead">Tags</Text>
        <Link
          to={props.unselectLinkTo}
          css={{
            textDecoration: 'none',
            padding: `${theme.spaces.xs} ${theme.spaces.sm}`,
            '&:hover': {
              backgroundColor: theme.colors.background.tint1,
            },
          }}
        >
          <Text>Unselect tag</Text>
        </Link>
      </div>
      {props.children}
    </div>
  );
};

export default React.memo(TagList);
