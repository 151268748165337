import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Alert, Text, Toolbar, Link as SanchoLink } from 'sancho';
import { Main, Centering } from '../../components/layout';
import { Logo } from '../../components/logo';

interface GithubErrorProps {
  message: string;
}
const GithubError: React.FC<GithubErrorProps> = (props) => (
  <Main>
    <Centering>
      <Logo />
      <Alert
        intent="danger"
        title="An error occurred."
        subtitle={props.message}
      />
      <Toolbar>
        <Text variant="paragraph">
          <SanchoLink component={RouterLink} to="/">
            Back to top page
          </SanchoLink>
        </Text>
      </Toolbar>
    </Centering>
  </Main>
);

export default GithubError;
