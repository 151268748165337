import { PhrasingContent } from 'mdast';

export const extractURLsFromNodes = (nodes: PhrasingContent[]): string[] => {
  let urls: string[] = [];
  for (const child of nodes) {
    if (child.type === 'link') {
      urls.push(child.url);
    }
    if (
      child.type === 'emphasis' ||
      child.type === 'strong' ||
      child.type === 'delete' ||
      child.type === 'footnote'
    ) {
      const childUrls = extractURLsFromNodes(child.children);
      urls = urls.concat(childUrls);
    }
  }

  return urls;
};
