import React from 'react';
import DeleteButton from './components/DeleteButton';
import { useNoteSubscription } from './NoteSubscriber';

const NoteDelete: React.FC = () => {
  const { deleteNote } = useNoteSubscription();

  return <DeleteButton onDelete={deleteNote} />;
};

export default React.memo(NoteDelete);
