import React, { useState, useEffect, useCallback } from 'react';
import NoteTitleInput, { NoteTitleInputRef } from './components/NoteTitleInput';
import { useNoteSubscription } from './NoteSubscriber';

export type NoteTitleRef = NoteTitleInputRef;

interface NoteTitleProps {
  onFocusNextRequested?: () => void;
}
const NoteTitle = React.forwardRef<NoteTitleInputRef, NoteTitleProps>(
  (props, ref) => {
    const [title, setTitle] = useState('');

    const {
      initialNote,
      uid,
      directoryPath,
      noteId,
      repository,
    } = useNoteSubscription();

    useEffect(() => {
      setTitle(initialNote ? initialNote.title : '');
    }, [initialNote]);

    const onNoteTitleChange = useCallback(
      (title: string) => {
        setTitle(title);

        if (uid != null && directoryPath != null && noteId != null) {
          repository.updateNote(uid, directoryPath, noteId, { title });
        }
      },
      [uid, directoryPath, noteId, repository]
    );

    return (
      <NoteTitleInput
        ref={ref}
        value={title}
        onChange={onNoteTitleChange}
        onBlur={repository.flush}
        onFocusNextRequested={props.onFocusNextRequested}
      />
    );
  }
);

NoteTitle.displayName = 'NoteTitle';

export default React.memo(NoteTitle);
