/*global process*/

import firebase from 'firebase/app';
export let firebaseConfig: Parameters<typeof firebase.initializeApp>[0];

if (process.env.NODE_ENV === 'development') {
  firebaseConfig = {
    apiKey: 'AIzaSyB2YUczREZyO9fa0O0ffX1pOOTI3slz1o4',
    authDomain: 'tasquet-staging.firebaseapp.com',
    databaseURL: 'https://tasquet-staging.firebaseio.com',
    projectId: 'tasquet-staging',
    storageBucket: 'tasquet-staging.appspot.com',
    messagingSenderId: '77197513678',
    appId: '1:77197513678:web:130c624b87d6578702519f',
    measurementId: 'G-5DWKM181P6',
  };
} else if (process.env.NODE_ENV === 'production') {
  if (process.env.REACT_APP_FIREBASE_CONFIG === 'production') {
    firebaseConfig = {
      apiKey: 'AIzaSyBR_Dq-vdM1867iqY4h-eqjKbp3yaDhw2A',
      authDomain: 'tasquet-production.firebaseapp.com',
      databaseURL: 'https://tasquet-production.firebaseio.com',
      projectId: 'tasquet-production',
      storageBucket: 'tasquet-production.appspot.com',
      messagingSenderId: '391229489889',
      appId: '1:391229489889:web:9c8a46926b5104ccaf2da0',
      measurementId: 'G-WSZ3L3NCEQ',
    };
  } else {
    firebaseConfig = {
      apiKey: 'AIzaSyB2YUczREZyO9fa0O0ffX1pOOTI3slz1o4',
      authDomain: 'tasquet-staging.firebaseapp.com',
      databaseURL: 'https://tasquet-staging.firebaseio.com',
      projectId: 'tasquet-staging',
      storageBucket: 'tasquet-staging.appspot.com',
      messagingSenderId: '77197513678',
      appId: '1:77197513678:web:130c624b87d6578702519f',
      measurementId: 'G-5DWKM181P6',
    };
  }
} else if (process.env.NODE_ENV === 'test') {
  firebaseConfig = {
    apiKey: 'test',
    appId: 'test',
    measurementId: 'test',
    projectId: 'test',
  };
}
