import React from 'react';
import { useTheme, IconHardDrive } from 'sancho';

interface OfflinePersistenceIconProps {
  enabled: boolean;
}
const OfflinePersistenceIcon: React.FC<OfflinePersistenceIconProps> = (
  props
) => {
  const theme = useTheme();

  return (
    <IconHardDrive
      css={{
        stroke: props.enabled
          ? theme.colors.palette.green.light
          : theme.colors.palette.red.base,
      }}
    />
  );
};

export default OfflinePersistenceIcon;
