import React, { useCallback } from 'react';
import { Editor, Transforms, Point } from 'slate';
import { ReactEditor } from 'slate-react';
import EditorContainerComponent from './components/EditorContainer';

interface EditorBackgroundProps {
  editor: ReactEditor;
  children: React.ReactNode;
}
const EditorContainer: React.FC<EditorBackgroundProps> = (props) => {
  return (
    <EditorContainerComponent
      onClick={useCallback(
        (e) => {
          if (e.currentTarget !== e.target) {
            return;
          }

          const end = Editor.end(props.editor, []);

          // NOTE: This is necessary to focus this point (`end`) again in case the current focus is already there.
          if (
            props.editor.selection &&
            Point.equals(props.editor.selection.focus, end)
          ) {
            Transforms.deselect(props.editor);
          }
          Transforms.select(props.editor, end);

          ReactEditor.focus(props.editor);
        },
        [props.editor]
      )}
    >
      {props.children}
    </EditorContainerComponent>
  );
};

export default React.memo(EditorContainer);
