/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { Button, IconSettings, Popover, MenuDivider } from 'sancho';
import { useUser } from '../../auth/hooks';
import { signout } from '../../firebase/hooks';
import AuthMenuForLoggedIn from './components/AuthMenuForLoggedIn';
import AuthMenuForLoggedOut from './components/AuthMenuForLoggedOut';
import ThirdPartyMenu from './components/ThirdPartyMenu';
import DarkModeToggle from './DarkModeToggle';

let MainMenuContent: React.FC = () => {
  const user = useUser();

  const loggedIn = user != null && !user.isAnonymous;

  return (
    <React.Fragment>
      {loggedIn ? (
        <AuthMenuForLoggedIn onLogout={signout} />
      ) : (
        <AuthMenuForLoggedOut />
      )}
      <MenuDivider />
      <ThirdPartyMenu />
      <MenuDivider />
      <DarkModeToggle />
    </React.Fragment>
  );
};
MainMenuContent = React.memo(MainMenuContent);

const MainMenu: React.FC = () => {
  return (
    <Popover content={<MainMenuContent />}>
      <Button iconBefore={<IconSettings />} label="Menu" variant="outline">
        Menu
      </Button>
    </Popover>
  );
};

export default React.memo(MainMenu);
