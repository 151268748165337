/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { useTheme, Pager, PagerProps } from 'sancho';
import useMedia from 'use-media';

interface SidebarColumnProps {
  width?: string;
  hiddenForDesktop?: boolean;
}
export const SidebarColumn: React.FC<SidebarColumnProps> = (props) => {
  const { width, hiddenForDesktop: hidden = false } = props;

  const theme = useTheme();

  return (
    <div
      css={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        [theme.mediaQueries.md]: {
          width,
          display: hidden ? 'none' : 'flex',
        },
      }}
    >
      {props.children}
    </div>
  );
};

// Derived from
// https://github.com/bmcmahen/sancho/blob/82ecf45985a3dd991e1c94e23743e7ef02ef142a/src/Toolbar.tsx#L17-L18
const MOBILE_HEIGHT = '56px';
const DESKTOP_HEIGHT = '64px';

type SidebarLaneChild = React.ReactElement<
  React.ComponentProps<typeof SidebarColumn>
>;
interface SidebarLanesProps {
  page: number;
  onPageChange: PagerProps['onRequestChange'];
  children:
    | SidebarLaneChild
    | Array<SidebarLaneChild>
    | boolean
    | null
    | undefined;
}
export const SidebarLanes: React.FC<SidebarLanesProps> = (props) => {
  const { children, page, onPageChange: onRequestChange } = props;

  const theme = useTheme();
  const isMedium = useMedia({ minWidth: theme.breakpoints.md });

  if (isMedium) {
    return (
      <div
        css={{
          height: '100%',
          position: 'relative',
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        {props.children}
      </div>
    );
  }

  return (
    <Pager
      css={{
        height: '100%',
        position: 'relative',
      }}
      value={page}
      onRequestChange={onRequestChange}
    >
      {React.Children.toArray(children)}
    </Pager>
  );
};

interface SidebarMenuContainer {
  menuBlock: React.ReactNode;
}
export const SidebarMenuContainer: React.FC<SidebarMenuContainer> = (props) => {
  const theme = useTheme();

  return (
    <div
      css={{
        height: '100%',
        width: '100%',
        boxSizing: 'border-box',
        paddingBottom: MOBILE_HEIGHT,
        [theme.mediaQueries.md]: {
          paddingBottom: DESKTOP_HEIGHT,
        },
      }}
    >
      {props.children}
      <div
        css={{
          position: 'fixed',
          bottom: 0,
        }}
      >
        {props.menuBlock}
      </div>
    </div>
  );
};
