/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { Dialog, Text, Button, useTheme } from 'sancho';

interface ReloadDialogProps {
  isOpen: React.ComponentProps<typeof Dialog>['isOpen'];
  onRequestClose: React.ComponentProps<typeof Dialog>['onRequestClose'];
}
const ReloadDialog: React.FC<ReloadDialogProps> = (props) => {
  const theme = useTheme();

  return (
    <Dialog
      isOpen={props.isOpen}
      onRequestClose={props.onRequestClose}
      mobileFullscreen
      title="Reload to apply settings"
    >
      <div css={{ padding: theme.spaces.md }}>
        <Text variant="paragraph">
          You need to reload the app to apply offline persistence settings.
        </Text>
        <Button intent="primary" onClick={() => window.location.reload()}>
          Reload
        </Button>
      </div>
    </Dialog>
  );
};

export default ReloadDialog;
