import { useState, useEffect, useGlobal } from 'reactn';
import firebase from 'firebase/app';
import { firebaseAuth } from '.';
import {
  isOfflinePersistenceRequested,
  setOfflinePersistenceRequest,
} from './local';
import { insertTutorialNotes } from '../tutorial';

export const useFirebase = (): boolean => {
  const [authInitialized, setAuthInitialized] = useState(false);
  const [currentUser, setUser] = useGlobal('user');
  const [currentFirestore, setFirestore] = useGlobal('firestore');
  const setFirestoreOfflinePersistence = useGlobal(
    'firestoreOfflinePersistence'
  )[1];
  const setFirestoreOfflinePersistenceErrCode = useGlobal(
    'firestoreOfflinePersistenceErrCode'
  )[1];

  useEffect(
    () => {
      return firebaseAuth.onAuthStateChanged((nextUser) => {
        // Auth management
        if (nextUser == null && !authInitialized) {
          firebaseAuth.signInAnonymously().then((cred) => {
            if (cred.user) {
              insertTutorialNotes(firebase.firestore(), cred.user.uid);
            }
          });
        } else {
          setUser(nextUser);
          setAuthInitialized(true);
        }

        // Firestore instance management
        if (nextUser) {
          // Logged in
          const alreadyLoggedInAndUserChanged =
            currentUser && currentUser.uid !== nextUser.uid;
          if (alreadyLoggedInAndUserChanged) {
            currentFirestore?.terminate();
            currentFirestore?.clearPersistence();
          }

          const firestore = firebase.firestore();

          const newlyLoggedIn = !currentUser;
          const shouldInitPersistence =
            newlyLoggedIn || alreadyLoggedInAndUserChanged;
          if (
            shouldInitPersistence &&
            isOfflinePersistenceRequested(nextUser.uid)
          ) {
            firestore
              .enablePersistence({ synchronizeTabs: true })
              .then(() => {
                setFirestoreOfflinePersistence(true);
              })
              .catch(function (err) {
                setFirestoreOfflinePersistenceErrCode(err.code);
              });
          }

          setFirestore(firestore);
        } else {
          // Logged out
          currentFirestore?.terminate();
          currentFirestore?.clearPersistence();
          setFirestore(undefined);
        }
      });
    },
    // NOTE: insertTutorialNotes is not included in the dependency list
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      authInitialized,
      currentUser,
      setUser,
      currentFirestore,
      setFirestore,
      setFirestoreOfflinePersistence,
      setFirestoreOfflinePersistenceErrCode,
    ]
  );

  return authInitialized;
};

type SignoutCallback = () => void | Promise<void>;
let signoutCallbacks: SignoutCallback[] = [];
export const registerSignoutCallback = (fn: SignoutCallback): (() => void) => {
  signoutCallbacks.push(fn);
  return () => {
    signoutCallbacks = signoutCallbacks.filter((cb) => cb !== fn);
  };
};
export const useSignoutCallback = (callback: SignoutCallback): void => {
  useEffect(() => {
    signoutCallbacks.push(callback);
    return () => {
      signoutCallbacks = signoutCallbacks.filter((cb) => cb !== callback);
    };
  }, [callback]);
};

export const signout = async (): Promise<void> => {
  await Promise.all(signoutCallbacks.map((cb) => cb()));
  return firebaseAuth.signOut();
};

export const useFirestore = (): firebase.firestore.Firestore | undefined => {
  const [firestore] = useGlobal('firestore');

  return firestore;
};

export const useFirestoreOfflinePersistenceRequest = (): ((
  enabled: boolean
) => void) => {
  const user = useGlobal('user')[0];
  return (enabled: boolean) => {
    if (!user) {
      return;
    }

    setOfflinePersistenceRequest(user.uid, enabled);
  };
};

export const useFirestoreOfflinePersistenceStatus = (): [
  boolean,
  boolean,
  string | undefined
] => {
  const user = useGlobal('user')[0];
  const firebaseOfflinePersistenceRequested = user
    ? isOfflinePersistenceRequested(user.uid)
    : false;

  const firestoreOfflinePersistence =
    useGlobal('firestoreOfflinePersistence')[0] || false;
  const firestoreOfflinePersistenceErrCode = useGlobal(
    'firestoreOfflinePersistenceErrCode'
  )[0];

  return [
    firebaseOfflinePersistenceRequested,
    firestoreOfflinePersistence,
    firestoreOfflinePersistenceErrCode,
  ];
};
