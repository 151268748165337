import throttle from 'lodash.throttle';
import { Cancelable } from 'lodash';
import memoizeOne from 'memoize-one';

// TODO: Contribute to @types/lodash
/* eslint-disable @typescript-eslint/no-explicit-any */
declare module 'lodash' {
  interface Cancelable<
    T extends (...args: any) => any = (...args: any) => any
  > {
    cancel(): ReturnType<T> | undefined;
    flush(): ReturnType<T> | undefined;
  }
  interface LoDashStatic {
    throttle<T extends (...args: any) => any>(
      func: T,
      wait?: number,
      options?: ThrottleSettings
    ): T & Cancelable<T>;
  }
}
/* eslint-enable */

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FunctionBoundParam2<A0, A1, a0, aa extends any[], R> = (
  boundarg0: A0,
  boundarg1: A1,
  arg0: a0,
  ...args: aa
) => R;
interface ParamBound2<A0, A1> {
  boundParams: [A0, A1] | undefined;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ThrottledFunctionBoundParam2<F extends (...args: any) => any> = F &
  Cancelable<F> &
  ParamBound2<Parameters<F>[0], Parameters<F>[1]>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function throttleParameterized2<T, A0, A1, a0, aa extends any[], R>(
  fn: FunctionBoundParam2<A0, A1, a0, aa, R>,
  wait: Parameters<typeof throttle>[1],
  options: Parameters<typeof throttle>[2],
  thisArg: T
): ThrottledFunctionBoundParam2<FunctionBoundParam2<A0, A1, a0, aa, R>> {
  let currentFn:
    | (((arg0: a0, ...args: aa) => R) &
        Cancelable<(arg0: a0, ...args: aa) => R>)
    | undefined = undefined;

  const makeFn = memoizeOne(function (
    fn: FunctionBoundParam2<A0, A1, a0, aa, R>,
    boundarg0: A0,
    boundarg1: A1
  ) {
    return throttle(
      fn.bind<T, A0, A1, [a0], R>(thisArg, boundarg0, boundarg1) as (
        arg0: a0,
        ...args: aa
      ) => R,
      wait,
      options
    );
  });

  const resultFn: ThrottledFunctionBoundParam2<FunctionBoundParam2<
    A0,
    A1,
    a0,
    aa,
    R
  >> = Object.assign(
    (boundarg0: A0, boundarg1: A1, arg0: a0, ...args: aa) => {
      const memoizedThrottledFn = makeFn(fn, boundarg0, boundarg1);
      if (memoizedThrottledFn !== currentFn) {
        currentFn?.flush();
        currentFn = memoizedThrottledFn;
        resultFn.cancel = () => {
          resultFn.boundParams = undefined;
          return memoizedThrottledFn.cancel();
        };
        resultFn.flush = () => {
          resultFn.boundParams = undefined;
          return memoizedThrottledFn.flush();
        };
        resultFn.boundParams = [boundarg0, boundarg1];
      }

      return memoizedThrottledFn(arg0, ...args);
    },
    {
      cancel: () => undefined, // Dummy implementation as an initial value
      flush: () => undefined, // Dummy implementation as an initial value
      boundParams: undefined,
    }
  );

  return resultFn;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FunctionBoundParam3<A0, A1, A2, a0, aa extends any[], R> = (
  boundarg0: A0,
  boundarg1: A1,
  boundarg2: A2,
  arg0: a0,
  ...args: aa
) => R;
interface ParamBound3<A0, A1, A2> {
  boundParams: [A0, A1, A2] | undefined;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ThrottledFunctionBoundParam3<F extends (...args: any) => any> = F &
  Cancelable<F> &
  ParamBound3<Parameters<F>[0], Parameters<F>[1], Parameters<F>[2]>;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function throttleParameterized3<T, A0, A1, A2, a0, aa extends any[], R>(
  fn: FunctionBoundParam3<A0, A1, A2, a0, aa, R>,
  wait: Parameters<typeof throttle>[1],
  options: Parameters<typeof throttle>[2],
  thisArg: T
): ThrottledFunctionBoundParam3<FunctionBoundParam3<A0, A1, A2, a0, aa, R>> {
  let currentFn:
    | (((arg0: a0, ...args: aa) => R) &
        Cancelable<(arg0: a0, ...args: aa) => R>)
    | undefined = undefined;

  const makeFn = memoizeOne(function (
    fn: FunctionBoundParam3<A0, A1, A2, a0, aa, R>,
    boundarg0: A0,
    boundarg1: A1,
    boundarg2: A2
  ) {
    return throttle(
      fn.bind<T, A0, A1, A2, [a0], R>(
        thisArg,
        boundarg0,
        boundarg1,
        boundarg2
      ) as (arg0: a0, ...args: aa) => R,
      wait,
      options
    );
  });

  const resultFn: ThrottledFunctionBoundParam3<FunctionBoundParam3<
    A0,
    A1,
    A2,
    a0,
    aa,
    R
  >> = Object.assign(
    (boundarg0: A0, boundarg1: A1, boundarg2: A2, arg0: a0, ...args: aa) => {
      const memoizedThrottledFn = makeFn(fn, boundarg0, boundarg1, boundarg2);
      if (memoizedThrottledFn !== currentFn) {
        currentFn?.flush();
        currentFn = memoizedThrottledFn;
        resultFn.cancel = () => {
          resultFn.boundParams = undefined;
          return memoizedThrottledFn.cancel();
        };
        resultFn.flush = () => {
          resultFn.boundParams = undefined;
          return memoizedThrottledFn.flush();
        };
        resultFn.boundParams = [boundarg0, boundarg1, boundarg2];
      }

      return memoizedThrottledFn(arg0, ...args);
    },
    {
      cancel: () => undefined, // Dummy implementation as an initial value
      flush: () => undefined, // Dummy implementation as an initial value
      boundParams: undefined,
    }
  );

  return resultFn;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FunctionBoundParam4<A0, A1, A2, A3, a0, aa extends any[], R> = (
  boundarg0: A0,
  boundarg1: A1,
  boundarg2: A2,
  boundarg3: A3,
  arg0: a0,
  ...args: aa
) => R;
interface ParamBound4<A0, A1, A2, A3> {
  boundParams: [A0, A1, A2, A3] | undefined;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ThrottledFunctionBoundParam4<F extends (...args: any) => any> = F &
  Cancelable<F> &
  ParamBound4<
    Parameters<F>[0],
    Parameters<F>[1],
    Parameters<F>[2],
    Parameters<F>[3]
  >;

export function throttleParameterized4<
  T,
  A0,
  A1,
  A2,
  A3,
  a0,
  aa extends any[], // eslint-disable-line @typescript-eslint/no-explicit-any
  R
>(
  fn: FunctionBoundParam4<A0, A1, A2, A3, a0, aa, R>,
  wait: Parameters<typeof throttle>[1],
  options: Parameters<typeof throttle>[2],
  thisArg: T
): ThrottledFunctionBoundParam4<
  FunctionBoundParam4<A0, A1, A2, A3, a0, aa, R>
> {
  let currentFn:
    | (((arg0: a0, ...args: aa) => R) &
        Cancelable<(arg0: a0, ...args: aa) => R>)
    | undefined = undefined;

  const makeFn = memoizeOne(function (
    fn: FunctionBoundParam4<A0, A1, A2, A3, a0, aa, R>,
    boundarg0: A0,
    boundarg1: A1,
    boundarg2: A2,
    boundarg3: A3
  ) {
    return throttle(
      fn.bind<T, A0, A1, A2, A3, [a0], R>(
        thisArg,
        boundarg0,
        boundarg1,
        boundarg2,
        boundarg3
      ) as (arg0: a0, ...args: aa) => R,
      wait,
      options
    );
  });

  const resultFn: ThrottledFunctionBoundParam4<FunctionBoundParam4<
    A0,
    A1,
    A2,
    A3,
    a0,
    aa,
    R
  >> = Object.assign(
    (
      boundarg0: A0,
      boundarg1: A1,
      boundarg2: A2,
      boundarg3: A3,
      arg0: a0,
      ...args: aa
    ) => {
      const memoizedThrottledFn = makeFn(
        fn,
        boundarg0,
        boundarg1,
        boundarg2,
        boundarg3
      );
      if (memoizedThrottledFn !== currentFn) {
        currentFn?.flush();
        currentFn = memoizedThrottledFn;
        resultFn.cancel = () => {
          resultFn.boundParams = undefined;
          return memoizedThrottledFn.cancel();
        };
        resultFn.flush = () => {
          resultFn.boundParams = undefined;
          return memoizedThrottledFn.flush();
        };
        resultFn.boundParams = [boundarg0, boundarg1, boundarg2, boundarg3];
      }

      return memoizedThrottledFn(arg0, ...args);
    },
    {
      cancel: () => undefined, // Dummy implementation as an initial value
      flush: () => undefined, // Dummy implementation as an initial value
      boundParams: undefined,
    }
  );

  return resultFn;
}
