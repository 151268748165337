import { useEffect } from 'react';
import { firebaseFunctions } from '../firebase';

import { GrantGoogleApiRequest } from '../../../../interface/functions/google';

import { gapiClientId } from '../const';

const scope = 'https://www.googleapis.com/auth/calendar.events';
const grantGoogleApi = firebaseFunctions.httpsCallable('grantGoogleApi');

let auth2: gapi.auth2.GoogleAuth;

export const useGoogleAPI = (): void => {
  useEffect(() => {
    // eslint-disable-next-line no-undef
    if (window.gapi == null) {
      return;
    }

    // eslint-disable-next-line no-undef
    gapi.load('auth2', () => {
      // eslint-disable-next-line no-undef
      auth2 = gapi.auth2.init({
        client_id: gapiClientId,
      });
    });
  }, []);
};

export const authGoogleAPI = (): void => {
  auth2
    .grantOfflineAccess({
      scope,
    })
    .then(({ code }) => {
      const req: GrantGoogleApiRequest = { code };
      grantGoogleApi(req);
    });
};
