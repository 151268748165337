import firebase from 'firebase/app';
import { EventPayloads } from '@octokit/webhooks';
import { RestEndpointMethodTypes } from '@octokit/rest';

import { ProxiedData } from '../../../../interface/firestore/serviceDataProxy';

export type GithubIssue =
  | EventPayloads.WebhookPayloadIssuesIssue
  | RestEndpointMethodTypes['issues']['get']['response']['data'];

const getGithubIssueDocRef = (
  firestore: firebase.firestore.Firestore,
  tasquetUid: string,
  owner: string,
  repo: string,
  issueNumber: number
): firebase.firestore.DocumentReference<ProxiedData<GithubIssue>> => {
  return firestore
    .collection('users')
    .doc(tasquetUid)
    .collection('serviceDataProxies')
    .doc('github')
    .collection('owners')
    .doc(owner)
    .collection('repos')
    .doc(repo)
    .collection('issues')
    .doc(issueNumber.toString(10)) as firebase.firestore.DocumentReference<
    ProxiedData<GithubIssue>
  >;
};

export const requestUpdateGithubIssueProxy = (
  firestore: firebase.firestore.Firestore,
  tasquetUid: string,
  owner: string,
  repo: string,
  issueNumber: number
): void => {
  const docRef = getGithubIssueDocRef(
    firestore,
    tasquetUid,
    owner,
    repo,
    issueNumber
  );
  const updateData = {
    status: 'update-requested',
    error: 'ok',
  };
  docRef.update(updateData);
};

export const subscribeGithubIssueProxy = (
  firestore: firebase.firestore.Firestore,
  tasquetUid: string,
  owner: string,
  repo: string,
  issueNumber: number,
  onChange: (data: ProxiedData<GithubIssue>) => void
): (() => void) => {
  const docRef = getGithubIssueDocRef(
    firestore,
    tasquetUid,
    owner,
    repo,
    issueNumber
  );

  return docRef.onSnapshot((doc) => {
    const data = doc.data();
    if (data == null) {
      // No existent document. Create new one and request fetching.
      const newDoc: ProxiedData = {
        status: 'update-requested',
        error: 'ok',
      };
      docRef.set(newDoc);
      return;
    }

    onChange(data as ProxiedData<GithubIssue>);
  });
};
