import React, { useEffect } from 'react';
import { Global } from '@emotion/core';
import { useTheme } from 'sancho';

const GlobalStyle: React.FC = () => {
  const theme = useTheme();
  const backgroundColor = theme.colors.background.default;

  useEffect(() => {
    const themeMeta = document.querySelector('meta[name=theme-color]');
    if (themeMeta) {
      themeMeta.setAttribute('content', backgroundColor);
    } else {
      const newThemeMeta = document.createElement('meta');
      newThemeMeta.setAttribute('content', backgroundColor);
      document.head.appendChild(newThemeMeta);
    }
  }, [backgroundColor]);

  return (
    <Global
      styles={{
        'html, body, #root': {
          backgroundColor,
          margin: 0,
          width: '100%',
          height: '100%',
        },
      }}
    />
  );
};

export default React.memo(GlobalStyle);
