/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';
import { IconButton, IconRefreshCw } from 'sancho';

const refreshButtonCss = css({
  marginLeft: '0.2em',
  maxHeight: '1.2em',
  maxWidth: '1.2em',
});

interface RefreshButtonProps {
  onClick: () => void;
}
const RefreshButton: React.FC<RefreshButtonProps> = (props) => {
  return (
    <IconButton
      icon={<IconRefreshCw />}
      label="Refresh"
      size="xs"
      variant="ghost"
      onClick={props.onClick}
      css={refreshButtonCss}
    />
  );
};

export default React.memo(RefreshButton);
