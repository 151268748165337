import React, { useState, useCallback } from 'react';
import { useTheme, Toolbar } from 'sancho';
import useMedia from 'use-media';
import { useLocalStorage } from '@rehooks/local-storage';
import { useUser } from '../auth/hooks';
import ConnectedDirectoryList from './ConnectedDirectoryList';
import ConnectedTagList from './ConnectedTagList';
import ConnectedNoteAddButton from './ConnectedNoteAddButton';
import ConnectedNoteList from './ConnectedNoteList';
import ConnectedNoteApp from './ConnectedNoteApp';
import ConnectedCalendarApp from './ConnectedCalendarApp';
import {
  ColumnarAppFrame,
  Sidebar,
  SideBySide,
  Main,
} from '../components/layout';
import {
  SidebarMenuContainer,
  SidebarLanes,
  SidebarColumn,
} from './components/layout';
import DirectoryNamesButton from './components/DirectoryNamesButton';
import MainMenu from './MainMenu';
import FirestoreOfflinePersistenceControl from '../FirestoreOfflinePersistenceControl';
import { useDirectoryExistance } from './directory-existance';
import TagsSubscriber from './TagsSubscriber';

interface ConnectedAppProps {
  directoryPath: string;
  tagId: string | null;
  noteId: string | null;
  onNoteIdChange: (
    directoryPath: string,
    tagId: string | null,
    noteId: string | null
  ) => void;
  onNoteIdValidate: (
    directoryPath: string,
    tagId: string | null,
    noteId: string | null
  ) => void;
}

const ConnectedApp: React.FC<ConnectedAppProps> = (props) => {
  const {
    directoryPath,
    tagId,
    noteId,
    onNoteIdChange,
    onNoteIdValidate,
  } = props;

  const [page, setPage] = useState(1);

  const [dirListOpen, setDirListOpen] = useLocalStorage<boolean>(
    'dirListOpen',
    false
  );
  const [dirNames, setDirNames] = useState<(string | undefined)[]>([]);

  const user = useUser();

  const theme = useTheme();
  const isMedium = useMedia({ minWidth: theme.breakpoints.md });

  const dirExistanceCallback = useCallback(
    (existing) => {
      if (!existing) {
        if (directoryPath === '') {
          // directoryPath is already ''. Avoid an infinite loop.
          return;
        }
        onNoteIdValidate('', tagId, null);
      }
    },
    [onNoteIdValidate, directoryPath, tagId]
  );
  useDirectoryExistance(user?.uid, directoryPath, dirExistanceCallback);

  const unselectNote = useCallback(
    () => onNoteIdValidate(directoryPath, tagId, null),
    [onNoteIdValidate, directoryPath, tagId]
  );

  if (!user) return null;

  return (
    <TagsSubscriber uid={user.uid}>
      <ColumnarAppFrame>
        <Sidebar isOpenForMobile={noteId == null}>
          <SidebarMenuContainer
            menuBlock={
              <Toolbar>
                {/** TODO: FirestoreOfflinePersistenceControl should also be in the main menu */}
                <FirestoreOfflinePersistenceControl />
                <MainMenu />
              </Toolbar>
            }
          >
            <SidebarLanes page={page} onPageChange={setPage}>
              <SidebarColumn width="224px" hiddenForDesktop={!dirListOpen}>
                <ConnectedDirectoryList
                  uid={user.uid}
                  selectedDirectoryPath={directoryPath}
                  onDirectoryNamesFetched={setDirNames}
                  onDirectoryClick={() => setPage(1)}
                />
                <ConnectedTagList selectedTagId={tagId} />
              </SidebarColumn>
              <SidebarColumn width="264px">
                <DirectoryNamesButton
                  onPress={() => {
                    setPage(0);
                    setDirListOpen(!dirListOpen);
                  }}
                  directoryNames={dirNames}
                />
                <Toolbar>
                  <ConnectedNoteAddButton
                    uid={user.uid}
                    tagId={tagId}
                    directoryPath={directoryPath}
                    onNoteAdded={onNoteIdChange}
                  />
                </Toolbar>
                <ConnectedNoteList
                  uid={user.uid}
                  directoryPath={directoryPath}
                  tagId={tagId}
                  selectedNoteId={noteId}
                  enableNoteIdValidation={isMedium}
                  onNoteIdSelect={onNoteIdChange}
                  onNoteIdValidate={onNoteIdValidate}
                />
              </SidebarColumn>
            </SidebarLanes>
          </SidebarMenuContainer>
        </Sidebar>
        <Main>
          <SideBySide
            localStorageKey="main-window"
            primary={
              noteId ? (
                <ConnectedNoteApp
                  uid={user.uid}
                  directoryPath={directoryPath}
                  noteId={noteId}
                  onBackClick={unselectNote}
                  onNoteDelete={unselectNote}
                />
              ) : null
            }
            secondary={<ConnectedCalendarApp uid={user.uid} />}
          />
        </Main>
      </ColumnarAppFrame>
    </TagsSubscriber>
  );
};

export default React.memo(ConnectedApp);
