import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useFirestore } from '../firebase/hooks';
import NoteList from './components/NoteList';
import { Note, FirestoreRepository } from './repositories';

const notesIncludes = (notes: [string, Note][], searchId: string): boolean => {
  return notes.findIndex(([id]) => id === searchId) > -1;
};

interface ConnectedNoteListProps {
  uid: string;
  directoryPath: string;
  tagId: string | null;
  selectedNoteId: string | null;

  /**
   * If true, note id validation is enabled, which calls onNoteIdValidate
   * in case noteId is null while at least one noteId is available.
   */
  enableNoteIdValidation: boolean;

  onNoteIdSelect: (
    directoryPath: string,
    tagId: string | null,
    noteId: string | null
  ) => void;
  onNoteIdValidate: (
    directoryPath: string,
    tagId: string | null,
    noteId: string | null
  ) => void;
}

const ConnectedNoteList: React.FC<ConnectedNoteListProps> = ({
  uid,
  directoryPath,
  tagId,
  selectedNoteId,
  enableNoteIdValidation,
  onNoteIdSelect,
  onNoteIdValidate,
}) => {
  const [initialLoaded, setInitialLoaded] = useState(false);
  const [notes, setNotes] = useState<[string, Note][]>([]);
  const firestore = useFirestore();
  const repository = useMemo(() => {
    return firestore ? new FirestoreRepository(firestore) : null;
  }, [firestore]);

  useEffect(() => {
    if (!repository) {
      return;
    }

    const unsubscribe = repository.listenNotes(
      uid,
      directoryPath,
      tagId,
      (idsAndNotes) => {
        setNotes(idsAndNotes);
        setInitialLoaded(true);
      }
    );

    return unsubscribe;
  }, [repository, uid, directoryPath, tagId]);

  useEffect(() => {
    if (!enableNoteIdValidation) {
      return;
    }

    if (!initialLoaded) {
      // Do nothing before the initial data is loaded to avoid calling onNoteIdSelected
      // with noteId=null just after opening the page.
      return;
    }

    if (notes.length === 0) {
      if (selectedNoteId != null) {
        onNoteIdValidate(directoryPath, tagId, null);
      }
    } else if (
      selectedNoteId == null ||
      !notesIncludes(notes, selectedNoteId)
    ) {
      const newNoteId = notes[0][0];
      if (selectedNoteId !== newNoteId) {
        onNoteIdValidate(directoryPath, tagId, newNoteId);
      }
    }
  }, [
    enableNoteIdValidation,
    initialLoaded,
    directoryPath,
    tagId,
    notes,
    selectedNoteId,
    onNoteIdValidate,
  ]);

  return (
    <NoteList
      onSelect={useCallback(
        (noteId: string) => onNoteIdSelect(directoryPath, tagId, noteId),
        [onNoteIdSelect, directoryPath, tagId]
      )}
      notes={notes}
      selectedNoteId={selectedNoteId}
    />
  );
};

export default ConnectedNoteList;
