/** @jsx jsx */
import React, { useCallback, useRef, useImperativeHandle } from 'react';
import { jsx } from '@emotion/core';
import { useTheme } from 'sancho';
import FullCalendar from '@fullcalendar/react';
import { EventApi } from '@fullcalendar/common';
import CustomizedFullCalendar from './CustomizedFullCalendar';

type EventDropFn = NonNullable<
  React.ComponentProps<typeof FullCalendar>['eventDrop']
>;
type EventResizeFn = NonNullable<
  React.ComponentProps<typeof FullCalendar>['eventResize']
>;
type EventDidMountFn = NonNullable<
  React.ComponentProps<typeof FullCalendar>['eventDidMount']
>;

export interface CalendarRef {
  gotoDate: (zonedDateInput: any) => void;
  reveal: () => void;
}
export interface CalendarProps {
  events: React.ComponentProps<typeof FullCalendar>['events'];
  onEventChange: (event: EventApi) => void;
}
const Calendar = React.forwardRef<CalendarRef, CalendarProps>((props, ref) => {
  const theme = useTheme();
  const calendarRef = useRef<FullCalendar>(null);
  const focusedEventDOMRef = useRef<HTMLElement>(null);

  useImperativeHandle(ref, () => ({
    gotoDate(zonedDateInput) {
      const api = calendarRef.current?.getApi();
      api?.gotoDate(zonedDateInput);
    },
    reveal() {
      focusedEventDOMRef.current?.scrollIntoView();
    },
  }));

  const onEventChange = props.onEventChange;

  return (
    <div
      css={{
        color: theme.colors.text.default,
        height: '100%',
        width: '100%',
        maxWidth: '100%',
        flexGrow: 1,
        position: 'relative',
        '& td.fc-today': {
          backgroundColor: theme.colors.background.tint1,
        },
        '& .focused': {
          border: `2px solid ${theme.colors.palette.blue.lightest}`,
        },
      }}
    >
      <CustomizedFullCalendar
        ref={calendarRef}
        height="100%"
        events={props.events}
        eventDrop={useCallback<EventDropFn>(
          (e) => {
            onEventChange(e.event);
          },
          [onEventChange]
        )}
        eventResize={useCallback<EventResizeFn>(
          (e) => {
            onEventChange(e.event);
          },
          [onEventChange]
        )}
        eventDidMount={useCallback<EventDidMountFn>((arg) => {
          if (arg.event.extendedProps.focused) {
            (focusedEventDOMRef as React.MutableRefObject<
              HTMLElement
            >).current = arg.el;
          }
        }, [])}
      />
    </div>
  );
});

export default React.memo(Calendar);
