/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { useTheme } from 'sancho';

interface CalendarFooterProps {
  children: React.ReactNode;
}
const CalendarFooter: React.FC<CalendarFooterProps> = (props) => {
  const theme = useTheme();

  return (
    <div
      css={{
        width: '100%',
        boxSizing: 'border-box',
        padding: `${theme.spaces.sm} ${theme.spaces.md}`,
        display: 'flex',
        justifyContent: 'flex-end',
      }}
    >
      {props.children}
    </div>
  );
};

export default React.memo(CalendarFooter);
