/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import {
  useTheme,
  IconButton,
  IconRotateCcw,
  IconRotateCw,
  IconChevronsLeft,
  IconChevronsRight,
} from 'sancho';

const Divider: React.FC = () => {
  const theme = useTheme();

  return (
    <div
      css={{
        backgroundColor: theme.colors.border.default,
        height: '80%',
        width: '2px',
        borderRadius: '1px',
      }}
    />
  );
};

interface ToolbarProps {
  onUndo: () => void;
  onRedo: () => void;
  onIndent: () => void;
  onOutdent: () => void;
}
const Toolbar: React.FC<ToolbarProps> = (props) => {
  const theme = useTheme();

  return (
    <React.Fragment>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          padding: theme.spaces.xs,
          alignItems: 'center',
          [theme.mediaQueries.md]: {
            display: 'none',
          },
        }}
      >
        <IconButton
          size="md"
          variant="ghost"
          intent="none"
          icon={<IconRotateCcw />}
          label="Undo"
          onPress={(e) => {
            e?.preventDefault();
            props.onUndo();
          }}
        />
        <IconButton
          size="md"
          variant="ghost"
          intent="none"
          icon={<IconRotateCw />}
          label="Redo"
          onPress={(e) => {
            e?.preventDefault();
            props.onRedo();
          }}
        />
        <Divider />
        <IconButton
          size="md"
          variant="ghost"
          intent="none"
          icon={<IconChevronsLeft />}
          label="Outdent"
          onPress={(e) => {
            e?.preventDefault();
            props.onOutdent();
          }}
        />
        <IconButton
          size="md"
          variant="ghost"
          intent="none"
          icon={<IconChevronsRight />}
          label="Indent"
          onPress={(e) => {
            e?.preventDefault();
            props.onIndent();
          }}
        />
      </div>

      {/** For Desktop, toolbar is hidden */}
      <div
        css={{
          display: 'none',
          height: theme.spaces.md,
          [theme.mediaQueries.md]: {
            display: 'block',
          },
        }}
      />
    </React.Fragment>
  );
};

export default React.memo(Toolbar);
