/** @jsx jsx */
import React from 'react';
import { useTheme } from 'sancho';
import { jsx } from '@emotion/core';

/* eslint-disable quotes */
// Same to index.css
const resetFontFamily =
  "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif";
/* eslint-enable */

const Gutter: React.FC = (props) => {
  const theme = useTheme();

  return (
    <div
      contentEditable={false}
      css={{
        fontFamily: resetFontFamily,
        minWidth: '120px',
        width: '120px',
        userSelect: 'none',
        color: theme.colors.text.default,
        backgroundColor: theme.colors.background.tint1,
        lineHeight: 'unset', // Remove line-height, which adds an extra mergin.
        display: 'none',
        [theme.mediaQueries.md]: {
          display: 'block',
        },
      }}
    >
      {props.children}
    </div>
  );
};

export default Gutter;
