import { useCallback } from 'react';
import firebase from 'firebase/app';
import { useToast } from 'sancho';
import { useUser } from '../auth/hooks';
import { useFirestore } from '../firebase/hooks';
import { FirestoreRepository, NoteCreate } from '../ConnectedApp/repositories';
import { generateTaskId } from '../ConnectedApp/ConnectedNoteApp/markdown-listeners';
import { makeGettingStartedMd } from './getting_started.md';

// XXX: Here is VERY rough implementation including much problems - For example, no mechanism to ensure the info synced between the tasks and the note...
export const insertTutorialNotes = async (
  firestore: firebase.firestore.Firestore,
  uid: string
): Promise<void> => {
  const repository = new FirestoreRepository(firestore);

  const today = new Date();
  today.setHours(0);
  today.setMinutes(0);
  today.setSeconds(0);
  today.setMilliseconds(0);

  const task1Start = new Date(today);
  task1Start.setHours(10);
  const task1End = new Date(today);
  task1End.setHours(12);
  const task1 = {
    title: 'Work out',
    start: task1Start,
    end: task1End,
  };
  const task1Id = generateTaskId();
  await repository.setTask(uid, task1Id, task1);

  const task2Start = new Date(today);
  task2Start.setHours(16);
  const task2End = new Date(today);
  task2End.setHours(17);
  const task2 = {
    title: 'Talk with my friend John',
    start: task2Start,
    end: task2End,
  };
  const task2Id = generateTaskId();
  await repository.setTask(uid, task2Id, task2);

  const newNote: NoteCreate = {
    title: 'Getting started with Tasquet',
    tagIds: [],
    plainText: makeGettingStartedMd(
      today.getFullYear(),
      today.getMonth() + 1,
      today.getDate()
    ),
    taskElementIndexMap: {
      [task1Id]: 12,
      [task2Id]: 13,
    },
  };
  await repository.createNote(uid, '', newNote);
};

export const useInsertTutorialNotes = (): (() => Promise<void>) => {
  const toast = useToast();

  const user = useUser();
  const uid = user?.uid;

  const firestore = useFirestore();

  return useCallback(async () => {
    if (!uid) {
      return;
    }
    if (!firestore) {
      return;
    }

    try {
      await insertTutorialNotes(firestore, uid);
    } catch {
      toast({
        intent: 'danger',
        title: 'Error occurred',
        subtitle: 'Failed to insert tutorial notes',
      });
    }
  }, [toast, firestore, uid]);
};
