import React, { useMemo } from 'react';
import {
  Route,
  Redirect,
  Switch,
  RouteProps,
  useRouteMatch,
  useParams,
  useLocation,
  useHistory,
} from 'react-router-dom';
import { History } from 'history';
import PrivateRoute from '../auth/PrivateRoute';
import ConnectedApp from '../ConnectedApp';
import OauthRoute from '../OauthRoute';

export const paths = {
  note(
    directoryPath: string,
    tagId: string | null,
    noteId: string | null
  ): string {
    const directoryURLSegments = [
      'notes',
      ...directoryPath.split('/').filter((d) => d),
      'note',
    ];
    const directoryURL = '/' + directoryURLSegments.join('/');

    const searchParams = new URLSearchParams();
    if (tagId) {
      searchParams.append('tagId', tagId);
    }

    if (noteId == null) {
      return directoryURL + '?' + searchParams.toString();
    }

    return directoryURL + '/' + noteId + '?' + searchParams.toString();
  },
};

export const handleNoteIdChange = (
  history: History,
  directoryPath: string,
  tagId: string | null,
  noteId: string | null
): void => {
  history.push(paths.note(directoryPath, tagId, noteId));
};
export const handleNoteIdValidate = (
  history: History,
  directoryPath: string,
  tagId: string | null,
  noteId: string | null
): void => {
  history.replace(paths.note(directoryPath, tagId, noteId));
};

interface NoteRouteProps {
  ConnectedAppComponent: typeof ConnectedApp;
}
const NoteRoute: React.FC<NoteRouteProps> = ({ ConnectedAppComponent }) => {
  const history = useHistory();

  const { directoryPath = '', noteId } = useParams();

  const queryString = useLocation().search;
  const query = new URLSearchParams(queryString);

  const handleNoteIdChangeBoundHistory = useMemo(
    () => handleNoteIdChange.bind(null, history),
    [history]
  );
  const handleNoteIdValidateBoundHistory = useMemo(
    () => handleNoteIdValidate.bind(null, history),
    [history]
  );

  return (
    <ConnectedAppComponent
      directoryPath={directoryPath}
      tagId={query.get('tagId')}
      noteId={noteId || null}
      onNoteIdChange={handleNoteIdChangeBoundHistory}
      onNoteIdValidate={handleNoteIdValidateBoundHistory}
    />
  );
};

interface NoteDirectoryRouteProps {
  ConnectedAppComponent: typeof ConnectedApp;
}
const NoteDirectoryRoute: React.FC<NoteDirectoryRouteProps> = ({
  ConnectedAppComponent,
}) => {
  const { url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${url}/:directoryPath*/note/:noteId?`}>
        <NoteRoute ConnectedAppComponent={ConnectedAppComponent} />
      </Route>
      <Route path={`${url}/:directoryPath*`}>
        {(props) => {
          const directoryPath = props.match?.params.directoryPath;
          const directoryPathInURL = directoryPath ? directoryPath + '/' : '';
          return <Redirect to={`${url}/${directoryPathInURL}note`} />;
        }}
      </Route>
    </Switch>
  );
};

interface RoutesProps {
  Login: RouteProps['component'];
  Signup: RouteProps['component'];
  PasswordReset: RouteProps['component'];
  ConnectedApp: typeof ConnectedApp;
}
const Routes: React.FC<RoutesProps> = (props) => {
  return (
    <Switch>
      <Route exact path="/login" component={props.Login} />
      <Route exact path="/signup" component={props.Signup} />
      <Route exact path="/reset-password" component={props.PasswordReset} />
      <PrivateRoute path="/notes">
        <NoteDirectoryRoute ConnectedAppComponent={props.ConnectedApp} />
      </PrivateRoute>
      <PrivateRoute path="/oauth">
        <OauthRoute />
      </PrivateRoute>
      <PrivateRoute render={() => <Redirect to="/notes" />} />
    </Switch>
  );
};

export default Routes;
