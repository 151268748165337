/*global process*/

export let hostingURL: string;
// To obtain client_id, see https://developers.google.com/identity/sign-in/web/server-side-flow
export let gapiClientId: string;
// The app installation URL is https://github.com/apps/<app name>/installations/new
export let githubAppInstallationURL: string;
if (process.env.NODE_ENV === 'development') {
  hostingURL = 'https://staging4932.tasquet.app';
  gapiClientId =
    '77197513678-fog6p5316q57ap0ngoc2kiu94ocrmof8.apps.googleusercontent.com';
  githubAppInstallationURL =
    'https://github.com/apps/tasquet-staging/installations/new';
} else if (process.env.NODE_ENV === 'production') {
  if (process.env.REACT_APP_FIREBASE_CONFIG === 'production') {
    hostingURL = 'https://tasquet.app';
    gapiClientId =
      '391229489889-a93m5adbpjjrieo8v6dsnf8eatu52n4j.apps.googleusercontent.com';
    githubAppInstallationURL =
      'https://github.com/apps/tasquet/installations/new';
  } else {
    hostingURL = 'https://staging4932.tasquet.app';
    gapiClientId =
      '77197513678-fog6p5316q57ap0ngoc2kiu94ocrmof8.apps.googleusercontent.com';
    githubAppInstallationURL =
      'https://github.com/apps/tasquet-staging/installations/new';
  }
} else if (process.env.NODE_ENV === 'test') {
  gapiClientId = '';
}

export const googleCalendarIdPrefix = 'tasquetv3e58fbdf';
