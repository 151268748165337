import React from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { useUser } from './hooks';

const PrivateRoute: React.FC<RouteProps> = (props) => {
  const user = useUser();

  const { children, render, ...restProps } = props;
  return (
    <Route
      {...restProps}
      render={(renderProps) => {
        if (!user) {
          return (
            <Redirect
              to={{ pathname: '/login', state: { from: renderProps.location } }}
            />
          );
        }

        if (render) {
          return render(renderProps);
        } else {
          return children;
        }
      }}
    />
  );
};

export default PrivateRoute;
