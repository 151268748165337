/** @jsx jsx */
import React, { useState, useCallback } from 'react';
import { jsx } from '@emotion/core';
import { useTheme, Spinner, IconCornerDownLeft } from 'sancho';

interface TagListItemFormProps {
  onSubmit: (name: string) => Promise<unknown>;
}
const TagListItemForm: React.FC<TagListItemFormProps> = ({ onSubmit }) => {
  const theme = useTheme();

  const [name, setName] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const submittable = name.trim() !== '';

  return (
    <li>
      <form
        onSubmit={useCallback(
          (e) => {
            e.preventDefault();

            setSubmitting(true);
            setName('');
            onSubmit(name.trim())
              .catch(() => {
                setName(name);
              })
              .finally(() => {
                setSubmitting(false);
              });
          },
          [name, onSubmit]
        )}
      >
        <div
          css={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            margin: 0,
            padding: 0,
            border: `1px solid ${theme.colors.border.default}`,
            boxSizing: 'border-box',
          }}
        >
          <input
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={submitting}
            placeholder="New tag"
            css={{
              color: theme.colors.text.default,
              margin: theme.spaces.xs,
              flexGrow: 1,
              border: 'none',
              padding: 0,
              borderRadius: 0,
              outline: 'none',
              background: 'none',
              width: '100%',
            }}
          />
          <Spinner
            css={{
              opacity: 0.5,
              display: submitting ? 'block' : 'none',
            }}
          />
          <button
            type="submit"
            css={{
              display: submittable && !submitting ? 'block' : 'none',
              backgroundColor: theme.colors.intent.success.base,
              border: 'none',
              cursor: 'pointer',
              outline: 'none',
              appearance: 'none',
              padding: theme.spaces.xs,
            }}
          >
            <IconCornerDownLeft size="xs" css={{ stroke: 'white' }} />
          </button>
        </div>
      </form>
    </li>
  );
};

export default React.memo(TagListItemForm);
