import styled from '@emotion/styled/macro';

export const NoteAppContainer = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const NoteAppHeader = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 10px;
`;
