import { v4 as uuidv4 } from 'uuid';
import { Editor } from 'slate';
import {
  MarkdownEventListeners,
  MarkdownEnhancedEditor,
} from '@tasquet/slate-markdown-plugin';
import {
  parseTask,
  fillTaskDateTimes,
  asDateTask,
  DateTask,
  parseContextDateTime,
} from './logics/task-parser';

import { googleCalendarIdPrefix } from '../../const';

export const generateTaskId = (): string => {
  // This ID must be compatible with the restriction for Google Calendar API's event ID.
  // - characters allowed in the ID are those used in base32hex encoding, i.e. lowercase letters a-v and digits 0-9, see section 3.1.2 in RFC2938
  // - the length of the ID must be between 5 and 1024 characters
  // - the ID must be unique per calendar  Due to the globally distributed nature of the system, we cannot guarantee that ID collisions will be detected at event creation time. To minimize the risk of collisions we recommend using an established UUID algorithm such as one described in RFC4122. If you do not specify an ID, it will be automatically generated by the server. Note that the icalUID and the id are not identical and only one of them should be supplied at event creation time. One difference in their semantics is that in recurring events, all occurrences of one event have different ids while they all share the same icalUIDs.
  const uuid = uuidv4().split('-').join(''); // split + join = replaceAll

  return `${googleCalendarIdPrefix}${uuid}`;
};

interface MakeMarkdownEventListenersOptions {
  onTaskCreate: (taskId: string, task: DateTask) => void;
  onTaskUpdate: (taskId: string, task: DateTask) => void;
  onTaskDelete: (taskId: string) => void;
}
const makeMarkdownEventListeners = (
  editor: Editor,
  options: MakeMarkdownEventListenersOptions
): MarkdownEventListeners => ({
  onBlockCreate(newText, newMdastContent, newTopLevelIndex) {
    if (newMdastContent.type === 'paragraph') {
      const nullableDateTimeTask = parseTask(newText);
      if (nullableDateTimeTask == null) {
        return;
      }

      const contextDateTime = parseContextDateTime(
        editor as MarkdownEnhancedEditor,
        newTopLevelIndex
      );

      const task = fillTaskDateTimes(nullableDateTimeTask, contextDateTime);

      const taskId = generateTaskId();

      options.onTaskCreate(taskId, asDateTask(task));

      return { taskId, task };
    }
  },
  onBlockUpdate(
    newText,
    newMdastContent,
    newTopLevelIndex,
    oldMdastContent,
    oldElement
  ) {
    const mdastContent = newMdastContent;
    if (mdastContent.type === 'paragraph') {
      const currentTaskId = oldElement.taskId as
        | ReturnType<typeof generateTaskId>
        | undefined;

      const nullableDateTimeTask = parseTask(newText);
      if (nullableDateTimeTask == null) {
        if (currentTaskId) {
          options.onTaskDelete(currentTaskId);
        }
        return;
      }

      const contextDateTime = parseContextDateTime(
        editor as MarkdownEnhancedEditor,
        newTopLevelIndex
      );

      const task = fillTaskDateTimes(nullableDateTimeTask, contextDateTime);

      if (currentTaskId) {
        options.onTaskUpdate(currentTaskId, asDateTask(task));
        return { taskId: currentTaskId, task };
      } else {
        const taskId = generateTaskId();
        options.onTaskCreate(taskId, asDateTask(task));
        return { taskId, task };
      }
    }
  },
  onBlockDelete(oldMdastContent, oldElement): void {
    const mdastContent = oldMdastContent;
    if (mdastContent.type === 'paragraph') {
      const currentTaskId = oldElement.taskId as
        | ReturnType<typeof generateTaskId>
        | undefined;

      if (currentTaskId) {
        options.onTaskDelete(currentTaskId);
      }
    }
  },
});

export default makeMarkdownEventListeners;
