/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';

const badgeCss = css({
  marginLeft: '0.5em',
});

const BadgeText: React.FC = (props) => {
  return <span css={badgeCss}>{props.children}</span>;
};

export default BadgeText;
