/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { useTheme, IconButton, ButtonProps, IconChevronLeft } from 'sancho';

const BackButton: React.FC<Omit<ButtonProps, 'children'>> = (props) => {
  const theme = useTheme();

  return (
    <IconButton
      {...props}
      icon={<IconChevronLeft />}
      label="Back"
      variant="ghost"
      css={{
        marginRight: theme.spaces.xs,
        [theme.mediaQueries.md]: {
          display: 'none',
        },
      }}
    />
  );
};

export default React.memo(BackButton);
