import React from 'react';
import { HistoryEditor } from 'slate-history';
import {
  TasquetMarkdownEditor,
  MarkdownEnhancedEditor,
} from '@tasquet/slate-markdown-plugin';
import ToolbarComponent from './components/Toolbar';

interface ToolbarProps {
  editor: MarkdownEnhancedEditor<HistoryEditor>;
}
const Toolbar: React.FC<ToolbarProps> = (props) => {
  return (
    <ToolbarComponent
      onUndo={() => HistoryEditor.undo(props.editor)}
      onRedo={() => HistoryEditor.redo(props.editor)}
      onIndent={() => TasquetMarkdownEditor.indent(props.editor)}
      onOutdent={() => TasquetMarkdownEditor.indent(props.editor, true)}
    />
  );
};

export default React.memo(Toolbar);
