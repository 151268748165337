/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';

const badgeContainerCss = css({
  display: 'inline-flex',
  alignItems: 'center',
});

interface BadgeContainerProps {
  children: React.ReactNode;
}
const BadgeContainer: React.FC<BadgeContainerProps> = (props) => {
  return <span css={badgeContainerCss}>{props.children}</span>;
};

export default React.memo(BadgeContainer);
