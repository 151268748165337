import React, { useState, useContext, useCallback, useEffect } from 'react';

export type DirectoryIdNameMap = { [id: string]: string };
type DirectoryIdNameMapAddFn = (data: DirectoryIdNameMap) => void;
const directoryIdNameMapContext = React.createContext<DirectoryIdNameMapAddFn>(
  () => null
);

/**
 * This hook returns `addDirectoryIdNameMap` function,
 * which is used to add a new directory id-name mapping
 * from child components of DirectoryNamesMapper.
 */
export const useAddDirectoryIdNameMap = (): DirectoryIdNameMapAddFn =>
  useContext(directoryIdNameMapContext);

interface DirectoryNamesProviderProps {
  /** '/'-separated directory path  */
  selectedDirectoryPath: string;
  /** Callback to get the updated `dirNames`, which is an array of directory names */
  onDirectoryNamesFetched: (dirNames: (string | undefined)[]) => void;
}

/**
 * This component stores the mapping from directory ID to directory name
 * and composes an array of directory names from the given `currentDirectoryPath` and the mapping.
 * `currentDirectoryPath` is
 */
const DirectoryNamesMapper: React.FC<DirectoryNamesProviderProps> = ({
  selectedDirectoryPath,
  onDirectoryNamesFetched,
  children,
}) => {
  const [directoryIdNameMap, setDirectoryIdNameMap] = useState<
    DirectoryIdNameMap
  >({});
  const addDirectoryIdNameMap = useCallback((addData: DirectoryIdNameMap) => {
    setDirectoryIdNameMap((cur) => ({ ...cur, ...addData }));
  }, []);
  useEffect(() => {
    const dirNames = selectedDirectoryPath
      .split('/')
      .filter((d) => d)
      .map((segment) => directoryIdNameMap[segment]);
    onDirectoryNamesFetched(dirNames);
  }, [directoryIdNameMap, selectedDirectoryPath, onDirectoryNamesFetched]);

  return (
    <directoryIdNameMapContext.Provider value={addDirectoryIdNameMap}>
      {children}
    </directoryIdNameMapContext.Provider>
  );
};

export default DirectoryNamesMapper;
