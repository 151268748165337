import { useEffect } from 'react';
import { useFirestore } from '../firebase/hooks';
import { directoryPathToDocPath } from './directory';

export const useDirectoryExistance = (
  uid: string | undefined,
  selectedDirectoryPath: string,
  callback: (existing: boolean) => void
): void => {
  const firestore = useFirestore();

  useEffect(() => {
    if (!uid) {
      return;
    }

    if (!firestore) {
      return;
    }

    if (selectedDirectoryPath.split('/').filter((d) => d).length === 0) {
      // The root directory always exists.
      callback(true);
      return;
    }

    const directoryDocPath = directoryPathToDocPath(selectedDirectoryPath);

    const directoryDocRef = firestore.doc(`users/${uid}${directoryDocPath}`);

    directoryDocRef.get().then((doc) => {
      callback(doc.exists);
    });
  }, [firestore, uid, selectedDirectoryPath, callback]);
};
