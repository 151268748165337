const FIRESTORE_OFFLINE_PERSISTENCE_KEY = 'tasquetFirestoreOfflinePersistence';
const firestoreOfflinePersistenceKey = (uid: string): string =>
  `${FIRESTORE_OFFLINE_PERSISTENCE_KEY}_${uid}`;
export const isOfflinePersistenceRequested = (uid: string): boolean => {
  return (
    localStorage.getItem(firestoreOfflinePersistenceKey(uid)) === 'enabled'
  );
};
export const setOfflinePersistenceRequest = (
  uid: string,
  enabled: boolean
): void => {
  localStorage.setItem(
    firestoreOfflinePersistenceKey(uid),
    enabled ? 'enabled' : 'disabled'
  );
};
