/** @jsx jsx */
import React, { useState, useCallback } from 'react';
import { jsx } from '@emotion/core';
import { useTheme, Text, IconX, Spinner } from 'sancho';
import { Link, LinkProps } from 'react-router-dom';
import { Tag } from '../TagsSubscriber';

interface TagListItemProps {
  tag: Tag;
  displayName?: string;
  selected?: boolean;
  to: LinkProps['to'];
  onDelete: (tagId: string) => Promise<unknown>;
  children?: React.ReactNode;
}
const TagListItem: React.FC<TagListItemProps> = ({
  tag,
  displayName,
  selected,
  to,
  onDelete,
  children,
}) => {
  const theme = useTheme();

  const [deleting, setDeleting] = useState(false);

  return (
    <li>
      <div
        css={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          backgroundColor: selected ? theme.colors.background.tint1 : undefined,
          '&:hover': {
            backgroundColor: theme.colors.background.tint1,
          },
          '& .DeleteButton': {
            display: 'none',
          },
          '&:hover .DeleteButton': {
            display: 'inherit',
          },
          pointerEvents: deleting ? 'none' : 'initial',
          opacity: deleting ? 0.5 : undefined,
        }}
      >
        <Link
          to={to}
          css={{
            display: 'block',
            flexGrow: 2,
            minWidth: 0,
            textDecoration: 'none',
          }}
        >
          <Text
            wrap={false}
            css={{
              padding: theme.spaces.xs,
              display: 'block',
              width: '100%',
            }}
          >
            {displayName || tag.name}
          </Text>
        </Link>
        <Spinner css={{ display: deleting ? 'initial' : 'none' }} />
        <button
          className="DeleteButton"
          css={{
            display: deleting ? 'none' : 'initial',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            outline: 'none',
            appearance: 'none',
            padding: theme.spaces.xs,
            borderRadius: '50%',
            '&:hover': {
              backgroundColor: 'rgba(180, 180, 180, 0.4)',
            },
          }}
          onClick={useCallback(() => {
            setDeleting(true);
            onDelete(tag.id).finally(() => {
              setDeleting(false);
            });
          }, [tag, onDelete])}
        >
          <IconX size="sm" />
        </button>
      </div>
      {children}
    </li>
  );
};

export default React.memo(TagListItem);
