import React, { useState } from 'react';
import { Toolbar, InputGroup, Input, Button, Text, Container } from 'sancho';
import { Link, RouteComponentProps } from 'react-router-dom';
import { StaticContext } from 'react-router';
import { firebaseAuth } from '../firebase';
import { Main, Centering } from '../components/layout';
import { Logo } from '../components/logo';
import { AuthFormContainer, AuthForm } from '../components/auth';

interface PasswordResetLocationState {
  email?: string;
}
const PasswordReset: React.FC<RouteComponentProps<
  Record<string, string>,
  StaticContext,
  PasswordResetLocationState
>> = (props) => {
  const locationState = props.location.state || {};
  const { email: defaultEmail = '' } = locationState;

  const [email, setEmail] = useState<string>(defaultEmail);
  const [loading, setLoading] = useState(false);
  const [sent, setSent] = useState(false);
  const [error, setError] = useState<{
    code: string;
    message: string;
  } | null>();

  const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
    e.preventDefault();

    setLoading(true);
    firebaseAuth
      .sendPasswordResetEmail(email)
      .then(() => {
        setLoading(false);
        setSent(true);
      })
      .catch((error) => {
        setLoading(false);
        setError(error);
      });
  };

  return (
    <Main>
      <Centering>
        <Link to="/">
          <Logo />
        </Link>
        <AuthFormContainer title="Reset your password">
          <Toolbar>
            <Text>
              Enter your email address and we will send you a link to reset your
              password.
            </Text>
          </Toolbar>
          <AuthForm onSubmit={onSubmit}>
            <InputGroup label="Email address" error={error && error.message}>
              <Input
                name="email"
                type="email"
                required
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </InputGroup>

            <InputGroup label="Submit" hideLabel>
              <Button
                component="button"
                type="submit"
                block
                intent="primary"
                loading={loading}
              >
                {sent
                  ? 'Resend password reset email'
                  : 'Send password reset email'}
              </Button>
            </InputGroup>
          </AuthForm>

          {sent && (
            <>
              <Toolbar>
                <Container>
                  <Text variant="lead">We sent the email!</Text>
                  <Text>
                    Check your email for a link to reset your password. If it
                    doesn’t appear within a few minutes, check your spam folder.
                  </Text>
                </Container>
              </Toolbar>
              <Toolbar>
                <Button
                  block
                  component={Link}
                  to={{ pathname: '/login', state: { email } }}
                >
                  Return to log in
                </Button>
              </Toolbar>
            </>
          )}
        </AuthFormContainer>
      </Centering>
    </Main>
  );
};

export default PasswordReset;
