import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from 'react';
import { VoidRepository, FirestoreRepository } from '../../repositories';
import { EventInput } from '@fullcalendar/common/';
import Calendar, { CalendarRef } from './components/Calendar';
import { useFirestore } from '../../../firebase/hooks';
// TODO: Move logics to the common parent of ConnectedNoteApp and ConnectedCalendar
import { DateTask } from '../../ConnectedNoteApp/logics/task-parser';

type TaskMap = { [taskId: string]: DateTask };

interface ConnectedCalendarProps {
  uid: string;
  focusedEventId: string | undefined | null;
}
const ConnectedCalendar: React.FC<ConnectedCalendarProps> = (props) => {
  const uid = props.uid;
  const focusedEventId = props.focusedEventId;

  const calendarRef = useRef<CalendarRef>(null);

  const [taskMap, setTaskMap] = useState<TaskMap>({});
  const [events, focusedEvent] = useMemo((): [
    EventInput[],
    EventInput | undefined
  ] => {
    let focusedEvent: EventInput | undefined = undefined;
    const events = Object.entries(taskMap).map(([taskId, task]) => {
      const focused = taskId === focusedEventId;
      const event = {
        id: taskId,
        title: task.title,
        start: task.start || undefined,
        end: task.end || undefined,
        focused,
      };
      if (focused) {
        // This makes side effect inside .map()... It's not ideal.
        focusedEvent = event;
      }
      return event;
    });
    return [events, focusedEvent];
  }, [taskMap, focusedEventId]);

  useEffect(() => {
    if (focusedEvent && calendarRef.current) {
      calendarRef.current.gotoDate(focusedEvent.start);
      setTimeout(() => {
        calendarRef.current && calendarRef.current.reveal();
      });
    }
  }, [focusedEvent]);

  const firestore = useFirestore();
  const repository = useMemo(() => {
    const repository = firestore
      ? new FirestoreRepository(firestore)
      : new VoidRepository();
    return repository;
  }, [firestore]);

  useEffect(() => {
    return repository.listenTaskChanges(uid, (changes) => {
      setTaskMap((currentTaskMap) => {
        const newTaskMap = Object.assign({}, currentTaskMap);
        changes.forEach((change) => {
          const taskId = change.id;

          if (change.type === 'removed') {
            if (taskId in newTaskMap) {
              delete newTaskMap[change.id];
            }
            return;
          }

          newTaskMap[taskId] = change.task;
        });
        return newTaskMap;
      });
    });
  }, [repository, uid]);

  return (
    <Calendar
      ref={calendarRef}
      events={events}
      onEventChange={useCallback(
        (event) => {
          const dateTask: DateTask = {
            title: event.title,
            start: event.start,
            end: event.end,
          };
          repository.setTask(props.uid, event.id, dateTask);
        },
        [repository, props.uid]
      )}
    />
  );
};

export default React.memo(ConnectedCalendar);
