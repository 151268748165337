/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';
import { Skeleton } from 'sancho';
import {
  renderers,
  makeTopLevelElement,
  makeLineElement,
} from '@tasquet/slate-markdown-plugin';

const empty = makeTopLevelElement('empty', [
  makeLineElement(''),
  makeLineElement(''),
  makeLineElement(''),
]);

const skeletonLineContainerStyle = css({
  paddingLeft: 10,
});

const SkeletonLine: React.FC = () => {
  return (
    <renderers.Line css={skeletonLineContainerStyle}>
      <Skeleton animated />
    </renderers.Line>
  );
};

const SkeletonEditable: React.FC = () => {
  return (
    <div css={renderers.editableStyle}>
      <renderers.TopLevelComponent element={empty}>
        <SkeletonLine />
        <SkeletonLine />
        <SkeletonLine />
      </renderers.TopLevelComponent>
    </div>
  );
};

export default React.memo(SkeletonEditable);
