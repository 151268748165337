import React, { useState, useEffect } from 'react';
import firebase from 'firebase/app';
import { useFirestore } from '../firebase/hooks';
import { Directory, DirectoryWithID, DirectoryPush } from './repositories';
import { directoryPathToDocPath } from './directory';
import {
  DirectoryList,
  DirectoryListItem,
  DirectoryListItemInput,
  DirectoryListContainer,
} from './components/directorylist';
import DirectoryNamesMapper, {
  DirectoryIdNameMap,
  useAddDirectoryIdNameMap,
} from './DirectoryNamesMapper';
import { paths } from '../App/Routes';

const createNewDirectory = (
  firestore: firebase.firestore.Firestore,
  uid: string,
  parentDirectoryPath: string,
  name: string
): Promise<firebase.firestore.DocumentReference> => {
  const parentDirectoryDocPath = directoryPathToDocPath(parentDirectoryPath);

  const directoriesRef = firestore
    .doc(`users/${uid}${parentDirectoryDocPath}`)
    .collection('directories');

  const newDirectory: DirectoryPush = {
    name,
    createdAt: firebase.firestore.FieldValue.serverTimestamp(),
    updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
  };

  return directoriesRef.add(newDirectory);
};

interface DirectoryAddFormProps {
  uid: string;
  parentDirectoryPath: string;
  onClose: () => void;
}
const DirectoryAddForm: React.FC<DirectoryAddFormProps> = (props) => {
  const [name, setName] = useState('');

  const firestore = useFirestore();
  if (firestore == null) {
    return null;
  }

  return (
    <DirectoryListItemInput
      value={name}
      onChange={(e) => setName(e.target.value)}
      onClose={props.onClose}
      onKeyDown={(e) => {
        if (e.nativeEvent.isComposing) {
          // Do not override IME's key handling
          return;
        }
        if (e.key === 'Enter') {
          e.preventDefault();

          if (name.length > 0) {
            createNewDirectory(
              firestore,
              props.uid,
              props.parentDirectoryPath,
              name
            ).then(() => {
              setName('');
              props.onClose();
            });
          } else {
            // TODO: Show error
            props.onClose();
          }
        }
      }}
    />
  );
};

interface RecursiveConnectedDirectoryListProps {
  uid: string;
  directory: { path: string; name: string };
  selectedDirectoryPath: string;
  onDirectoryClick: () => void;
}
const RecursiveConnectedDirectoryList: React.FC<RecursiveConnectedDirectoryListProps> = (
  props
) => {
  const [fetched, setFetched] = useState(false);
  const [childDirectoriesOpen, setChildDirectoriesOpen] = useState(false);
  const [childDirectories, setChildDirectories] = useState<DirectoryWithID[]>(
    []
  );

  const [newDirFormOpen, setNewDirFormOpen] = useState(false);

  const firestore = useFirestore();

  const directoryPathSegments = props.directory.path
    .split('/')
    .filter((d) => d);
  const selectedDirectoryPathSegments = props.selectedDirectoryPath
    .split('/')
    .filter((d) => d);

  const directoryId = directoryPathSegments[directoryPathSegments.length - 1];

  const selectedDirectoryId =
    selectedDirectoryPathSegments[selectedDirectoryPathSegments.length - 1];
  const isRoot = directoryId == null;
  const isSelected = selectedDirectoryId === directoryId;

  const hasSelectedChild =
    isRoot || selectedDirectoryPathSegments.includes(directoryId);

  const addDirectoryIdMap = useAddDirectoryIdNameMap();

  const shouldLoad = hasSelectedChild || childDirectoriesOpen;
  useEffect(() => {
    if (!firestore) {
      return;
    }

    if (!shouldLoad) {
      return;
    }

    const directoryDocPath = directoryPathToDocPath(props.directory.path);

    const childDirectoriesRef = firestore
      .doc(`users/${props.uid}${directoryDocPath}`)
      .collection('directories')
      .orderBy('createdAt', 'asc');
    return childDirectoriesRef.onSnapshot((querySnapshot) => {
      const fetchedChildDirectories = querySnapshot.docs
        .map((doc) => {
          if (!doc.exists) {
            return null;
          }
          const rawDirectory = doc.data() as Partial<Directory>;
          const directory: DirectoryWithID = {
            id: doc.id,
            name: rawDirectory.name || '',
            createdAt:
              rawDirectory.createdAt ||
              firebase.firestore.Timestamp.fromDate(new Date()),
            updatedAt:
              rawDirectory.updatedAt ||
              firebase.firestore.Timestamp.fromDate(new Date()),
          };
          return directory;
        })
        .filter((d) => d != null) as DirectoryWithID[];
      setChildDirectories(fetchedChildDirectories);
      addDirectoryIdMap(
        fetchedChildDirectories.reduce<DirectoryIdNameMap>((acc, cur) => {
          acc[cur.id] = cur.name;
          return acc;
        }, {})
      );
      setFetched(true);
      setChildDirectoriesOpen(true);
    });
  }, [
    firestore,
    props.uid,
    props.directory.path,
    shouldLoad,
    addDirectoryIdMap,
  ]);

  return (
    <>
      <DirectoryListItem
        name={props.directory.name}
        isOpen={childDirectoriesOpen}
        selected={isSelected}
        to={paths.note(props.directory.path, null, null)}
        onLinkClick={props.onDirectoryClick}
        onOpenChange={(newState) => setChildDirectoriesOpen(newState)}
        onRequestAddChild={() => setNewDirFormOpen(true)}
      />
      {/* When the children list is not open, it's just hidden, but not unmounted */}
      <DirectoryList hidden={!childDirectoriesOpen}>
        {childDirectories.map((childDirectory) => {
          return (
            <li key={childDirectory.id}>
              <RecursiveConnectedDirectoryList
                uid={props.uid}
                directory={{
                  path: `${props.directory.path}/${childDirectory.id}`,
                  name: childDirectory.name,
                }}
                selectedDirectoryPath={props.selectedDirectoryPath}
                onDirectoryClick={props.onDirectoryClick}
              />
            </li>
          );
        })}
        {newDirFormOpen && fetched && (
          <li>
            <DirectoryAddForm
              uid={props.uid}
              parentDirectoryPath={props.directory.path}
              onClose={() => setNewDirFormOpen(false)}
            />
          </li>
        )}
      </DirectoryList>
    </>
  );
};

interface ConnectedDirectoryListProps {
  uid: string;
  selectedDirectoryPath: string;
  onDirectoryNamesFetched: (dirNames: (string | undefined)[]) => void;
  onDirectoryClick: () => void;
}
const ConnectedDirectoryList: React.FC<ConnectedDirectoryListProps> = (
  props
) => {
  return (
    <DirectoryListContainer>
      <DirectoryNamesMapper
        selectedDirectoryPath={props.selectedDirectoryPath}
        onDirectoryNamesFetched={props.onDirectoryNamesFetched}
      >
        <RecursiveConnectedDirectoryList
          uid={props.uid}
          directory={{
            path: '',
            name: '/',
          }}
          selectedDirectoryPath={props.selectedDirectoryPath}
          onDirectoryClick={props.onDirectoryClick}
        />
      </DirectoryNamesMapper>
    </DirectoryListContainer>
  );
};

export default ConnectedDirectoryList;
