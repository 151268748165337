import { css } from '@emotion/core';

export const badgeShapeCss = css({
  display: 'inline-flex',
  alignItems: 'center',
  padding: '4px 8px',
  fontWeight: 600,
  fontSize: '0.5em',
  maxHeight: '1.2em',
  lineHeight: 'unset',
  whiteSpace: 'nowrap',
  borderRadius: '3px',
});
