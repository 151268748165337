import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';

import { firebaseConfig } from './config';

/**
 * Initialize firebase
 */
firebase.initializeApp(firebaseConfig);

export const firebaseAnalytics = firebase.analytics();

export const firebaseAuth = firebase.auth();
// firebaseAuth.setPersistence(firebase.auth.Auth.Persistence.SESSION);

export const firebaseFunctions = firebase.functions();
// firebaseFunctions.useFunctionsEmulator('http://localhost:5001')
