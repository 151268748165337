export type GitHubInfo = GitHubIssue | GitHubPull;
export interface GitHubIssue {
  type: 'issue';
  owner: string;
  repo: string;
  issueNumber: number;
  url: string;
}
export interface GitHubPull {
  type: 'pull';
  owner: string;
  repo: string;
  pullNumber: number;
  url: string;
}

export const extractGitHubInfo = (url: string): GitHubInfo | null => {
  let urlobj: URL;

  try {
    urlobj = new URL(url);
  } catch (e) {
    return null;
  }

  if (urlobj.hostname !== 'github.com') {
    return null;
  }

  const paths = urlobj.pathname.split('/');
  if (paths.length !== 5) {
    return null;
  }

  let type: 'issue' | 'pull';
  if (paths[3] === 'issues') {
    type = 'issue';
    // TODO
    // } else if (paths[3] === 'pulls') {
    //   type = 'pull';
  } else {
    return null;
  }

  return {
    type,
    owner: paths[1],
    repo: paths[2],
    issueNumber: parseInt(paths[4]),
    url,
  };
};
