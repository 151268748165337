/** @jsx jsx */
import React from 'react';
import { jsx, css } from '@emotion/core';
import { QuestionIcon } from '@primer/octicons-react';
import { Button, Tooltip } from 'sancho';
import { badgeShapeCss } from './css';
import BadgeContainer from './BadgeContainer';
import BadgeText from './BadgeText';
import RefreshButton from './RefreshButton';
import { githubAppInstallationURL } from '../../../../../const';

const notFoundBadgeCss = css([
  badgeShapeCss,
  { color: '#586069', backgroundColor: '#fafbfc' },
]);

let NotFoundIssueBadgeInner = React.forwardRef<HTMLSpanElement>(
  (props, ref) => {
    return (
      <span ref={ref} {...props} css={notFoundBadgeCss}>
        <QuestionIcon size={14} />
        <BadgeText>Not Found</BadgeText>
      </span>
    );
  }
);
NotFoundIssueBadgeInner.displayName = 'NotFoundIssueBadgeInner';
NotFoundIssueBadgeInner = React.memo(NotFoundIssueBadgeInner);

let PopupContent: React.FC = () => {
  return (
    <div>
      <p>
        If the issue is not found even though it exists, it may be because
        Tasquet has not been permitted to access to the repository. Please
        install or update the permission of Tasquet GitHub App.
      </p>
      <Button component="a" href={githubAppInstallationURL}>
        Install Tasquet Github App
      </Button>
    </div>
  );
};
PopupContent = React.memo(PopupContent);

interface NotFoundIssueBadgeProps {
  onRefresh: () => void;
}
const NotFoundIssueBadge: React.FC<NotFoundIssueBadgeProps> = (props) => {
  return (
    <BadgeContainer>
      <Tooltip content={<PopupContent />}>
        <NotFoundIssueBadgeInner />
      </Tooltip>
      <RefreshButton onClick={props.onRefresh} />
    </BadgeContainer>
  );
};

export default React.memo(NotFoundIssueBadge);
