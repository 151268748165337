/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { useTheme } from 'sancho';
import useMedia from 'use-media';

import SwipeFrame from './SwipeFrame';
import SplitFrame from './SplitFrame';
export { useSplitFrame } from './SplitFrame';

interface SideBySideFrameProps {
  localStorageKey: string;
  primary?: React.ReactElement | null;
  secondary?: React.ReactElement | null;
}
const SideBySideFrame: React.FC<SideBySideFrameProps> = (props) => {
  const theme = useTheme();
  const isMedium = useMedia({ minWidth: theme.breakpoints.md });

  if (isMedium) {
    return (
      <SplitFrame
        primary={props.primary}
        secondary={props.secondary}
        localStorageKey={props.localStorageKey}
      />
    );
  }

  return <SwipeFrame primary={props.primary} secondary={props.secondary} />;
};

export default React.memo(SideBySideFrame);
