/** @jsx jsx */
import React, { useState, useMemo } from 'react';
import { jsx } from '@emotion/core';
import { Button, Text, IconButton, ResponsivePopover, useTheme } from 'sancho';
import OfflinePersistenceIcon from './OfflinePersistenceIcon';
import ReloadDialog from './ReloadDialog';

interface FirestoreOfflinePersistenceControlProps {
  enabled: boolean;
  requested: boolean;
  errCode: string | undefined;
  request: (enabled: boolean) => void;
}
const FirestoreOfflinePersistenceControl: React.FC<FirestoreOfflinePersistenceControlProps> = (
  props
) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const theme = useTheme();

  const iconButton = useMemo(() => {
    const statement = `Offline data persistence is ${
      props.enabled ? 'enabled' : 'disabled'
    }`;
    return (
      <IconButton
        icon={<OfflinePersistenceIcon enabled={props.enabled} />}
        label={statement}
        title={statement} // TODO: Replace with <Tooltip />
        variant="ghost"
      />
    );
  }, [props.enabled]);

  const content: React.ReactNode = (() => {
    if (props.enabled) {
      return (
        <React.Fragment>
          <Text variant="lead">Offline data persistence is now enabled</Text>
          <Text variant="paragraph">
            Your data such as notes and tasks is stored in your local
            environment (IndexedDB is used) and can be accessed even in offline
            state. If this device is not trusted, please disable persistence.
          </Text>
          <Button
            intent="none"
            onClick={() => {
              props.request(false);
              setDialogOpen(true);
            }}
          >
            Disable
          </Button>
        </React.Fragment>
      );
    }

    if (props.requested) {
      // Already requested, but not enabled

      let errorMsg: string;
      if (props.errCode === 'failed-precondition') {
        errorMsg =
          'Multiple tabs open, persistence can only be enabled in one tab at a a time.';
      } else if (props.errCode === 'unimplemented') {
        errorMsg =
          'The current browser does not support all of the features required to enable persistence.';
      } else {
        errorMsg = `Error code: "${props.errCode}"`;
      }

      return (
        <React.Fragment>
          <Text variant="lead">Offline data persistence is now disabled</Text>
          <Text variant="paragraph">{errorMsg}</Text>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <Text variant="lead">Offline data persistence is now disabled</Text>
        <Text variant="paragraph">
          Tasquet supports offline data persistence but it is now disabled. If
          enabled, your data such as notes and tasks is stored in your local
          environment (technically IndexedDB is used) and can be accessed even
          in offline state. Please make sure you are on a trusted device before
          enabling persistence.
        </Text>
        <Button
          intent="primary"
          onClick={() => {
            props.request(true);
            setDialogOpen(true);
          }}
        >
          Enable
        </Button>
      </React.Fragment>
    );
  })();

  return (
    <React.Fragment>
      <ResponsivePopover
        content={
          <div
            css={{
              padding: theme.spaces.md,
              maxWidth: theme.breakpoints.sm,
            }}
          >
            {content}
          </div>
        }
      >
        {iconButton}
      </ResponsivePopover>
      <ReloadDialog
        isOpen={dialogOpen}
        onRequestClose={() => setDialogOpen(false)}
      />
    </React.Fragment>
  );
};

export default React.memo(FirestoreOfflinePersistenceControl);
