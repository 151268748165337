import React from 'react';
import { isTopLevel, renderers } from '@tasquet/slate-markdown-plugin';
import Gutter from './Gutter';

const renderElement: typeof renderers.renderElement = (props) => {
  const { children, element, attributes } = props;

  if (isTopLevel(element)) {
    return (
      <renderers.TopLevelBlock
        element={element}
        attributes={attributes}
        Gutter={<Gutter element={element} />}
      >
        {children}
      </renderers.TopLevelBlock>
    );
  } else {
    return renderers.renderElement(props);
  }
};

export default renderElement;
