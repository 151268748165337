/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { Spinner } from 'sancho';
import BadgeContainer from './BadgeContainer';
import BadgeText from './BadgeText';
import { badgeShapeCss } from './css';

const LoadingBadge: React.FC = () => {
  return (
    <BadgeContainer>
      <span css={badgeShapeCss}>
        <Spinner size="sm" />
        <BadgeText>Loading...</BadgeText>
      </span>
    </BadgeContainer>
  );
};

export default LoadingBadge;
