import {
  NullableDateTime,
  DateTime,
  toDate,
  currentDateTime,
  mergeDateTimes,
  zerofill2,
} from './datetime';
import { MarkdownEnhancedEditor } from '@tasquet/slate-markdown-plugin';
import { getParentHeadings } from './headings';

const reDateYMDTime24 = new RegExp(
  '(from|to) ' +
    '(([12]\\d{3})-(\\d{2})-(\\d{2}) )?' +
    '([0-9]|[01][0-9]|2[0-3]):([0-5][0-9])(:([0-5][0-9]))?'
);

export interface NullableDateTimeTask {
  title: string;
  start: NullableDateTime | null;
  end: NullableDateTime | null;
}

export interface DateTimeTask {
  title: string;
  start: DateTime | null;
  end: DateTime | null;
}

export interface DateTask {
  title: string;
  start: Date | null;
  end: Date | null;
}

export const asDateTask = (dateTimeTask: DateTimeTask): DateTask => ({
  title: dateTimeTask.title,
  start: dateTimeTask.start ? toDate(dateTimeTask.start) : null,
  end: dateTimeTask.end ? toDate(dateTimeTask.end) : null,
});

const reYearPart = new RegExp('(.* |^)([12][0-9]{3})([ /-]|$)');
const reMonthPart = new RegExp('(.* |^)(0?[1-9]|1[012])([ /-]|$)');
const reDayPart = new RegExp('(.* |^)(0?[1-9]|[12][0-9]|3[01])( |$)');
const ParsingYMDTarget = {
  year: 0,
  month: 1,
  day: 2,
} as const;
type ParsingYMDTarget = typeof ParsingYMDTarget[keyof typeof ParsingYMDTarget];

export const parseCascadedDateTexts = (texts: string[]): NullableDateTime => {
  let year: NullableDateTime['year'] = null;
  let month: NullableDateTime['month'] = null;
  let day: NullableDateTime['day'] = null;

  // 1: Ready to parse year, month and day.
  // 2: month and day.
  // 3: day.
  let parsingTarget: ParsingYMDTarget = ParsingYMDTarget.year;

  for (let text of texts) {
    if (year == null && parsingTarget <= ParsingYMDTarget.year) {
      const yearMatch = text.match(reYearPart);
      if (yearMatch) {
        year = parseInt(yearMatch[2]);
        text = text.slice(yearMatch[0].length);
        parsingTarget = ParsingYMDTarget.month;
      }
    }

    if (month == null && parsingTarget <= ParsingYMDTarget.month) {
      const monthMatch = text.match(reMonthPart);
      if (monthMatch) {
        month = parseInt(monthMatch[2]);
        text = text.slice(monthMatch[0].length);
        parsingTarget = ParsingYMDTarget.day;
      }
    }

    if (day == null && parsingTarget <= ParsingYMDTarget.day) {
      const dayMatch = text.match(reDayPart);
      if (dayMatch) {
        day = parseInt(dayMatch[2]);

        break;
      }
    }
  }

  return {
    year,
    month,
    day,
    hour: null,
    minute: null,
    second: null,
  };
};

export const parseTask = (text: string): NullableDateTimeTask | null => {
  let offset = 0;

  let title = '';
  let start: NullableDateTime | null = null;
  let end: NullableDateTime | null = null;

  for (;;) {
    const match = text.slice(offset).match(reDateYMDTime24);
    if (match == null || match.index == null) {
      break;
    }

    const prefix = match[1];

    const yearStr = match[3];
    const monthStr = match[4];
    const dayStr = match[5];
    const hourStr = match[6];
    const minuteStr = match[7];
    const secondStr = match[9];

    const year = yearStr == null ? null : parseInt(yearStr);
    const month = monthStr == null ? null : parseInt(monthStr);
    const day = dayStr == null ? null : parseInt(dayStr);
    const hour = parseInt(hourStr);
    const minute = parseInt(minuteStr);
    const second = secondStr == null ? 0 : parseInt(secondStr);

    if (prefix === 'from') {
      start = {
        year,
        month,
        day,
        hour,
        minute,
        second,
      };
    } else if (prefix === 'to') {
      end = {
        year,
        month,
        day,
        hour,
        minute,
        second,
      };
    }

    title += text.slice(offset, offset + match.index);

    offset += match.index + match[0].length;
  }

  title += text.slice(offset);

  if (start == null && end == null) {
    return null;
  }

  title = title.trim();

  return {
    title,
    start,
    end,
  };
};

export const parseContextDateTime = (
  editor: MarkdownEnhancedEditor,
  index: number
): DateTime => {
  const parentHeadings = getParentHeadings(editor, index);
  const dateTimeFromHeadings = parseCascadedDateTexts(
    parentHeadings.map((h) => h.title)
  );
  const contextDateTime = mergeDateTimes(
    dateTimeFromHeadings,
    currentDateTime(true)
  );

  return contextDateTime;
};

const matchYMD = (dt1: NullableDateTime, dt2: NullableDateTime): boolean =>
  dt1.year === dt2.year && dt1.month === dt2.month && dt1.day === dt2.day;

export const updateTaskString = (
  text: string,
  task: DateTimeTask,
  contextDate?: NullableDateTime
): string => {
  let replaceOffset = 0;
  let finished;

  const replacerDateYMDTime24 = (
    match: string,
    prefix: string,
    ymdStr: string | undefined,
    yearStr: string | undefined,
    monthStr: string | undefined,
    dayStr: string | undefined,
    hourStr: string,
    minuteStr: string,
    p8: string,
    secondStr: string | undefined,
    offset: number,
    allString: string
  ): string => {
    // If replacer is called, it means the matched text exists.
    finished = false;

    let replacement: string;
    if (prefix === 'from') {
      const start = task.start;
      if (start == null) {
        replacement = match;
      } else {
        let ymdStr: string;
        if (contextDate && matchYMD(start, contextDate)) {
          ymdStr = '';
        } else {
          ymdStr = ` ${start.year}-${zerofill2(start.month)}-${zerofill2(
            start.day
          )}`;
        }

        const hmStr = `${zerofill2(start.hour)}:${zerofill2(start.minute)}`;
        const hmsStr =
          start.second === 0 && secondStr == null
            ? hmStr
            : `${hmStr}:${zerofill2(start.second)}`;

        replacement = `${prefix}${ymdStr} ${hmsStr}`;
      }
    } else if (prefix === 'to') {
      const end = task.end;
      if (end == null) {
        replacement = match;
      } else {
        let ymdStr: string;
        if (contextDate && matchYMD(end, contextDate)) {
          ymdStr = '';
        } else {
          ymdStr = ` ${end.year}-${zerofill2(end.month)}-${zerofill2(end.day)}`;
        }

        const hmStr = `${zerofill2(end.hour)}:${zerofill2(end.minute)}`;
        const hmsStr =
          end.second === 0 && secondStr == null
            ? hmStr
            : `${hmStr}:${zerofill2(end.second)}`;

        replacement = `${prefix}${ymdStr} ${hmsStr}`;
      }
    } else {
      replacement = match;
    }

    replaceOffset += offset + replacement.length;

    return replacement;
  };

  for (;;) {
    finished = true; // This flag is updated in the replacer
    text =
      text.slice(0, replaceOffset) +
      text.slice(replaceOffset).replace(reDateYMDTime24, replacerDateYMDTime24);

    if (finished) {
      break;
    }
  }

  return text;
};

export const fillTaskDateTimes = (
  task: NullableDateTimeTask,
  defaultDateTime?: DateTime
): DateTimeTask => {
  const overridingDateTime = defaultDateTime || currentDateTime(true);

  return {
    title: task.title,
    start: task.start ? mergeDateTimes(task.start, overridingDateTime) : null,
    end: task.end ? mergeDateTimes(task.end, overridingDateTime) : null,
  };
};
