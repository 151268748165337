/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { IssueOpenedIcon, IssueClosedIcon } from '@primer/octicons-react';
import { badgeShapeCss } from './css';
import BadgeContainer from './BadgeContainer';
import BadgeText from './BadgeText';
import RefreshButton from './RefreshButton';

const getLinkUrl = (owner: string, repo: string, issueNumber: number): string =>
  `https://github.com/${owner}/${repo}/issues/${issueNumber}`;

interface IssueBadgeProps {
  owner: string;
  repo: string;
  issueNumber: number;
  open: boolean | undefined;
  onRefresh: () => void;
}
const IssueBadge: React.FC<IssueBadgeProps> = (props) => {
  const href = getLinkUrl(props.owner, props.repo, props.issueNumber);

  return (
    <BadgeContainer>
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        css={[
          badgeShapeCss,
          {
            color: '#fff',
            backgroundColor:
              props.open != null && props.open ? '#2cbe4e' : '#cb2431',
          },
        ]}
      >
        {props.open != null &&
          (props.open ? (
            <IssueOpenedIcon size={14} />
          ) : (
            <IssueClosedIcon size={14} />
          ))}
        <BadgeText>
          {props.open != null && props.open ? 'Open' : 'Closed'}
        </BadgeText>
      </a>
      <RefreshButton onClick={props.onRefresh} />
    </BadgeContainer>
  );
};

export default React.memo(IssueBadge);
