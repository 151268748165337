import React from 'react';
import { Popover, Button, IconButton, IconTrash2 } from 'sancho';

interface DeleteButtonProps {
  onDelete: () => void;
}
const DeleteButton: React.FC<DeleteButtonProps> = (props) => {
  return (
    <Popover
      content={
        <div style={{ padding: '1em' }}>
          <Button
            onPress={props.onDelete}
            intent="danger"
            iconBefore={<IconTrash2 />}
          >
            Delete
          </Button>
        </div>
      }
    >
      <IconButton icon={<IconTrash2 />} label="delete" tabIndex={-1} />
    </Popover>
  );
};

export default React.memo(DeleteButton);
