/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { useTheme, IconSun, IconMoon } from 'sancho';
import { useDarkMode } from '../../darkmode';
import ItemContainer from './components/ItemContainer';
import Toggle from './components/Toggle';

const DarkModeToggle: React.FC = () => {
  const [darkMode, setDarkMode] = useDarkMode();
  const theme = useTheme();

  return (
    <ItemContainer title="Toggle dark mode">
      <IconSun />
      <Toggle
        value={darkMode}
        onChange={setDarkMode}
        css={{ margin: `0 ${theme.spaces.md}` }}
      />
      <IconMoon />
    </ItemContainer>
  );
};
export default React.memo(DarkModeToggle);
