import React, { useMemo } from 'react';
import { LinkProps } from 'react-router-dom';
import TagList from './TagList';
import TagListItem from './TagListItem';
import TagListItemForm from './TagListItemForm';
import { Tag } from '../TagsSubscriber';

const DELIMITER = '/';

interface NestedTag {
  tag: Tag;
  level: number;
  nameSegments: string[];
  children: NestedTag[];
}

interface NestableTagListProps {
  nestedTags: NestedTag[];
  selectedTagId: Tag['id'] | null;
  onTagDelete: (tagId: string) => Promise<unknown>;
  onTagCreate?: (name: string) => Promise<unknown>;
  tagLinkToFn: (tag: Tag) => LinkProps['to'];
}
let NestableTagList: React.FC<NestableTagListProps> = (props) => {
  return (
    <TagList>
      {props.nestedTags.map((nestedTag) => (
        <TagListItem
          key={nestedTag.tag.id}
          displayName={nestedTag.nameSegments
            .slice(nestedTag.level)
            .join(DELIMITER)}
          tag={nestedTag.tag}
          selected={nestedTag.tag.id === props.selectedTagId}
          to={props.tagLinkToFn(nestedTag.tag)}
          onDelete={props.onTagDelete}
        >
          <NestableTagList
            nestedTags={nestedTag.children}
            selectedTagId={props.selectedTagId}
            onTagDelete={props.onTagDelete}
            tagLinkToFn={props.tagLinkToFn}
          />
        </TagListItem>
      ))}
      {props.onTagCreate && <TagListItemForm onSubmit={props.onTagCreate} />}
    </TagList>
  );
};
NestableTagList = React.memo(NestableTagList);

const nestTags = (tags: Tag[]): NestedTag[] => {
  const sortedTags = tags.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  const nestedTags: NestedTag[] = [];
  sortedTags.forEach((tag) => {
    const nameSegments = tag.name.split(DELIMITER);

    // Find a parent tag in `nestedTags`
    function findParentTag(
      parentCandidates: NestedTag[],
      level = 0
    ): NestedTag | undefined {
      const ancestor = parentCandidates.find(
        (maybeAncestor) =>
          nameSegments.length > level &&
          nameSegments[level] === maybeAncestor.nameSegments[level]
      );
      if (ancestor == null) {
        return undefined;
      }
      const parent = findParentTag(ancestor.children, level + 1);
      if (parent) {
        return parent;
      } else {
        return ancestor;
      }
    }
    const parentTag = findParentTag(nestedTags);
    if (parentTag) {
      parentTag.children.unshift({
        tag,
        level: parentTag.level + 1,
        nameSegments,
        children: [],
      });
    } else {
      nestedTags.unshift({
        tag,
        level: 0,
        nameSegments,
        children: [],
      });
    }
  });

  return nestedTags;
};

interface NestedTagListProps {
  tags: Tag[];
  selectedTagId: string | null;
  onTagCreate: (name: string) => Promise<unknown>;
  onTagDelete: (tagId: string) => Promise<unknown>;
  tagLinkToFn: (tag: Tag) => LinkProps['to'];
}
const NestedTagList: React.FC<NestedTagListProps> = (props) => {
  const nestedTags = useMemo(() => nestTags(props.tags), [props.tags]);

  return (
    <NestableTagList
      nestedTags={nestedTags}
      selectedTagId={props.selectedTagId}
      onTagDelete={props.onTagDelete}
      onTagCreate={props.onTagCreate}
      tagLinkToFn={props.tagLinkToFn}
    />
  );
};

export default React.memo(NestedTagList);
