import { MarkdownEnhancedEditor } from '@tasquet/slate-markdown-plugin';

export const getParentHeadings = (
  editor: MarkdownEnhancedEditor,
  index: number
): { title: string; depth: number }[] => {
  if (index >= editor.children.length || index <= -1) {
    return [];
  }

  const startTopLevelElement = editor.children[index];
  let foundMinDepth: number;
  if (startTopLevelElement.type === 'heading') {
    foundMinDepth = startTopLevelElement.mdastNode?.depth || 7;
  } else {
    foundMinDepth = 7;
  }

  const resultsRev: { title: string; depth: number }[] = [];
  for (let searchIdx = index - 1; searchIdx >= 0; --searchIdx) {
    const topLevelElement = editor.children[searchIdx];
    if (topLevelElement.type === 'heading') {
      const mdastNode = topLevelElement.mdastNode;
      if (mdastNode && mdastNode.depth < foundMinDepth) {
        resultsRev.push({
          title: mdastNode.children.map((child) => child.value).join(''),
          depth: mdastNode.depth,
        });
        foundMinDepth = mdastNode.depth;

        if (foundMinDepth === 1) {
          break;
        }
      }
    }
  }
  return resultsRev.reverse();
};
