import * as React from 'react';
import { Route, Redirect, Switch, Link, useRouteMatch } from 'react-router-dom';
import { Main } from '../components/layout';

import { GithubApiRedirected } from './github';

const BadRedirectPage: React.FC = () => {
  return (
    <Main>
      <p>Bad redirect</p>
      <p>
        <Link to="/">Go to top</Link>
      </p>
    </Main>
  );
};

const OauthRoute: React.FC = () => {
  const { url } = useRouteMatch();

  const redirectErrorPath = `${url}/redirect/error`;

  return (
    <Switch>
      <Route path={redirectErrorPath} component={BadRedirectPage} />
      <Route path={`${url}/redirect/github`}>
        <GithubApiRedirected
          pathOnSuccess="/"
          pathOnError={`${url}/redirect/error`}
        />
      </Route>
      <Route render={() => <Redirect to="/" />} />
    </Switch>
  );
};

export default OauthRoute;
