import { Repository } from '.';
import { DateTask } from '../ConnectedNoteApp/logics/task-parser';

export class VoidRepository implements Repository {
  createNote(): Promise<string> {
    return new Promise((resolve) => resolve(Math.random().toString()));
  }
  updateNote(): Promise<void> {
    return Promise.resolve();
  }
  listenNote(): () => void {
    return () => null;
  }
  listenNotes(): () => void {
    return () => null;
  }
  deleteNote(): Promise<void> {
    return Promise.resolve();
  }
  setTask(): Promise<void> {
    return Promise.resolve();
  }
  deleteTask(): Promise<void> {
    return Promise.resolve();
  }
  listenTaskChanges(): () => void {
    return () => null;
  }
  getTasks(): Promise<{ [taskId: string]: DateTask }> {
    return Promise.resolve({});
  }
  listenGoogleCalendarEvents(): () => void {
    return () => null;
  }
}
