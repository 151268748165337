/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { Link } from 'react-router-dom';
import {
  useTheme,
  Text,
  Input,
  InputBaseProps,
  Popover,
  MenuList,
  MenuItem,
  CloseButton,
  IconButton,
  IconPlus,
  IconChevronRight,
  IconChevronDown,
  IconMoreVertical,
} from 'sancho';

interface DirectoryListItemProps extends JSX.IntrinsicAttributes {
  name: string;
  isOpen?: boolean;
  onOpenChange: (newState: boolean) => void;
  selected?: boolean;
  to?: React.ComponentProps<typeof Link>['to'];
  onLinkClick?: () => void;
  onRequestAddChild: () => void;
}
export const DirectoryListItem: React.FC<DirectoryListItemProps> = (props) => {
  const {
    name,
    isOpen,
    onOpenChange,
    selected,
    to,
    onLinkClick,
    onRequestAddChild,
  } = props;

  const theme = useTheme();

  return (
    <span
      css={{
        display: 'flex',
        alignItems: 'center',
        backgroundColor: selected ? theme.colors.background.tint2 : undefined,
        height: '28px',

        '& .FolderMenu': {
          display: 'none',
        },
        '&:hover .FolderMenu': {
          display: 'inherit',
        },
      }}
    >
      <IconButton
        variant="ghost"
        label={isOpen ? 'Close' : 'Open'}
        intent="none"
        icon={isOpen ? <IconChevronDown /> : <IconChevronRight />}
        css={{
          width: theme.iconSizes.md,
          height: theme.iconSizes.md,
        }}
        onPress={() => onOpenChange(!isOpen)}
      />
      <Text
        css={{
          marginLeft: theme.spaces.sm,
          cursor: 'default',
          flexGrow: 2,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
        }}
      >
        {!selected && to ? (
          <Link
            to={to}
            onClick={onLinkClick}
            css={{
              color: theme.colors.text.default,
              textDecoration: 'none',
              display: 'inline-block',
              width: '100%',
            }}
          >
            {name}
          </Link>
        ) : (
          name
        )}
      </Text>
      <Popover
        content={
          <MenuList
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <MenuItem onPress={onRequestAddChild}>Create a sub folder</MenuItem>
          </MenuList>
        }
      >
        <IconButton
          className="FolderMenu"
          css={{
            cursor: 'pointer',
            opacity: 0.5,
          }}
          size="xs"
          variant="ghost"
          intent="none"
          label="Menu"
          icon={<IconMoreVertical />}
        />
      </Popover>
    </span>
  );
};

interface DirectoryListItemInput extends InputBaseProps {
  onClose: () => void;
}
export const DirectoryListItemInput: React.FC<DirectoryListItemInput> = (
  props
) => {
  const { onClose, ...restProps } = props;

  const theme = useTheme();

  return (
    <span
      css={{
        display: 'flex',
        alignItems: 'center',
        height: '28px',
      }}
    >
      <span
        css={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <IconPlus
          css={{
            opacity: 0.5,
          }}
        />
      </span>
      <Input
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={(node?: HTMLInputElement) => {
          setTimeout(() => {
            if (node) {
              node.focus();
            }
          });
        }}
        type="text"
        inputSize="sm"
        css={{
          margin: `0 ${theme.spaces.sm}`,
        }}
        onBlur={onClose}
        {...restProps}
      />
      <CloseButton
        variant="ghost"
        intent="none"
        label="Close"
        css={{
          width: theme.iconSizes.md,
          height: theme.iconSizes.md,
        }}
        onPress={onClose}
      />
    </span>
  );
};

interface DirectoryListProps {
  hidden?: boolean;
}
export const DirectoryList: React.FC<DirectoryListProps> = (props) => {
  const theme = useTheme();

  return (
    <ul
      css={{
        display: props.hidden ? 'none' : 'block',
        margin: 0,
        padding: 0,
        listStyle: 'none',
        paddingLeft: theme.spaces.sm,
      }}
    >
      {props.children}
    </ul>
  );
};

export const DirectoryListContainer: React.FC = (props) => {
  const theme = useTheme();

  return (
    <div
      css={{
        padding: theme.spaces.sm,
        height: '100%',
        overflowY: 'scroll',
      }}
    >
      <Text variant="uppercase">Folders</Text>
      {props.children}
    </div>
  );
};
