import { Editor } from 'slate';
import { MarkdownEnhancedEditor } from '@tasquet/slate-markdown-plugin';

import {
  updateTaskString,
  parseContextDateTime,
  DateTask,
} from './logics/task-parser';
import { fromDate } from './logics/datetime';

export const TasquetConnectedEditor = {
  applyTask(
    editor: MarkdownEnhancedEditor,
    taskId: string,
    task: DateTask
  ): void {
    const index = editor.children.findIndex(
      (element) => element.taskId === taskId
    );
    if (index >= 0) {
      const element = editor.children[index];
      const currentText = element.children[0].children[0].text; // TODO: Multi lines
      const contextDateTime = parseContextDateTime(editor, index);
      const newText = updateTaskString(
        currentText,
        {
          title: task.title,
          start: task.start ? fromDate(task.start) : null,
          end: task.end ? fromDate(task.end) : null,
        },
        contextDateTime
      );
      const textPath = [index, 0, 0]; // TODO: Multi lines
      if (currentText !== newText) {
        Editor.withoutNormalizing(editor, () => {
          editor.apply({
            type: 'remove_text',
            path: textPath,
            offset: 0,
            text: currentText,
          });
          editor.apply({
            type: 'insert_text',
            path: textPath,
            offset: 0,
            text: newText,
          });
        });
      }
    }
  },
};
