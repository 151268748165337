import React, { useMemo, useCallback } from 'react';
import { useNoteSubscription } from './NoteSubscriber';
import { useTagsSubscription } from '../TagsSubscriber';
import NoteTagSelectComponent from './components/NoteTagSelect';

const TagSelect: React.FC = () => {
  const {
    note,
    repository,
    uid,
    directoryPath,
    noteId,
  } = useNoteSubscription();
  const { tags } = useTagsSubscription();

  const availableTags = useMemo(
    () => tags.filter((tag) => !note?.tagIds.includes(tag.id)),
    [tags, note]
  );

  const onSelect = useCallback(
    (tagId: string) => {
      if (
        uid == null ||
        directoryPath == null ||
        noteId == null ||
        note == null
      ) {
        return null;
      }

      // TODO: Split updating the tags and text. Throttling is not necessary for tags.
      (async function () {
        await repository.flush();
        await repository.updateNote(uid, directoryPath, noteId, {
          tagIds: [...note.tagIds, tagId],
        });
        await repository.flush();
      })();
    },
    [repository, uid, directoryPath, noteId, note]
  );

  return <NoteTagSelectComponent tags={availableTags} onSelect={onSelect} />;
};

export default React.memo(TagSelect);
