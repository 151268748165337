import React from 'react';
import { Editable } from 'slate-react';
import { Alert, Button, Text } from 'sancho';

const EditorErrorFallback: React.FC = () => {
  return (
    <div>
      <Alert intent="warning" title="I'm sorry, something broken.">
        <Text variant="paragraph">
          The editor is now working in fallback mode, where no text decoration
          is applied.
          <br />
          It seems the markdown parser failed to parse the text or the renderer
          raised an exception.
          <br />
          Please try to edit the text and reload the app to fix the problem.
          <br />
          Then, please report us the issue if possible.
        </Text>
        <div>
          <Button onClick={() => window.location.reload()}>Reload app</Button>
        </div>
      </Alert>
      <div style={{ padding: '10px' }}>
        <Editable />
      </div>
    </div>
  );
};

export default React.memo(EditorErrorFallback);
