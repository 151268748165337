export interface NullableDateTime {
  year: number | null;
  month: number | null; // 1-12
  day: number | null;
  hour: number | null;
  minute: number | null;
  second: number | null;
}

export interface DateTime extends NullableDateTime {
  year: number;
  month: number;
  day: number;
  hour: number;
  minute: number;
  second: number;
}

export const fromDate = (date: Date): DateTime => {
  return {
    year: date.getFullYear(),
    month: date.getMonth() + 1,
    day: date.getDate(),
    hour: date.getHours(),
    minute: date.getMinutes(),
    second: date.getSeconds(),
  };
};

export const toDate = (dateTime: DateTime): Date => {
  return new Date(
    dateTime.year,
    dateTime.month - 1,
    dateTime.day,
    dateTime.hour,
    dateTime.minute,
    dateTime.second
  );
};

export const dateTime = (
  year: number,
  month: number,
  day: number,
  hour: number,
  minute: number,
  second: number
): DateTime => ({
  year,
  month,
  day,
  hour,
  minute,
  second,
});

export const nullableDateTime = (
  year: number | null = null,
  month: number | null = null,
  day: number | null = null,
  hour: number | null = null,
  minute: number | null = null,
  second: number | null = null
): NullableDateTime => ({
  year,
  month,
  day,
  hour,
  minute,
  second,
});

export const currentDateTime = (zeroHMS = false): DateTime => {
  const currentDateTime = fromDate(new Date());
  if (!zeroHMS) {
    return currentDateTime;
  }
  currentDateTime.hour = 0;
  currentDateTime.minute = 0;
  currentDateTime.second = 0;
  return currentDateTime;
};

export const mergeDateTimes = (
  dt1: NullableDateTime,
  dt2: DateTime
): DateTime => ({
  year: dt1.year || dt2.year,
  month: dt1.month || dt2.month,
  day: dt1.day || dt2.day,
  hour: dt1.hour || dt2.hour,
  minute: dt1.minute || dt2.minute,
  second: dt1.second || dt2.second,
});

export const zerofill2 = (str: string | number): string =>
  ('00' + str).slice(-2);

export const formatDateTime = (dt: DateTime): string =>
  `${dt.year}-${zerofill2(dt.month)}-${zerofill2(dt.day)} ${zerofill2(
    dt.hour
  )}:${zerofill2(dt.minute)}:${zerofill2(dt.second)}`;
