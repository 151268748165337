/** @jsx jsx */
import React, { useState, useCallback } from 'react';
import { jsx } from '@emotion/core';
import { Select, Text, useTheme } from 'sancho';
import { Tag } from '../../TagsSubscriber';

interface TagSelectProps {
  tags: Tag[];
  onSelect: (tagId: string) => void;
}
const TagSelect: React.FC<TagSelectProps> = ({ tags, onSelect }) => {
  const [value, setValue] = useState('');

  const theme = useTheme();

  // NOTE: `Select` is not appropriate control and `Combobox` is better for this use, but it has a bug on `onSelect` handler.
  // TODO: Change to better control when the bug is fixed, or another component library like Chakra UI is introduced.
  return (
    <div css={{ position: 'relative' }}>
      {value === '' && (
        <Text
          css={{
            position: 'absolute',
            color: theme.colors.text.muted,
            left: theme.spaces.sm,
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          Add tag
        </Text>
      )}
      <Select
        inputSize="sm"
        css={{ minWidth: '150px' }}
        value={value}
        onChange={useCallback<React.ChangeEventHandler<HTMLSelectElement>>(
          (e) => {
            const tagId = e.target.value;
            if (tagId !== '') {
              onSelect(tagId);
            }
            setValue('');
          },
          [onSelect]
        )}
      >
        <option value=""></option>
        {tags.map((tag) => (
          <option value={tag.id} key={tag.id}>
            {tag.name}
          </option>
        ))}
      </Select>
    </div>
  );
};

export default React.memo(TagSelect);
