import React from 'react';
import { MenuList, MenuItem, IconLogOut } from 'sancho';

interface AuthMenuForLoggedInProps {
  onLogout: () => void;
}
const AuthMenuForLoggedIn: React.FC<AuthMenuForLoggedInProps> = (props) => {
  return (
    <MenuList>
      <MenuItem contentBefore={<IconLogOut />} onPress={props.onLogout}>
        Log out
      </MenuItem>
    </MenuList>
  );
};
export default React.memo(AuthMenuForLoggedIn);
