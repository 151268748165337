/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import {
  Button,
  Popover,
  IconButton,
  IconPlus,
  IconChevronDown,
  MenuList,
  MenuItem,
} from 'sancho';

interface NoteAddButtonProps {
  disabled?: boolean;
  onNoteAdd?: () => void;
  onTutorialsAdd?: () => void;
}
const NoteAddButton: React.FC<NoteAddButtonProps> = (props) => {
  return (
    <div
      css={{
        width: '100%',
        flexGrow: 1,
        display: 'flex',
      }}
    >
      <Button
        onPress={props.onNoteAdd}
        variant="outline"
        iconBefore={<IconPlus />}
        css={{
          flexGrow: 1,
        }}
        disabled={props.disabled}
      >
        Add new note
      </Button>
      <Popover
        content={
          <MenuList>
            <MenuItem onPress={props.onTutorialsAdd}>
              Insert tutorial notes
            </MenuItem>
          </MenuList>
        }
      >
        <IconButton
          variant="ghost"
          icon={<IconChevronDown />}
          label="Options..."
          disabled={props.disabled}
        />
      </Popover>
    </div>
  );
};

export default React.memo(NoteAddButton);
