/** @jsx jsx */
import React, { useImperativeHandle } from 'react';
import { jsx } from '@emotion/core';
import { Node } from 'slate';
import { Slate, Editable, ReactEditor } from 'slate-react';
import {
  decorate,
  renderers,
  useOnCopy,
  useOnCut,
  useOnKeyDown,
  MarkdownEnhancedEditor,
} from '@tasquet/slate-markdown-plugin';
import renderElement from './renderElement';
import { useSlateMarkdownTheme } from './markdown-theme';
import { useTheme } from 'sancho';
import EditorContainer from './EditorContainer';
import EditorErrorBoundary from './EditorErrorBoundary';

export interface SlateEditorProps {
  editor: MarkdownEnhancedEditor<ReactEditor>;
  value: Node[];
  onChange: (value: Node[]) => void;
}
export interface SlateEditorRef {
  focus: () => void;
}
const SlateEditor = React.forwardRef<SlateEditorRef, SlateEditorProps>(
  (props, ref) => {
    useImperativeHandle(
      ref,
      () => ({
        focus() {
          ReactEditor.focus(props.editor);
        },
      }),
      [props.editor]
    );

    const onCopy = useOnCopy(props.editor);
    const onCut = useOnCut(props.editor);
    const onKeyDown = useOnKeyDown(props.editor);

    const theme = useTheme();
    const EditorTheme = useSlateMarkdownTheme(theme);

    return (
      <Slate
        editor={props.editor}
        value={props.value}
        onChange={props.onChange}
      >
        <EditorContainer editor={props.editor}>
          <renderers.theme.ThemeProvider value={EditorTheme}>
            <renderers.MarkdownEditorProvider>
              <EditorErrorBoundary>
                <Editable
                  decorate={decorate}
                  renderElement={renderElement}
                  renderLeaf={renderers.renderLeaf}
                  css={renderers.editableStyle}
                  onCopy={onCopy}
                  onCut={onCut}
                  onKeyDown={onKeyDown}
                  spellCheck={false}
                />
              </EditorErrorBoundary>
            </renderers.MarkdownEditorProvider>
          </renderers.theme.ThemeProvider>
        </EditorContainer>
      </Slate>
    );
  }
);
SlateEditor.displayName = 'SlateEditor';

// NOTE: This React.memo is very important to make the rendered DOM element consistent with the given `editor` object
// so that the WeakMap managed in slate-react can track the mapping between the DOM and the editor object
// and methods such as ReactEditor.focus() can work properly.
export default React.memo(SlateEditor);
