import { useLocalStorage } from '@rehooks/local-storage';

export const isDarkModePreferred = (): boolean =>
  window.matchMedia &&
  window.matchMedia('(prefers-color-scheme: dark)').matches;

const darkModeInitVal = isDarkModePreferred();

export const useDarkMode = (): [boolean, React.Dispatch<boolean>] => {
  const [darkMode, setDarkMode] = useLocalStorage<boolean>(
    'darkmode',
    darkModeInitVal
  );
  return [darkMode, setDarkMode];
};
