/** @jsx jsx */
import React, { useEffect, useState } from 'react';
import { jsx } from '@emotion/core';
import { LayerLoading } from 'sancho';
import { Main, Centering } from './layout';
import { Logo } from './logo';

const InitialScreen: React.FC = () => {
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    const timeout = setTimeout(() => {
      setShowLoading(true);
    }, 1000);

    return () => clearTimeout(timeout);
  });

  return (
    <Main>
      <Centering>
        <Logo />
        <LayerLoading
          loading={showLoading}
          css={{ transition: 'opacity 1s ease' }}
        />
      </Centering>
    </Main>
  );
};

export default InitialScreen;
