import React, { useEffect, useGlobal } from 'reactn';
import { BrowserRouter as Router } from 'react-router-dom';
import { useFirebase } from '../firebase/hooks';
import { AppContainer } from '../components/layout';
import InitialScreen from '../components/InitialScreen';
import Login from '../auth/Login';
import Signup from '../auth/Signup';
import PasswordReset from '../auth/PasswordReset';
import ConnectedApp from '../ConnectedApp';
import Routes from './Routes';
import { useServiceWorker } from './serviceWorker';
import '../firebase';

const App: React.FC = () => {
  const authInitialized = useFirebase();

  const [pageTitle] = useGlobal('pageTitle');
  useEffect(() => {
    if (pageTitle) {
      document.title = `${pageTitle} / Tasquet`;
    } else {
      document.title = 'Tasquet';
    }
  }, [pageTitle]);

  useServiceWorker();

  return (
    <AppContainer>
      {authInitialized ? (
        <Router>
          <Routes
            Login={Login}
            Signup={Signup}
            PasswordReset={PasswordReset}
            ConnectedApp={ConnectedApp}
          />
        </Router>
      ) : (
        <InitialScreen />
      )}
    </AppContainer>
  );
};

export default App;
