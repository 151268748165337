import React, { useMemo } from 'react';
import { useFirestore } from '../firebase/hooks';
import NoteAddButton from './components/NoteAddButton';
import { FirestoreRepository, NoteCreate } from './repositories';
import { useInsertTutorialNotes } from '../tutorial';

interface ConnectedNoteAddButtonProps {
  uid: string;
  tagId: string | null;
  directoryPath: string;
  onNoteAdded: (
    directoryPath: string,
    tagId: string | null,
    noteId: string
  ) => void;
}
const ConnectedNoteAddButton: React.FC<ConnectedNoteAddButtonProps> = (
  props
) => {
  const { uid } = props;

  const firestore = useFirestore();
  const repository = useMemo(() => {
    return firestore ? new FirestoreRepository(firestore) : null;
  }, [firestore]);

  const insertTutorialNotes = useInsertTutorialNotes();

  if (!repository) {
    return <NoteAddButton disabled />;
  }

  const addNote = (): void => {
    const tagIds = props.tagId ? [props.tagId] : [];
    const newNote: NoteCreate = {
      title: '',
      tagIds,
      plainText: '',
      taskElementIndexMap: {},
    };

    repository.createNote(uid, props.directoryPath, newNote).then((noteId) => {
      props.onNoteAdded(props.directoryPath, props.tagId, noteId);
    });
  };

  return (
    <NoteAddButton onNoteAdd={addNote} onTutorialsAdd={insertTutorialNotes} />
  );
};

export default React.memo(ConnectedNoteAddButton);
