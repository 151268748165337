/** @jsx jsx */
import { jsx } from '@emotion/core';
import {
  useFirestoreOfflinePersistenceStatus,
  useFirestoreOfflinePersistenceRequest,
} from '../firebase/hooks';
import { useUser } from '../auth/hooks';
import FirestoreOfflinePersistenceControlComponent from './components/FirestoreOfflinePersistenceControl';

const FirestoreOfflinePersistenceControl: React.FC = () => {
  const user = useUser();
  const [
    offlinePersistenceRequested,
    offlinePersistence,
    offlinePersistenceErrCode,
  ] = useFirestoreOfflinePersistenceStatus();
  const firestoreOfflinePersistenceRequest = useFirestoreOfflinePersistenceRequest();

  if (!user) {
    return null;
  }

  return (
    <FirestoreOfflinePersistenceControlComponent
      enabled={offlinePersistence}
      requested={offlinePersistenceRequested}
      request={firestoreOfflinePersistenceRequest}
      errCode={offlinePersistenceErrCode}
    />
  );
};

export default FirestoreOfflinePersistenceControl;
