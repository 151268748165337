/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { Button, ButtonProps, IconFolder, useTheme } from 'sancho';

interface DirectoryNamesButtonProps extends Omit<ButtonProps, 'children'> {
  directoryNames: (string | undefined)[];
}
const DirectoryNamesButton: React.FC<DirectoryNamesButtonProps> = (props) => {
  const { directoryNames, ...restProps } = props;

  const theme = useTheme();

  const dirNamesStr =
    '/' + directoryNames.map((name) => (name == null ? '...' : name)).join('/');

  return (
    <div
      css={{
        padding: theme.spaces.sm,
      }}
    >
      <Button
        variant="outline"
        size="xs"
        iconBefore={<IconFolder />}
        css={{
          width: '100%',
        }}
        title={dirNamesStr}
        {...restProps}
      >
        <span
          css={{
            flexGrow: 1,
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            whiteSpace: 'nowrap',
          }}
        >
          {dirNamesStr}
        </span>
      </Button>
    </div>
  );
};

export default DirectoryNamesButton;
