import React, { useMemo } from 'react';
import { TopLevelElement } from '@tasquet/slate-markdown-plugin';
import GutterComponent from './components/Gutter';
import TaskInfo from './components/task/TaskInfo';
import Urls from './Urls';

import { DateTimeTask } from '../logics/task-parser';
import { extractURLsFromNodes } from './url';

const getUniqueURLs = (element: TopLevelElement): string[] => {
  const mdastNode = element.mdastNode;
  if (mdastNode == null) {
    return [];
  }
  if (
    mdastNode.type !== 'paragraph' &&
    mdastNode.type !== 'heading' &&
    mdastNode.type !== 'tableCell'
  ) {
    return [];
  }

  const urls = extractURLsFromNodes(mdastNode.children);
  return Array.from(new Set(urls));
};

interface GutterProps {
  element: TopLevelElement;
}
const Gutter: React.FC<GutterProps> = (props) => {
  const { element } = props;
  const taskId = element.taskId as string | undefined;
  const task = element.task as DateTimeTask | undefined;

  const uniqUrls: string[] = useMemo(() => getUniqueURLs(element), [element]);

  return (
    <GutterComponent>
      <Urls urls={uniqUrls} element={element} />
      {task && taskId && <TaskInfo taskId={taskId} task={task} />}
    </GutterComponent>
  );
};

export default React.memo(Gutter);
