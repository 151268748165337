import firebase from 'firebase/app';
import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { useFirestore } from '../firebase/hooks';

export interface Tag {
  id: string;
  name: string;
  createdAt: Date;
  updatedAt: Date;
}

export type TagCreate = Omit<Tag, 'id' | 'createdAt' | 'updatedAt'>;

interface FirestoreTag extends Omit<Tag, 'id' | 'createdAt' | 'updatedAt'> {
  user: string;
  createdAt: firebase.firestore.Timestamp;
  updatedAt: firebase.firestore.Timestamp;
}

interface FirestoreTagCreate extends TagCreate {
  user: string;
  createdAt: firebase.firestore.FieldValue;
  updatedAt: firebase.firestore.FieldValue;
}

interface TagsSubscriptionContextValue {
  tags: Tag[];
  createTag: (tag: TagCreate) => Promise<boolean>;
  deleteTag: (tagId: string) => Promise<boolean>;
}
const tagsSubscriptionContext = React.createContext<
  TagsSubscriptionContextValue | undefined
>(undefined);

export const useTagsSubscription = (): TagsSubscriptionContextValue => {
  const value = useContext(tagsSubscriptionContext);
  if (value == null) {
    throw new Error('TagsSubscriber is not set up.');
  }

  return value;
};

interface TagsSubscriberProps {
  uid: string;
  children: React.ReactNode;
}
const TagsSubscriber: React.FC<TagsSubscriberProps> = (props) => {
  const firestore = useFirestore();

  const [tags, setTags] = useState<Tag[]>([]);
  useEffect(() => {
    if (firestore == null) {
      return;
    }

    const unsubscribe = firestore
      .collection('users')
      .doc(props.uid)
      .collection('tags')
      .orderBy('updatedAt', 'desc')
      .onSnapshot((querySnapshot) => {
        const newFirebaseTags = querySnapshot.docs
          .map<Tag | undefined>((doc) => {
            if (!doc.exists) {
              return undefined;
            }

            const fetchedTag = doc.data() as Partial<FirestoreTag>;
            const filledTag: Tag = {
              id: doc.id,
              name: fetchedTag.name || '',
              createdAt: fetchedTag.createdAt?.toDate() || new Date(),
              updatedAt: fetchedTag.updatedAt?.toDate() || new Date(),
            };

            return filledTag;
          })
          .filter((n): n is Tag => !!n);

        setTags(newFirebaseTags);
      });

    return unsubscribe;
  }, [firestore, props.uid]);

  const createTag = useCallback<TagsSubscriptionContextValue['createTag']>(
    (tag) => {
      if (firestore == null) {
        return Promise.resolve(false);
      }

      const firestoreTag: FirestoreTagCreate = {
        ...tag,
        user: props.uid,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
      };

      return firestore
        .collection('users')
        .doc(props.uid)
        .collection('tags')
        .add(firestoreTag)
        .then(() => {
          return true;
        });
    },
    [firestore, props.uid]
  );

  const deleteTag = useCallback<TagsSubscriptionContextValue['deleteTag']>(
    (tagId) => {
      if (firestore == null) {
        return Promise.resolve(false);
      }

      return firestore
        .collection('users')
        .doc(props.uid)
        .collection('tags')
        .doc(tagId)
        .delete()
        .then(() => true);
    },
    [firestore, props.uid]
  );

  const contextValue = useMemo(
    () => ({
      tags,
      createTag,
      deleteTag,
    }),
    [tags, createTag, deleteTag]
  );

  return (
    <tagsSubscriptionContext.Provider value={contextValue}>
      {props.children}
    </tagsSubscriptionContext.Provider>
  );
};

export default React.memo(TagsSubscriber);
