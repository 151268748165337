export type CompletedStatusMap = {
  [url: string]: boolean | undefined;
};

export const aggregateCompletedStatus = (
  completedStatusMap: CompletedStatusMap,
  urls: string[]
): boolean | undefined => {
  if (urls.length === 0) {
    return undefined;
  }
  if (urls.some((url) => completedStatusMap[url] == null)) {
    return undefined;
  }
  return urls.every((url) => completedStatusMap[url]);
};
