/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';

interface CalendarContainerProps {
  children: React.ReactNode;
}
const CalendarContainer: React.FC<CalendarContainerProps> = (props) => {
  return (
    <div
      css={{
        width: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      <div
        css={{
          // Tricks to set proper size when rendered inside <Pager />
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          boxSizing: 'border-box',
        }}
      >
        {props.children}
      </div>
    </div>
  );
};

export default React.memo(CalendarContainer);
