/** @jsx jsx */
import React, { useState } from 'react';
import { jsx } from '@emotion/core';
import { Pager } from 'sancho';

interface SwipeFrameProps {
  primary?: React.ReactElement | null;
  secondary?: React.ReactElement | null;
}
const SwipeFrame: React.FC<SwipeFrameProps> = (props) => {
  const [page, setPage] = useState(0);

  return (
    <Pager
      value={page}
      onRequestChange={setPage}
      css={{
        width: '100%',
        maxWidth: '100%',
        height: '100%',
        position: 'relative',
      }}
    >
      {props.primary}
      {props.secondary}
    </Pager>
  );
};
export default React.memo(SwipeFrame);
