import React from 'react';
import { MenuList, MenuItem, IconGithub } from 'sancho';
import { githubAppInstallationURL } from '../../../const';

const ThirdPartyMenu: React.FC = () => {
  return (
    <MenuList>
      <MenuItem
        contentBefore={<IconGithub />}
        component="a"
        href={githubAppInstallationURL}
      >
        Sync with GitHub
      </MenuItem>
    </MenuList>
  );
};
export default React.memo(ThirdPartyMenu);
