/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { useTheme } from 'sancho';

const EditorContainer: React.FC<React.ComponentProps<'div'>> = (props) => {
  const theme = useTheme();

  return (
    <div
      {...props}
      css={{
        height: '100%',
        overflowY: 'scroll',
        padding: `0 ${theme.spaces.sm}`,
      }}
    />
  );
};

export default React.memo(EditorContainer);
