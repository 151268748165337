/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { useTheme } from 'sancho';

interface TagListProps {
  children: React.ReactNode;
}
const TagList: React.FC<TagListProps> = (props) => {
  const theme = useTheme();

  return (
    <ul
      css={{
        margin: 0,
        padding: 0,
        listStyle: 'none',
        paddingLeft: theme.spaces.sm,
      }}
    >
      {props.children}
    </ul>
  );
};

export default React.memo(TagList);
