import React, { useEffect } from 'react';
import { useHistory, Redirect } from 'react-router-dom';
import { useUser } from '../auth/hooks';
import { firebaseFunctions } from '../firebase';
import GithubRedirected from './components/GithubRedirected';
import GithubError from './components/GithubError';

import { GrantGithubWebAppFlowRequest } from '../../../../interface/functions/github';

const grantGithubWebAppFlow = firebaseFunctions.httpsCallable(
  'grantGithubWebAppFlow'
);

interface GithubApiRedirectedProps {
  pathOnSuccess: string;
  pathOnError: string;
}
export const GithubApiRedirected: React.FC<GithubApiRedirectedProps> = (
  props
) => {
  const user = useUser();

  const history = useHistory();

  const params = new URLSearchParams(window.location.search);
  const error = params.get('error');
  const code = params.get('code');

  useEffect(() => {
    if (error) return;
    if (!code) return;
    if (!user) return;

    const req: GrantGithubWebAppFlowRequest = { code };

    grantGithubWebAppFlow(req)
      .then(() => history.replace(props.pathOnSuccess))
      .catch(() => history.replace(props.pathOnError));
  }, [user, error, code, history, props.pathOnSuccess, props.pathOnError]);

  if (error) {
    return <GithubError message={error} />;
  }

  if (!code) {
    return <GithubError message="No code" />;
  }

  if (!user) {
    return <Redirect to="/" />;
  }

  return <GithubRedirected />;
};
