/** @jsx jsx */
import React, { useCallback } from 'react';
import { useTheme } from 'sancho';
import { jsx } from '@emotion/core';

interface ToggleProps extends Omit<React.ComponentProps<'label'>, 'onChange'> {
  value: boolean;
  onChange: (value: boolean) => void;
}
const Toggle: React.FC<ToggleProps> = (props) => {
  const { value, onChange, ...restProps } = props;

  const theme = useTheme();

  const radius = 26;
  const margin = 4;

  return (
    <label
      {...restProps}
      css={{
        position: 'relative',
        display: 'inline-block',
        width: (radius - margin) * 2,
        height: radius,
      }}
    >
      <input
        css={{
          opacity: 0,
          width: 0,
          height: 0,
          '&:checked ~ span': {
            backgroundColor: theme.colors.intent.primary.light,
          },
          '&:focus ~ span': {
            boxShadow: `0 0 1px ${theme.colors.intent.primary.light}`,
          },
          '&:checked ~ span::before': {
            transform: `translateX(${radius - 2 * margin}px)`,
          },
        }}
        type="checkbox"
        checked={value}
        onChange={useCallback(
          (e) => {
            onChange(e.target.checked);
          },
          [onChange]
        )}
      />
      <span
        css={{
          position: 'absolute',
          cursor: 'pointer',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: theme.colors.background.overlay,
          transition: '.4s',
          borderRadius: radius,
          '&::before': {
            position: 'absolute',
            content: '" "',
            height: radius - 2 * margin,
            width: radius - 2 * margin,
            left: margin,
            bottom: margin,
            backgroundColor: theme.colors.background.layer,
            transition: '.4s',
            borderRadius: '50%',
          },
        }}
      />
    </label>
  );
};

export default React.memo(Toggle);
