/** @jsx jsx */
import { jsx } from '@emotion/core';
import { useTheme } from 'sancho';
import logoSVG from '../images/logo_beta.svg';
import logoDarkSVG from '../images/logo_dark_beta.svg';
// import logoSVG from '../images/logo.svg';
// import logoDarkSVG from '../images/logo_dark.svg';

export const Logo: React.FC = () => {
  const theme = useTheme();

  return (
    <img
      src={theme.colors.mode === 'dark' ? logoDarkSVG : logoSVG}
      alt="Tasquet"
      aria-hidden
      css={{
        width: '300px',
        margin: '1em',
      }}
    />
  );
};
