import { hostingURL } from '../const';

const zerofill2 = (str: string | number): string => ('00' + str).slice(-2);

export const makeGettingStartedMd = (
  y: number,
  m: number,
  d: number
): string => {
  return `# Welcome to Tasquet
**Tasquet** is a "connected" Markdown editor that helps you write memos, break down tasks, and organize ideas.
![Tasquet](${hostingURL}/assets/tutorial/20200503/logo_beta.png)

[Tutorial video](https://www.youtube.com/watch?v=zYCL2L0RjH0)

## As a task manager
You can write down your tasks as below:

### ${y}-${zerofill2(m)}
#### ${zerofill2(d > 1 ? d - 1 : d + 1)}
* [ ] Implement awesome log-in form https://github.com/tasquet/test/issues/1
* [ ] Design a fantastic logo https://github.com/tasquet/test/issues/2

#### ${zerofill2(d)}
* [ ] Work out from 10:00 to 12:00
* [ ] Talk with my friend John from 16:00 to 17:00

---

Yes, it's like an ordinary \`todo.md\` - but as you see, there are some icons on the right pane.
* GitHub issue URLs are linked to the relevant GitHub issue pages and the statuses are automatically shown.
* Schedules with date and time are considered as "tasks" and automatically synchronized with a calendar view. The date and time are synchronized bidirectionally. Let's try editing the schedule both on the editor and the calendar.

![Schedule synchronized with calendar](${hostingURL}/assets/tutorial/20200503/schedule.gif)

In addition, Tasquet can listen on GitHub issues and reflect the status changes to the status badges on the right pane and Markdown checkboxes.
![Syncing with GitHub issues](${hostingURL}/assets/tutorial/20200503/github_sync.gif)
To enable it, authorize and install Tasquet's GitHub app from the menu to permit subscribing to the repositories.
![Sync with GitHub](${hostingURL}/assets/tutorial/20200503/github_menu.png)

## As an outliner
One of the most powerful Markdown features is *list*.
You can list up your tasks and ideas as list items, and break them down into sub-items.

* To build a good Markdown editor
  * Smooth editing experience
    * Performance
  * Syntax coverage
  * Live inline preview
    * [x] Syntax highlighting
    * [x] Inline image preview
    * [ ] Table editor
* To create a more convenient \`todo.md\` editor
  * Synchronize tasks in Markdown with task management tools like calendar
    * [x] Built-in calendar UI
    * [x] Google Calendar
  * Synchronize GitHub links in Markdown
    * [x] Issues
    * [ ] Pull requests

And in Tasquet, like in outliners, you can manipulate the list items and checkboxes (and other elements) via GUI actions such as click and drag-and-drop.
![Click and DnD](${hostingURL}/assets/tutorial/20200503/list_gui.gif)

## Of course, as a Markdown editor
As you have seen above, the core feature of Tasquet is Markdown editing.
It supports live inline preview with CommonMark and GFM (GitHub Flavored Markdown) syntax (while some elements such as table are not supported yet).
This tutorial page itself is written as a Markdown note in Tasquet and fully editable. In this note, you can try all the features explained in this note.

## Document management
You can use folders.
The folder view can be accessed from the button at the top of the left sidebar.

![Folder view](${hostingURL}/assets/tutorial/20200503/folder.gif)

## Desktop/mobile app
Tasquet is built as a PWA (Progressive Web App) and so can be used like a native app both on mobile and PC.
For more information, please see pages such as https://support.google.com/chrome/answer/9658361?co=GENIE.Platform%3DDesktop&hl=en.

## Offline support
Tasquet can work in offline-first mode, in which Tasquet synchronizes notes and data with the server and stores an offline cache ("offline data persistence"). Then it works in an offline environment and re-syncs next time when it gets online again.
By default, the offline data persistence feature is disabled to avoid saving users' private notes on untrusted devices without their knowledge.
To enable it, click the icon at the bottom left and follow the instructions.
![Tasquet](${hostingURL}/assets/tutorial/20200503/offline_icon.png)

## Registration
You can use Tasquet without creating an account and now you may be doing so. It's a convenient way to write small notes and try out Tasquet.
In this situation, you are using Tasquet and its server-side services like GitHub syncing via an "anonymous" account. Please note that the identity of the anonymous account is bound to the locally stored data on your device and can be lost by accident.
By signing up and creating an account, you can create a stable identity and access the data from multiple devices using the account.

You can sign in or log in from the menu on the bottom left.

## Bug reports/feature requests
Please create issues [here](https://github.com/tasquet/issue-tracker/issues) if you find bugs and/or to request features.


**Enjoy Tasquet!**

# FAQ
## Where are my notes saved?
Tasquet uses [Firebase](https://firebase.google.com/) as its backend and saves notes in Firestore, a managed database provided by Firebase.
All data is encrypted before written to disk. For more information about it, see https://cloud.google.com/firestore/docs/server-side-encryption.
`;
};
