import React, { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { Location } from 'history';
import { Link, IconArrowRight, IconCalendar } from 'sancho';
import { useSplitFrame } from '../../../../../components/SideBySide';
import styled from '@emotion/styled/macro';
import { DateTimeTask } from '../../../logics/task-parser';
import { formatDateTime } from '../../../logics/datetime';

const TaskInfoContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
`;

interface TaskInfoProps {
  taskId: string;
  task: DateTimeTask;
}
const TaskInfo: React.FC<TaskInfoProps> = (props) => {
  const task = props.task;
  const taskId = props.taskId;

  const startStr = task.start ? formatDateTime(task.start) : '';
  const endStr = task.end ? formatDateTime(task.end) : '';
  const title = `${startStr} ~ ${endStr}`;

  const [, setSecondaryVisibility] = useSplitFrame();
  const openCalendar = useCallback(() => setSecondaryVisibility(true), [
    setSecondaryVisibility,
  ]);

  const eventFocusLinkFn = useCallback(
    (location: Location) => {
      const search = new URLSearchParams(location.search);
      search.set('eventFocus', taskId);
      return { ...location, search: search.toString() };
    },
    [taskId]
  );

  return (
    <TaskInfoContainer>
      <Link
        component={RouterLink}
        to={eventFocusLinkFn}
        title={title}
        onClick={openCalendar}
      >
        <IconCalendar size="sm" />
        <IconArrowRight size="sm" />
      </Link>
    </TaskInfoContainer>
  );
};

export default React.memo(TaskInfo);
