/** @jsx jsx */
import React, { useRef, useImperativeHandle } from 'react';
import { jsx } from '@emotion/core';
import { useTheme, Input } from 'sancho';

interface NoteTitleInputProps {
  value: string;
  onChange: (value: string) => void;
  onBlur: () => void;
  onFocusNextRequested?: () => void;
}
export interface NoteTitleInputRef {
  focus: () => void;
}
const NoteTitleInput = React.forwardRef<NoteTitleInputRef, NoteTitleInputProps>(
  (props, ref) => {
    const inputRef = useRef<HTMLInputElement>(null);

    useImperativeHandle(ref, () => ({
      focus() {
        inputRef.current?.focus();
      },
    }));

    const theme = useTheme();

    return (
      <Input
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        ref={inputRef} // This is a bug of sancho. See https://github.com/bmcmahen/sancho/pull/57
        type="text"
        placeholder="(Untitled)"
        css={{
          marginRight: theme.spaces.xs,
          width: 'initial',
          flexGrow: 1,
        }}
        value={props.value}
        onChange={(e) => props.onChange(e.target.value)}
        onKeyDown={(e) => {
          if (e.nativeEvent.isComposing) {
            // Do not override IME's key handling
            return;
          }
          if (e.key === 'Enter') {
            e.preventDefault();
            props.onFocusNextRequested && props.onFocusNextRequested();
          }
        }}
        onBlur={props.onBlur}
      />
    );
  }
);

export default React.memo(NoteTitleInput);
