import React, { useState, useEffect, useCallback } from 'react';
import { useUser } from '../../../auth/hooks';
import { useFirestore } from '../../../firebase/hooks';
import IssueBadge from './components/github/IssueBadge';
import NotFoundIssueBadge from './components/github/NotFoundIssueBadge';
import LoadingBadge from './components/github/LoadingBadge';
import ErrorBadge from './components/github/ErrorBadge';
import {
  subscribeGithubIssueProxy,
  requestUpdateGithubIssueProxy,
  GithubIssue,
} from '../../../serviceDataProxy/github';
import {
  ServiceDataProxyStatus,
  ServiceDataProxyError,
} from '../../../../../../interface/firestore/serviceDataProxy';
import { GitHubInfo, GitHubIssue, GitHubPull } from './github-info';

interface GitHubIssueBadgeProps {
  issue: GitHubIssue;
  onCompletedChange: (value: boolean | undefined) => void;
}
const GitHubIssueBadge: React.FC<GitHubIssueBadgeProps> = (props) => {
  const { issue, onCompletedChange } = props;

  const [proxyStatus, setProxyStatus] = useState<ServiceDataProxyStatus>();
  const [proxyError, setProxyError] = useState<ServiceDataProxyError>();
  const [githubIssue, setGithubIssue] = useState<GithubIssue>();

  const user = useUser();

  const firestore = useFirestore();

  useEffect(() => {
    if (!firestore) return;
    if (!user) return;

    return subscribeGithubIssueProxy(
      firestore,
      user.uid,
      issue.owner,
      issue.repo,
      issue.issueNumber,
      (proxiedData) => {
        setProxyStatus(proxiedData.status);
        setProxyError(proxiedData.error);

        if (proxiedData.status === 'updated') {
          setGithubIssue(proxiedData.data);
        } else {
          setGithubIssue(undefined);
        }
      }
    );
  }, [firestore, user, issue.owner, issue.repo, issue.issueNumber]);

  const checked: boolean | undefined =
    githubIssue && githubIssue.state === 'closed';
  useEffect(() => {
    onCompletedChange(checked);
  }, [checked, onCompletedChange]);

  const refresh = useCallback(() => {
    if (!firestore || !user) {
      return;
    }
    requestUpdateGithubIssueProxy(
      firestore,
      user.uid,
      issue.owner,
      issue.repo,
      issue.issueNumber
    );
  }, [firestore, user, issue.owner, issue.repo, issue.issueNumber]);

  if (proxyStatus === 'update-requested') {
    return <LoadingBadge />;
  }

  if (proxyError != null && proxyError !== 'ok') {
    if (proxyError === 'not-found') {
      return <NotFoundIssueBadge onRefresh={refresh} />;
    }

    return <ErrorBadge onRefresh={refresh} />;
  }

  if (githubIssue) {
    return (
      <IssueBadge
        open={githubIssue && githubIssue.state === 'open'}
        owner={issue.owner}
        repo={issue.repo}
        issueNumber={issue.issueNumber}
        onRefresh={refresh}
      />
    );
  }

  return null;
};

interface GitHubPullBadgeProps {
  pull: GitHubPull;
}
const GitHubPullBadge: React.FC<GitHubPullBadgeProps> = (props) => {
  return <div>PullReq {props.pull.pullNumber}</div>;
};

interface GitHubInfoBadgeProps {
  info: GitHubInfo;
  onCompletedChange: (value: boolean | undefined) => void;
}
const GitHubInfoBadge: React.FC<GitHubInfoBadgeProps> = (props) => {
  if (props.info.type === 'issue') {
    return (
      <GitHubIssueBadge
        issue={props.info}
        onCompletedChange={props.onCompletedChange}
      />
    );
  }
  if (props.info.type === 'pull') {
    return <GitHubPullBadge pull={props.info} />;
  }

  return null;
};

export default React.memo(GitHubInfoBadge);
