/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { useTheme, Text, IconX } from 'sancho';
import { Tag } from '../../TagsSubscriber';

interface NoteTagProps {
  tag: Tag;
  onRemove: (tagId: string) => void;
}
let NoteTag: React.FC<NoteTagProps> = (props) => {
  const theme = useTheme();

  return (
    <li
      css={{
        listStyle: 'none',
        display: 'inline-flex',
        alignItems: 'center',
        marginRight: theme.spaces.sm,
        borderRadius: '32px',
        backgroundColor: theme.colors.palette.blue.base,
        padding: '2px 8px 2px 12px',
        fill: 'white',
      }}
    >
      <Text
        css={{
          margin: 0,
          padding: 0,
          color: 'white',
          fontSize: theme.fontSizes[0],
          fontWeight: 'bold',
          textAlign: 'center',
        }}
      >
        {props.tag.name}
      </Text>
      <button
        css={{
          display: 'inline-flex',
          backgroundColor: 'transparent',
          border: 'none',
          cursor: 'pointer',
          outline: 'none',
          padding: 0,
          appearance: 'none',
          marginLeft: '4px',
        }}
        onClick={() => props.onRemove(props.tag.id)}
      >
        <IconX css={{ stroke: theme.colors.palette.gray.light }} size="sm" />
      </button>
    </li>
  );
};
NoteTag = React.memo(NoteTag);

interface NoteTagListProps {
  tags: Tag[];
  onRemove: (tagId: string) => void;
}
const NoteTagList: React.FC<NoteTagListProps> = (props) => {
  return (
    <ul
      css={{
        margin: 0,
        padding: 0,
        display: 'flex',
        flexDirection: 'row',
      }}
    >
      {props.tags.map((tag) => (
        <NoteTag key={tag.id} tag={tag} onRemove={props.onRemove} />
      ))}
    </ul>
  );
};

export default React.memo(NoteTagList);
