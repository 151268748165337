import { useMemo } from 'react';
import { useTheme } from 'sancho';
import { renderers } from '@tasquet/slate-markdown-plugin';

export const useSlateMarkdownTheme = (
  theme: ReturnType<typeof useTheme>
): typeof renderers.theme.defaultTheme =>
  useMemo(() => {
    const darkMode = theme.colors.mode === 'dark';

    return {
      ...renderers.theme.defaultTheme,
      colors: {
        ...renderers.theme.defaultTheme.colors,
        leftGutter: {
          background: theme.colors.background.tint1,
          backgroundSelected: theme.colors.background.tint2,
        },
        dragHandle: {
          edge: theme.colors.border.default,
          icon: theme.colors.border.default,
        },
        line: {
          number: theme.colors.text.muted,
        },
        heading: {
          text: theme.colors.text.heading,
          mark: theme.colors.text.muted,
        },
        listItem: {
          marker: theme.colors.border.default,
        },
        listItemCheckbox: theme.colors.background.tint2,
        inlineCode: {
          border: theme.colors.border.default,
          background: theme.colors.background.tint1,
        },
        code: {
          text: theme.colors.text.default,
          border: theme.colors.border.default,
          background: theme.colors.background.tint1,
        },
        thematicBreak: theme.colors.border.default,
        paragraph: {
          text: theme.colors.text.default,
        },
        blockQuote: {
          marker: theme.colors.border.default,
        },
        link: {
          text: darkMode
            ? theme.colors.palette.blue.light
            : theme.colors.palette.blue.base,
          textVisited: darkMode
            ? theme.colors.palette.violet.light
            : theme.colors.palette.violet.base,
        },
      },
    };
  }, [theme]);
